import React from "react";
import swal from "sweetalert";
import i18n from "i18n";
import moment from "moment";

export const saveData = (key, value) => {
  const timestamp = +new Date();
  const data = JSON.stringify({ token: value, timestamp });
  window.localStorage.setItem(key, data);
};

export const loadData = (key) => {
  const data = JSON.parse(window.localStorage.getItem(key));
  if (data) {
    const timeDiff = Math.abs(data.timestamp - new Date().getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays > 1) {
      window.localStorage.removeItem("token");
      return "";
    }
    return data.token;
  }
  return "";
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const paginationRender = (current, type, originalElement) => {
  if (type === "prev") {
    return <i className="fas fa-angle-left pagination-arrows" />;
  } else if (type === "next") {
    return <i className="fas fa-angle-right pagination-arrows" />;
  }
  return originalElement;
};

export const moneyField = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

export const elipsedText = (text, limit) =>
  text && text.length > limit ? text.substring(0, limit - 3) + "..." : text;

export const camelize = (str) =>
  str.replace(/_([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });

export const networkError = () =>
  swal({
    title: i18n.t(
      "Error: Network error: Response not successful: Received status code 400"
    ),
    icon: "error",
  });

export const itemQualityLabel = (paidAt, itemQuality) => {
  if (paidAt) {
    if (paidAt > moment("18/07/2022", "DD/MM/YYYY").toDate()) {
      return i18n.t(itemQuality);
    } else {
      return i18n.t(`${itemQuality}_old`);
    }
  } else {
    return i18n.t(itemQuality);
  }
};
