import React from 'react'
import { graphql, Query, compose } from 'react-apollo'

// import OrderMarginForm from 'components/Forms/OrderMarginForm'
import OrderOwnerForm from 'components/Forms/OrderOwnerForm'
import Close from 'components/Icons/Close'

import { withAuth } from 'hocs/PrivateRoute'
import { onlyClient } from 'hocs/componentByRole'
import { setOwner } from 'mutations'
import { getJobOrder, getClients } from 'queries/queries'
import { camelize } from 'utils'
import Loader from 'components/Loader'


const OrderSetOwner = props => {
  const handleSetOwner = async (values, { setErrors }) => {
    const response = await props.setOwner({
      variables: {
        orderId: props.match.params.id,
        owner: values.owner
      },
      refetchQueries: [
        {
          query: getJobOrder,
          variables: { 
            id: props.match.params.id 
          }
        }
      ]
    })
    if (response.data.setOwner.error.validationErrors.length) {
      const errors = {}
      response.data.setOwner.error.validationErrors.map(error => {
        errors[camelize(error.field)] = error.messages.join(' ')
        return errors
      })
      setErrors(errors)
    } else {
      props.history.goBack()
    }
  }

  return (
    <Query
      query={getJobOrder}
      variables={{ id: props.match.params.id }}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loader />
        if (error) return `Error! ${error.message}`
        return (
          <div className='row justify-content-md-center p-5 m-0' data-component='Dashboard/Client/OrderSetOwner/index'>
            <div className='confirm-order col-8'>
              <OrderOwnerForm
                {...props}
                {...data.jobOrder}
                clients={props.getClients}
                onSubmit={handleSetOwner}
              />
              <Close onClick={() => props.history.goBack()} />
            </div>
          </div>
        )
      }}
    </Query>
  )
}

// export default graphql(handleSetOwner, { name: 'handleSetOwner' })(
//   withAuth(onlyClient(OrderSetOwner))
// )

export default compose(
  graphql(getClients, { 
    name: 'getClients' 
  }),
  graphql(setOwner, { 
    name: 'setOwner' 
  })
)(withAuth(onlyClient(OrderSetOwner)))