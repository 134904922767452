import React from 'react'
import { Spin } from 'antd'

import 'antd/dist/antd.css'

const Loader = ({ style }) => (
  <div className='text-center'>
    <Spin style={style} size='large' />
  </div>
)

export default Loader
