import React, {useState} from 'react'
import CreateCouponForm from "components/Forms/CreateCouponForm";
import {compose, graphql, Query} from "react-apollo";
import {adminCreateCoupon} from "../../../../mutations";
import {withAuth} from "../../../../hocs/PrivateRoute";
import {onlyClient} from "../../../../hocs/componentByRole";
import {Link} from 'react-router-dom'
import * as path from 'constants/routes'
import Close from "../../../../components/Icons/Close";
import swal from "sweetalert";
import i18n from "../../../../i18n";
import {getPropertyCategories} from "../../../../queries/queries";

const CreateCoupon = (props) => {
  const [state] = useState({error: ''})
  const [isSubmitted, setSubmitted] = useState(false)
  const handleCreate = async (values, {setErrors}) => {
    const willCreate = await swal({
      title: i18n.t('Are you sure?'),
      text: i18n.t('Are you sure you want to create the coupon?'),
      icon: 'warning',
      buttons: true,
      dangerMode: true
    })
    if (willCreate) {
      setSubmitted(true)
      try {
        const {data} = await props.adminCreateCoupon({variables: values})
        if (data.adminCreateCoupon.success) {
          props.history.push(`/showcoupons`)
        } else {
          const errors = {}
          data.adminCreateCoupon.error.validationErrors.forEach(
            error => (errors[error.field] = error.messages.join(' '))
          )
          setErrors(errors)
        }
      } catch (e) {
        console.log({e})
      } finally {
        setSubmitted(false)
      }
    }
  }
  return (
    <Query query={getPropertyCategories} variables={{hasProperties: true}}>
      {({data, loading}) =>(
        <div className='row justify-content-md-center p-5 m-0'>
          <div className='col-8'>
            <CreateCouponForm
              handleCreate={handleCreate}
              error={state.error}
              errors={props}
              isSubmitted={isSubmitted}
              categories={(!loading && data) ? data.propertyCategories.map(el => el.name) : []}
            />
            <Link to={path.SHOWCOUPONS}>
              <Close/>
            </Link>
          </div>
        </div>
      )}
    </Query>
  )
}


export default compose(
  graphql(adminCreateCoupon, {
    name: 'adminCreateCoupon'
  })
)(withAuth(onlyClient(CreateCoupon)))