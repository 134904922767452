const it = {
  yes: "Sì",
  Yes: "Sì",
  No: "No",
  "Page not found": "Pagina non trovata :(",
  "2019 ByTek Marketing Srl. All rights reserved":
    "2019 ByTek Marketing Srl. Tutti i diritti riservati",
  "Forgot password?": "Ha dimenticato la password?",
  "Forgot Password": "Ha dimenticato la password",
  "Didn't receive activation email?":
    "Non hai ricevuto l'email di attivazione?",
  "username@email": "nomeutente@email.it",
  "Enter Password": "Inserisci Password",
  "Remember me": "Ricordati di me",
  Submit: "Submit",
  "E-mail is not valid!": "L'email non è valida!",
  "E-mail is required!": "E-mail è richiesta!",
  "Password is required!": "È richiesta la password!",
  Name: "Nome:",
  "Enter Name": "Inserisci Nome",
  Surname: "Cognome:",
  "Enter Surname": "Inserisci Cognome",
  "Enter company name": "Inserisci il nome dell'azienda",
  "Company name": "Nome Azienda:",
  "Enter Email": "Inserisci Email",
  Email: "Email",
  "Password confirmation": "Conferma password:",
  "Repeat the password": "Ripeti Password",
  "Sign up": "Registrati",
  "Name has to be longer than 2 characters!":
    "Il nome deve essere più lungo di 2 caratteri!",
  "Name has to be shorter than 30 characters!":
    "Il nome deve essere più corto di 30 caratteri!",
  "Name is required!": "Il nome è obbligatorio!",
  "Surname has to be longer than 2 characters!":
    "Il cognome deve essere più lungo di 2 caratteri!",
  "Surname has to be shorter than 30 characters!":
    "Il cognome deve essere più corto di 30 caratteri!",
  "Surname is required!": "Il cognome è richiesto!",
  "Password confirmation is required!":
    "È richiesta la conferma della password!",

  "New offer": "Nuova Offerta",
  "Edit offer": "Modifica Offerta",
  "Edit margin": "Modifica Margine",
  "Update Offer": "Salva Offerta",
  "Create Offer Form Name": "Nome Offerta",
  "Create Offer Form Name Placeholder": "Inserisci il nome dell'offerta",
  Description: "Descrizione:",
  "Enter the description of the offer":
    "Inserisci la descrizione della offerta",
  Price: "Prezzo",
  "Select Link Type": "Seleziona Tipologia Link:",
  "Publication Type": "Tipologia Pubblicazione",
  "Prohibited Articles": "Articoli Vietati",
  "Separate tags with": 'Separare i tag con delle ","',
  "Create Offer": "Salva Offerta",
  "Create Request": "Salva Campagna",
  "Enter the name of the offer": "Inserisci il nome dell'offerta",
  "CSV download": "Scarica CSV",
  Loading: "Caricamento...",
  "Description is required!": "La descrizione è obbligatoria!",
  "Price is required!": "Il prezzo è richiesto!",
  "Link type is required!": "È richiesto il tipo di collegamento!",
  "Public type is required!": "È richiesto un tipo di tipo pubblico!",
  "Prohibited Articles is required!": "Gli articoli vietati sono obbligatori!",

  // CreatePropertyForm
  "New property": "Nuova Proprietà",
  "Name Property": "Nome Sito",
  "Enter the name of the property": "es. Nome Sito",
  "Enter the description of the property":
    "es. Sito che tratta articoli di sport e salute",
  "Monthly Traffic": "Traffico Mensile",
  Language: "Lingua",
  "Select language": "Seleziona lingua",
  "Select Country": "Seleziona Country",
  "Article Writing": "Scrittura Articolo Realizzata dal Cliente:", // for label in form
  "Writing Article": "Scrittura Articolo",
  "Select Article": "Si",
  Category: "Categoria",
  "Select the category of property": "Seleziona la categoria di proprietà",
  Type: "Tipologia",
  "Select the type of property": "Seleziona la tipologia di proprietà",
  "Create Property": "Salva Proprietà",
  "Create new property": "Nuova Proprietà",

  // Edit Property Form
  "Edit property": "Modifica Proprietà",
  "Update Property": "Salva Modifiche",
  "Url is not valid!": "URL non valido!",
  "Url is required!": "Url è richiesto!",
  "URL Target": "URL di destinazione",
  "Language is required!": "La lingua è richiesta!",
  "Monthly traffic is required!": "È richiesto traffico mensile!",
  "Country is required!": "Paese è richiesto!",
  "Category is required!": "La categoria è obbligatoria!",
  "Type is required!": "Tipo è richiesto!",
  "Article writing is required!": "È necessaria la scrittura dell'articolo!",

  // Login
  Registration: "Registrati",
  "Dont have an account?": "Non hai un account?",
  "Password has to be longer than x characters":
    "La password deve essere più lunga di {{count}} caratteri!",
  // 'Password has to be longer than':
  //   'La password deve essere più lunga di',
  // 'characters!': 'caratteri!',

  // Sign Up

  "Do you already have an account?": "Hai già un account?",
  Login: "Fai il Login",
  "Select the type of user": "Seleziona la tipologia di utente",
  "Select which user category you belong to":
    "Seleziona a quale categoria di utente appartieni",
  Company: "Azienda",
  Freelance: "Libero Professionista",
  Freelancer: "Libero Professionista",
  "New Publisher User": "Nuovo Utente Publisher",
  "Create User": "Crea Utente",

  // Pop Up
  "Are you sure?": "Sei sicuro?",
  "Are you sure you want to delete it?": "Sei sicuro di voler cancellare?",
  "The property has been success deleted!":
    "La proprietà è stata cancellata con successo!",
  "The offer has been success deleted!":
    "L'offerta è stata cancellata con successo!",
  "The order has been success deleted!":
    "L'ordine è stata cancellata con successo!",
  "Your profile has been changed!": "Il tuo profilo è stata modificato!",
  "Your password has been changed!": "La tua password è stata modificata!",
  "Your billing data has been changed!":
    "I tuoi dati di fatturazione sono stati modificati!",
  Confirmation: "Conferma",
  "The request has been successfully paid!":
    "La campagna è stata pagata con successo!",
  "Please, check your email!": "Per favore, controlla la tua email!",
  "Please reduce price to appear on Self Service":
    "Riduci il prezzo se vuoi apparire nel Self Service",
  "There are cheaper offers":
    "Esistono già altre {{number}} offerte per questa proprietà con prezzi minori.",
  "There is a cheaper offer":
    "Esiste già un'altra offerta per questa proprietà con prezzi minori.",
  //

  Dashboard: "Dashboard",
  Properties: "Proprietà",
  Property: "Proprietà",
  Proposals: "Richieste",
  One_offer: "una offerta",
  Many_offers: "{{number}} offerte",

  // CompletePublisher

  "Welcome to Relevanty": "Benvenuto su Relevanty",
  "Before you begin, complete your profile":
    "Prima di iniziare, completa il tuo profilo:",
  "Enter your first Property in Relevanty":
    "Inserisci la tua prima Proprietà dentro Relevanty",
  Go: "Vai",

  //
  "List of Offers": "Elenco Offerte",
  "Search for offer": "Cerca offerta",
  "Offer Name": "Nome Offerta",
  Publication: "Pubblicazione",
  "Saved Offer": "Offerta Salvata",
  "Congratulations!": "Complimenti!",
  "Your offer has been successfully changed":
    "La tua offerta è stata modificata con successo",
  "Back to the Offers": "Torna alle Offerte",
  "Technical specifications": "Specifiche Tecniche:",
  Traffic: "Traffico:",
  "Saved Property": "Proprietà Salvata",
  "Your property has been successfully created":
    "La tua proprietà è stata modificata con successo",
  "Back to Properties": "Torna alle Proprietà",
  "Search for properties": "Cerca proprietà",
  "Search for URL": "Cerca URL",
  "Category Placeholder": "Categoria",
  "Type Placeholder": "Tipologia",
  Country: "Country",
  "Language Placeholder": "Lingua",
  "Property name": "Nome Sito",
  "Order Article": "Commissiona Articolo",
  "Commission Article": "Voglio che scriviate voi i testi",
  "Write Article": "Scriverò io gli articoli",
  "Average Number Words": "Numero Medio Parole",
  "Minimum Domain Authority": "Domain Authority Minima",
  "Send campaign": "Invia Campagna",
  // Request
  Requests: "Campagne",
  Request: "Campagna",
  "New Request": "Nuova Campagna",
  "New Admin Request": "Nuova Commessa",
  "Request Title": "Titolo Campagna",
  "Admin Request Title": "Titolo Commessa",
  "Ex. Publications Site Name": "Es. Pubblicazioni Nome Sito",
  "Link number": "Numero di Link",
  "Total Budget": "Budget Totale: (euro)",
  "State of request": "Stato della campagna",
  Deadline: "Scadenza",
  "Expiration date": "Data di Scadenza",
  "Enter Request Specifications": "Inserisci Specifiche Campagna",
  "Specify your request": "Specifica la tua campagna",
  "Sites Technical Specifications (DA, DR and Organic Traffic)":
    "Specifiche Tecniche Siti (DA, DR e Traffico Organico)",
  Insert: "Inserisci",
  "Not Insert": "Non Inserire",
  "Traffic Org. Min": "Traffico Org. Min",
  "Article writing": "Scrittura Articolo",
  Article: "Articolo",
  "Article Quality": "Qualità Articolo",
  "Skip Article Approval": "Tralascia Approvazione Articolo",
  "Request Article Approval": "Richiedi Approvazione Articolo",
  "Number Words": "Numero di parole",
  "In progress": "In Lavorazione",
  "in progress": "In Lavorazione",
  deleted: "Cancellato",
  "to be approved": "Da Approvare",
  "to pay": "Da Pagare",
  "to pay adv": "Da Pagare Adv",
  Active: "Attivo",
  Amount: "Costo",
  "Request Name": "Nome Campagna",
  "Request Elements": "Elementi Campagna ",
  "Total Request": "Totale Campagna",
  "Total with vat": "Totale IVA inc.",
  "Total Request with vat": "Totale Campagna IVA inc.",
  "Make Payment": "Effettua Pagamento",
  "non set": "Non impostato",
  "Status URL": "Stato URL",
  "Budget Total": "Budget totale",
  closed: "Chiusa",
  "Request's name": "Nome della campagna",
  "Property's name": "Nome della proprietà",
  "Payment Total": "Totale pagamento",
  "Request status": "Stato campagna",
  "Link quantity": "Numero link",
  "Article information": "Informazioni articolo",
  "Our team is selecting the best sites for your campaign":
    "Il nostro Team sta selezionando i siti migliori per la tua campagna",
  "Approve the list of selected sites for your campaign":
    "Approva la lista di siti selezionati per la tua campagna",
  "Make the payment to proceed with the publication on the approved websites":
    "Effettua il pagamento per procedere con la pubblicazione sui siti approvati",
  "Our team is going to approve the uploaded receipt":
    "Il nostro Team sta approvando la ricevuta di bonifico caricata",
  "Our publishers are publishing the contents on the selected websites":
    "I nostri editori stanno pubblicando i contenuti sui siti selezionati",
  "The campaign has ended": "La campagna è terminata",

  // Profile
  Logout: "Esci",
  "Profile Name": "Nome:",
  "Profile Surname": "Cognome:",
  Save: "Salva Modifiche",
  "View Profile": "Visualizza Profilo",
  "Publication Type Placeholder": "Tipologia Pubblicazione",
  "Come back": "Torna Indietro",
  "Price from": "Prezzo da",
  "Price to": "Prezzo a",
  Cooking: "Cucinando",
  News: "Notizia",
  Site: "Sito",
  Journal: "Giornale",
  "Instagram Account": "Account Instagram",
  "Facebook Page": "Pagina Facebook",
  Follow: "Follow",
  "No Follow": "No follow",
  Final: "Finale",
  Temporary: "Temporaneo",
  "Temporary Offer": "Offerta temporanea",
  high: "Qualità alta",
  medium: "Qualità media",
  low: "Qualità bassa",
  high_old: "Qualità alta",
  medium_old: "Qualità media",
  low_old: "Qualità bassa",
  "Prohibited items": "Oggetti proibiti:",
  "Reset password": "Resetta la password",
  "is required!": "è richiesto!",
  "Profile Data": "Dati Profilo",
  "Is this order free?": "È un ordine gratuito?",
  "Are you sure you want to create the request?":
    "Sei sicuro di voler creare questa campagna?",
  Notification: "Notifiche",
  "Edit profile": "Modifica Dati Personali",
  "Change password": "Modifica Password:",
  "New password": "Nuova Password:",
  "Confirm password": "Conferma password:",
  "Change Billing Data": "Modifica Dati Fatturazione",
  "Billing Data": "Dati Fatturazione",
  "Confirm invoice data": "Conferma i dati di fatturazione",
  Pay: "Paga",
  Payment: "Pagamento",
  "Business name": "Ragione Sociale:",
  "Billing address": "Indirizzo Fatturazione:",
  City: "Città*:",
  Province: "Provincia*:",
  State: "Stato",
  "Electronic Billing": "Fatturazione Elettronica",
  Enable: "Abilita",
  Disable: "Disabilita",
  "Electronic Billing Code": "Codice Fatturazione Elettronica:",
  "Business name is required!": "La ragione sociale non è richiesta!",
  "Number Link is required!": "Numero di collegamento è richiesto!",
  "VAT Code": "P. IVA:",
  "ZIP code": "CAP:",
  "Certified Email": "PEC:",
  "VAT is required!": "P.IVA è richiesto!",
  "Billing address is required!": "Indirizzo Fatturazione è richiesto!",
  "CAP is required!": "CAP è richiesto!",
  "City is required!": "Città è richiesto!",
  "Province is required!": "Provincia è richiesta!",
  "State is required!": "Stato è richiesto!",
  "PEC is required!": "PEC è richiesta!",
  "Electronic Billing Code is required!":
    "Codice Fatturazione Elettronica è richiesta!",

  "Save Changes": "Salva Modifiche",
  "Select all": "Seleziona tutto",
  "Mark as Read": "Segna come Letti ",
  Delete: "Elimina",
  Orders: "Commesse",
  Invoices: "Fatture",
  "New Orders": "Nuove Commesse",
  "Payment Orders": "Commesse in Pagamento",
  "Orders in Approval": "Commesse in Approvazione",

  // Job Order
  "Active job orders": "Commesse Attive",
  "to compose": "Da Comporre",
  sent: "inviato",
  "under revision": "in revisione",
  Approved: "Approvato",
  Quality: "Qualità",
  "N° Medium Words": "Nº Parole Medie",
  "N° Words": "Nº Parole",
  "Item specifications": "Specifiche Articolo",
  "Organic Traffic": "Traffico Organico",
  Deadlines: "Scadenza",
  "N° Publications": "Nº Pubblicazioni",
  "Job Name": "Nome Commessa",
  "Make Job Order": "Componi Commessa",
  "Commission yes": "Commissiona",
  "Commission no": "Non Commissiona",
  "New Job Order": "Nuova Commessa",
  "Select Publication Category": "Seleziona Categoria Pubblicazione ",
  "Set the order name and description":
    "Imposta il nome e la descrizione della commessa",
  "Order name": "Nome della commessa",
  "Order description": "Descrizione della commessa",
  "Enter Order name": "Inserisci il nome della commessa",
  "Enter Order description": " Inserisci la descrizione della commessa",
  "Order name is required!": "Il nome dell'ordine è obbligatorio!",
  "Order description is required!":
    "La descrizione dell'ordine è obbligatoria!",
  "Selected Categories": "Categorie Selezionate",
  "Category Name": "Nome Categoria",
  "Sites Present": "Siti Presenti",
  "Search for category name": "Cerca nome categoria",
  "Number of sites": "Numero Siti",
  Proceed: "Procedi",
  "Marks as Paid": "Segna come Pagato",
  "Remove Invoice": "Rimuovi fattura",
  paid: "Pagato",
  Pubblication: "Pubblicazione",
  "Overall Marginality": "Marginalità Complessiva",
  "Proposal completed": "Richiesta completata",
  "Numerical Value": "Valore Numerico",
  "Percentage Value": "Valore Percentuale",
  "Enter Value": "Inserisci Valore ",
  "Enter Margin": "Inserisci Margine",
  "Enter Owner": "Inserisci Proprietario",
  "Construction of the Job Order": "Realizzazione della Commessa",
  "Select the margin mode you want to apply to the realized order.":
    "Seleziona la modalità di margine che vuoi applicare alla commesa realizzata.",
  "Select the type of margin": "Seleziona la tipologia di margine",
  "Marginality is required!": "Marginalità è richiesto!",
  "Numerical Value is required!": "Il valore numerico è richiesto!",
  "Value is required!": "È necessario il valore!",
  "Personalized Marginality": "Marginalità Personalizzata",
  "Add Categories": "Aggiungi Categorie",
  "Add Offers": "Aggiungi Offerte",
  "Send Job Order": "Invia Commessa",
  "Save Job Order": "Salva Commessa",
  "Selected offers": "Offerte selezionate",
  "Selected properties": "Proprietà selezionate",
  "Total Offers": "Totale Offerte",
  "Final Value": "Valore Finale",
  "Job Order Summary": "Riepilogo Commessa",
  "Selected Margin": "Margine Selezionato",
  "Overall Value Percentage": "Complessivo Valore Percentuale",
  "Overall Value Numeric": "Complessivo Valore Numerico",
  "Customized Percentage Value": "Personalizzato Valore Percentuale",
  Edit: "Modifica",
  "Final Price": "Prezzo Finale",
  "Enter Margin Value": "Inserisci Valore Margine ",
  "Confirmation of Order Receipt": "Conferma Invio Commessa",
  "Do you want to send the following order?":
    "Confermi di voler inviare la seguente commessa?",
  "Order Sent Successfully": "Commessa Inviato con Successo",
  "The request was correctly transformed into a job order and sent to the customer.":
    "La campagna è stata  correttamente trasformata in commessa ed inviata al cliente.",
  "The order will now be visible inside the orders in progress":
    "La commessa sara ora visible all'intermo delle commese in Approvazione",
  "Orders on hold": "Commesse in attesa",
  "Order status updated": "Stato della commessa aggiornato",
  "Order is now on hold": "La commessa è stata messa in attesa", // sospesa?
  "Order is now resumed": "La commessa è ripartita",
  "put on hold": "metti in attesa",
  resume: "riprendi",
  "On hold": "In attesa",
  "Back to requests": "Torna alle Campagne",
  "View Job Order": "Visualizza Commessa",
  "Non-Allowed Topics": "Argomenti non-Ammessi",
  "Enter in the Job Order": "Inserisci nella Commessa",
  "Selected Sites": "Siti Selezionati ",
  "Order in Approval": "Commesse in Approvazione",
  "Active Order": "Commesse Attive",
  "Publisher Approval": "Approvazione Publisher",
  "Advertiser Approval": "Approvazione Advertiser",
  "Article to approve": "Articoli da Approvare",
  "URL to approve": "URL da Approvare",
  "Total Proposal": "Totale Richiesta",
  "Budget Substitutions": "Budget Sostituzioni",
  "In Approval": "In Approvazione",
  "in approval": "In Approvazione",
  Total: "Totale",
  "Sort by": "Ordina per",
  Notifications: "Notifiche",
  "General Notifications": "Notifiche Generali",
  "Offers Updates in Approval": "Aggiornamenti Offerte in Approvazione",
  "Updates Active Offers": "Aggiornamenti Offerte Attive",
  Search: "Cerca",
  "Typology Notification": "Tipologia Notifica",
  "Job Order ID": "ID Commessa",
  Date: "Data",
  "To Read": "Da Leggere",
  Read: "Letto",
  "Payment Notification": "Notifica Pagamento",
  "Type of Request": "Tipologia Campagna",
  "Request number of links": "Campagna numero di link",
  "Status article": "Stato Articolo",
  "Publisher URL": "Stato URL",
  "Publisher Status": "Stato Publisher",
  "Advertiser Status": "Stato Advertiser",
  "Replace Offer": "Sostituisci Offerta",
  "URL Type": "Tipologia URL",
  "Arguments Not Allowed": "Argomenti non Ammessi",
  "Order in payment": "Commesse in Pagamento",
  "Order Status": "Stato Commessa",
  "Owner of the Job Order": "Proprietario della commessa",
  "Job order chat": "Chat Commessa",
  "Approved articles": "Articoli da Approvare",
  Invoice: "Fattura",
  " file upload failed.": " caricamento file fallito.",
  " file uploaded successfully.": " il file è stato caricato con successo.",
  "under processing": "In Lavorazione",
  "Post to upload": "Post da Caricare",
  "Article to upload": "Post da Caricare",
  "Post under revision": "Post in Revisione",
  "Post refused": "Post Rifiutato",
  "Post approved": "Post Approvato",
  "URL to upload": "URL",
  "Url under revision": "URL in Revisione",
  "Url refused": "URL Rifiutata",
  "URL refused": "URL Rifiutati",
  "Url approved": "URL Approvata",
  "URL Approved": "URL Approvati",
  "Url rejected advertiser": "URL rifiutata Advertiser",
  "Advertiser post under revision": "In Revisione",
  "Advertiser post refused": "Post Rifiut. Adv",
  "Advertiser post approved": "Post Appr. Adv",
  "Publisher post under revision": "In Revisione",
  "Publisher post refused": "Post Rifiut. Pub",
  "Publisher post approved": "Post Appr. Pub",
  Refused: "Rifiutato",
  "Under revision": "In revisione",
  "Enter Margin Value (press enter)": "Inserisci margine (e premi invio)",
  "Publisher's name": "Publisher",
  "Advertiser's name": "Advertiser",
  "Check the details of the request and compose the order":
    "Verifica i dettagli della richiesta e componi la commessa",
  "The advertiser is going to approve the proposed websites":
    "L'advertiser sta approvando i siti proposti",
  "The advertiser has to conclude the payment in order to activate the order":
    "L'advertiser deve effettuare il pagamento per attivare la commessa",
  "Check the uploaded receipt in order to activate the order":
    "Verifica la ricevuta di bonifico caricata per attivare la commessa",
  "Publisher are publishing on the approved websites":
    "I publisher stanno procedendo con la pubblicazione sui siti approvati",
  "The order has ended": "La commessa è terminata",
  "Offer type": "Tipologia offerta",

  // Proposals
  "New Proposals": "Nuove Richieste",
  "Proposals in Review": "Richieste in Revisione",
  "Active Proposals": "Richieste Attive",
  "Proposals Concluded": "Richieste Concluse",
  "Publication completed": "Pubblicazione completata",
  "Publication, check to upload the invoice":
    "Pubblicazione completata, controlla per caricare la fattura",
  "Client's name": "Nome Cliente",
  Status: "Stato",
  "Search client": "Cerca Cliente",
  "Payment Date": "Data Pagamento",
  "Upload Invoice": "Carica fattura",
  "Upload Invoice Adv": "Carica fattura Adv",
  "Are you sure you want to upload this file?":
    "Sei sicuro di voler caricare questo file?",
  "Upload Article": "Carica Articolo",
  "To Approve": "Da Approvare",
  "Sort By": "Ordina per",
  "Items to approve": "Elementi da approvare",
  "Status Proposal": "Stato Richiesta",
  "Total Richiesta": "Totale Richiesta",
  "Site Name": "Nome Sito",
  "Request correction article": "Campagna correzione articolo",
  "You have requested to correct the following article":
    "Hai richiesto di correggere il seguente articolo",
  "View Article": "Visualizza articolo",
  "Request correction url": "Campagna correzione URL",
  "You have requested to correct the following url":
    "Hai richiesto di correggere il seguente URL",
  "View url": "Visualizza URL",
  "Enter correction reason": "Inserisci motivazione correzione",
  "Site Replacement Request": "Campagna Sostituzione Sito",
  "You have requested to replace the following site":
    "Hai richiesto di sostituire il seguente sito",
  "Enter replacement reason": "Inserisci motivazione sostituzione",
  "View URL": "Visualizza URL",
  View: "Visualizza",
  Grounds: "Motivazione",
  "Send inquiry": "Invia Campagna",
  "Proposal Name": "Nome Richiesta",
  "Enter number of words": "Inserisci numero parole",
  "Publication Type (optional)": "Tipologia Pubblicazione: (opzionale)",
  "Topic Article": "Argomento Articolo",
  Topic: "Tematica dei Siti",
  "URL Property": "URL Proprietà",
  "Enter the topic of the article": "Inserisci la tematica dell'articolo",
  "Tipologia Link (optional)": "Tipologia Link: (opzionale)",
  "Tipologia Link": "Tipologia Link:",
  Definitive: "Definitiva",
  "Select the quality": "Seleziona la qualita",
  "Replacement Items": "Elementi in Sostituzione ",
  "The proposal has been success deleted!":
    "La richiesta è stata cancellata con successo!",
  "Number Words is required!": "Numero di parole è richiesto!",
  "Item Quality": "Qualità Articolo",
  "Motivation is required!": "Motivazione è richiesto! ",
  "The offer has been successfully approved!":
    "L'offerta è stata approvata con successo!",
  "Approve Domain": "Approva Dominio",
  "Delete Domain": "Elimina Dominio",
  "In Replacement": "In Sostituzione",
  Replace: "Sostituisci",
  "The offer has been successfully refused!":
    "L'offerta è stata rifiutata con successo!",
  "Back to orders": "Torna alle Commesse",
  "Offer successfully replaced": "Offerta sostituita con Successo",
  "The offer has been correctly replaced and sent to the customer.":
    "L’offerta è stata correttamente sostituita e inviata al cliente.",
  "The approval status of the offer will be visible within the order page.":
    "Lo stato di approvazione dell’offerta sarà visibile all’interno delle pagina della commessa.",
  "Perform Replacement": "Effettua Sostituzione",
  "To Upload": "Da Caricare",
  "Upload URL": "Carica URL",
  "The url has been loaded!": "L'url è stato caricato!",
  "In Review": "in revisione",
  "In Payment": "in pagamento",
  "waiting bank transfer approval": "Bonifico",
  "To be invoiced": "da fatturare",
  "Upload Invoice pub": "Fattura da caricare",
  "Our Team has selected your property for a publication":
    "Il nostro Team ha selezionato la tua proprietà per una pubblicazione",
  "You approved the publication on your property":
    "Hai approvato la pubblicazione sulla tua proprietà",
  "The client is approving the publication on your property":
    "Il cliente sta approvando la pubblicazione sulla tua proprietà",
  "Upload the url of your publication": "Carica l'url della tua pubblicazione",
  "The request has ended, upload again the receipt to receive the payment":
    "La richiesta è terminata, carica la ricevuta per ricevere il pagamento",
  "Receipt sent, You will receive the payment according to the Terms and Conditions":
    "Ricevuta inviata, riceverai il pagamento secondo i Termini e Condizioni",
  "Your receipt has been successfully payed":
    "La tua ricevuta è stata correttamente pagata",

  // Users
  Users: "Utenti",
  "Users Present": "Utenti trovati",
  isConfirmed: "Confermato",
  Inactive: "Non attivo",
  userType: "Tipologia Utente",
  Client: "Admin",
  Advertiser: "Advertiser",
  Publisher: "Publisher",
  advertiserType: "Tipologia Advertiser",
  "Self Service": "Self Service",
  "Properties Count": "Numero proprietà",
  "Offers Count": "Numero offerte",

  // Notifications
  "New chat message": "Nuovo messaggio in chat",
  "New proposal": "Nuova Richiesta",
  "Review request": "Campagna in Revisione",
  "Property rejected": "Proprietà Rifiutata",
  "Approval article": "Approvazione Articolo",
  "Approved requested": "Campagna Approvata",
  "New request": "Nuova richiesta",
  "URL rejected": "URL Rifiutato",
  "Approved URL": "Approvazione URL",
  "Approved url": "Approvazione URL",
  "Upload article": "Upload Articolo",
  "Article approved": "Articolo Approvato",
  "Rejected article": "Articolo Rifiutato",
  "Article rejected advertiser": "Articolo rifiutato Advertiser",
  "Offer rejected publisher": "Offerta Rifiutata Publisher",
  "Offer rejected advertiser": "Offerta Rifiutata Advertiser",
  "Payment received": "Pagamento Ricevuto",
  "Article Rejected": "Articolo Rifiutato Publisher",
  "Article rejected publisher": "Articolo rifiutato Publisher",
  "Invoice to pay": "Fattura da Pagare ",
  "to pay publisher": "Da pagare Pub.",
  concluded: "Concluso",
  "A new request has been submitted by an advertiser":
    "Una nuova richiesta è stata presentata da un inserzionista",
  "A property has been selected for a publication":
    "Una proprietà è stata selezionata per una pubblicazione",
  "A selected property has been rejected by the Customer":
    "Una proprietà selezionata è stata rifiutata dal Cliente",
  "Article entered, check to approve":
    "Articolo inserito, controlla per approvare",
  "URL refused, check to replace": "URL rifiutato, controlla per sostituire",
  "Confirmed proposal, check to upload the article":
    "Richiesta confermata, controlla per caricare l'articolo",
  "Approved article, check to enter the URL":
    "Articolo approvato, controlla per inserire l'URL",
  "Rejected, check to replace": "Articolo rifiutato, controlla per sostituire",
  "Publication Completed - Publication, check to upload the invoice":
    "Pubblicazione Completata - Pubblicazione completata, controlla per caricare la fattura",
  "Formulated request, check to approve the publications":
    " Campagna formulata, controlla per approvare le pubblicazioni",
  "Publications approved, check to make the payment":
    "Pubblicazioni approvate, controlla per effettuare il pagamento",
  "Article inserted, check to approve":
    "Articolo inserito, controlla per approvare",
  "URL entered, check to approve": "URL inserito, controlla per approvare",
  "Offer rejected by the Publisher, check to replaces":
    "Offerta rifiutata dal Publisher, controlla per sostituire",
  "Offer rejected by the Advertiser, check to replace":
    "Offerta rifiutata dall'Advertiser, controlla per sostituire",
  "Order paid, check to monitor the publication":
    "Commessa pagata, controlla per monitorare la pubblicazione",
  "Publisher Article rejected by the Publisher, checks the status":
    "Articolo rifiutato dal Publisher, controlla lo stato",
  "Invoice to pay available, check to confirm":
    "Fattura da pagare disponibile, controlla per confermare",
  "Error: Network error: Response not successful: Received status code 400":
    "Errore: errore di rete: risposta non riuscita: codice stato ricevuto 400",
  Updated: "Aggiornamento",
  Error: "Errore",
  "Concluded job orders": "Commesse Chiuse",
  "Search publisher": "Ricerca Publisher",
  "Search advertiser": "Ricerca Advertiser",
  "Invoiced at": "Fatturato il",
  "Proposal name": "Nome richiesta",
  "Payment with credit card": "Pagamento con carta di credito",
  "Payment with bank transfer": "Pagamento con bonifico bancario",
  "Bank transfer uploaded": "Bonifico caricato",
  "Activities Summary": "Riepilogo Attività",
  "Current month revenue": "Fatturato mese in corso",
  "Total amount to pay": "Totale fatture da pagare",
  "Current month profit": "Utile mese in corso",
  "total requests": "campagna totali",
  "total proposals": "richieste totali",
  "Last 10 invoices to pay": "Ultime 10 fatture da pagare",
  "Last 10 new requests": "Ultime 10 nuove Campagne",
  "Terms and conditions": "Termini e Condizioni",
  "I declare that I have read the {{tag_start}}privacy policy{{tag_end}}":
    "Dichiaro di aver letto la {{tag_start}}Privacy Policy{{tag_end}}",
  "I declare that I have read and accept the {{tag_start}}terms and conditions{{tag_end}}":
    "Dichiaro di aver letto e di accettare i {{tag_start}}Termini e Condizioni{{tag_end}}.",
  "You must accept the Privacy Policy": "Devi accettare la Privacy Policy",
  "You must accept the Terms and Conditions":
    "Devi accettare i Termini e Condizioni",
  "Search Request": "Cerca Campagna",
  "Active Proposals total": "Totale richieste attive",
  "In payment proposals total": "Totale richieste in pagamento",
  "Paid proposals total": "Totale richieste pagate",
  "Next deadlines": "Prossime scadenze",
  "Incoming Summary": "Riepilogo Entrate",
  "WIP requests total": "Totale campagne in lavorazione",
  "To pay requests total": "Totale campagne da pagare",
  "Paid requests total": "Totale campagne pagate",
  "Publications summary": "Riepilogo Campagne",
  "Expenses Summary": "Riepilogo Spese",
  "To be approved": "Campagne da Approvare",
  "Active Requests": "Campagne Attive",
  Concluded: "Campagne Concluse",
  "If you own a blog, website or network, and want to start monetize by posting new high - quality sponsored content.":
    "Se possiedi un blog, un sito web o un network, e vuoi iniziare a monetizzare pubblicando nuovi contenuti sponsorizzati di alta qualità.",
  "If you want to increase the value and authority of your site due to the publication of new, original and high quality contents on numerous sites.":
    "Se desideri aumentare il valore e l'autorevolezza del tuo sito grazie alla pubblicazione di contenuti nuovi, originali e di alta qualità su numerosi siti.",
  "The costs and timing of the campaign will increase by around 10%":
    "I tempi della campagna aumenteranno del 10% circa",
  "The costs and timing of the campaign will increase by around 30%":
    "I tempi della campagna aumenteranno del 30% circa",
  standard:
    "Campagna Standard: Voglio ricevere un'offerta totalmente strutturata da voi secondo le mie indicazioni, approvando solo la lista di siti, senza dover decidere anchor text e pagine target di ogni pubblicazione",
  slow: "Campagna Lenta: Voglio poter approvare la lista di siti che mi manderete e specificare la pagina target e anchor text di ogni pubblicazione",
  controlled:
    "Campagna Controllata: Voglio poter approvare la lista di siti che mi manderete e specificare la pagina target e anchor text di ogni pubblicazione. Inoltre voglio approvare ogni singolo testo.",
  "fixed links": "Ottenere un numero preciso di link",
  "better awareness": "Migliorare l'awareness",
  "better google": "Migliorare il posizionamento su Google",
  Objective: "Obiettivo",
  "Months are required": "Numero mesi richiesto!",
  "Additional article margin is": "Margine addizionale da articoli",
  "Comma separated values": "Inserisci separati da una virgola",
  "Description and Notes": "Descrizione e Note",
  "Brief and Note": "Brief e Note",
  "Objectives and Notes": "Obiettivi di Posizionamento e Note",
  "What are the characteristics of the links you need? Specify in detail your needs and any constraints in order to get a detailed quote immediately":
    "Quali sono le caratteristiche dei link che ti servono? Specifica nel modo più dettagliato le tue esigenze ed eventuali vincoli in modo da ottenere un preventivo dettagliato subito",
  "Enter your strategic goals here":
    "Inserisci qui i tuoi obiettivi strategici",
  "What are your goals? What is your current traffic and where do you want to go with the countryside?":
    "Quali sono i tuoi obiettivi? Qual è il tuo traffico attuale e dove vuoi arrivare con la campagna?",
  "Monthly Budget (euro)": "Budget Mensile (euro)",
  "Activity Months": "Mesi di Attività",
  "Target Url": "Sito Target",
  "Objective site": "Sito oggetto dell'attività",
  "Is required!": "E' richiesto",
  "The article will be written by the client":
    "L'articolo verrà scritto dal cliente",
  "The article will be written by ReleVanty":
    "L'articolo verrà scritto da ReleVanty",
  "New Self Service Request": "Nuova campagna self service",
  "Select properties": "Seleziona proprietà",
  "Once confirmed you will not able to change offers. Then, you must enter additional information for each offer.":
    "Una volta confermato non sarà più possibile modificare le offerte. Nello step successivo ti sarà richiesto di aggiungere informazioni addizionali per ogni offerta prima di procedere al pagamento.",
  enableSelfserviceTip:
    "Vuoi che il tuo sito venga visto direttamente dagli advertiser? Clicca qui per rendere la tua proprietà visibile direttamente dai nostri clienti, potranno selezionarti attraverso la modalità self- service e il tuo sito avrà ancora più richieste.",
  "Do not show the site to clients": "Non mostrare il sito ai clienti",
  "Make the site visible to clients": "Rendi sito visibile ai clienti",
  "DA from": "DA da",
  "DA to": "DA a",
  "Are you sure tu use this coupon for this order? Once added you cannot remove it.":
    "Sei sicuro di usare questo codice sconto? Una volta aggiunto non potrà più essere rimosso.",
  "The payment of the invoices, as per the terms and conditions accepted during registration on the platform, is made at the end of the month following the upload of the invoice to the platform. Do you need the header data of the invoice?":
    "Il pagamento delle fatture, come da termini e condizioni accettate in fase di iscrizione alla piattaforma, viene effettuato alla fine del mese successivo al caricamento della fattura in piattaforma.Ti servono i dati di intestazione della fattura?",
  "Click here": "Clicca qui",
  "You can address the invoices to:": "Puoi intestare le fatture a:",
  Filter: "Filtra",
  "Are you sure you want to duplicate it?":
    "Sei sicuro di voler duplicare questa offerta?",
  "The offer has been successfully duplicated!":
    "Lofferta è stata duplicata con successo",
  "Invoiced at from": "Fatturato da",
  "Invoiced at to": "Fatturato a",
  "Add Offer": "Aggiungi offerta",
  Add: "Aggiungi",
  "Are you sure you want to add it?": "Sei sicuro di volerla aggiungere?",
  "The offer has been successfully added!":
    "L'offerta è stata aggiunta con successo",
  Impersonate: "Impersonifica",
  "Articles details": "Dettaglio articoli",
  "Are you sure you want to approve it?": "Sei sicuro di approvarla?",
  "Insert article info": "Inserisci info articolo",
  "Welcome back": "Ciao",
  "You have paid campaigns": "Hai {{ count }} campagne in pagamento",
  "activate them now": "attivale ora",
  "Be followed by our specialists": "Fatti seguire dai nostri specialist",
  "Publish in total autonomy": "Pubblica in totale autonomia",
  "Total campaigns in progress": "Totale campagne in lavorazione",
  "Total campaigns to pay": "Totale campagne da pagare",
  "Total completed campaigns": "Campagne totali completate",
  "View all": "Visuallizza Tutte",
  "Active Campaigns": "Campagne Attive",
  "Time For Writing": "Tempo Scrittura Articolo",
  "Article Language": "Lingua Scrittura Articolo",
  Italian: "Italiano",
  English: "Inglese",
  "Define the features of the article for the following domain":
    "Definisci le caratteristiche dell'articolo per il seguente dominio",
  "Article In Home": "Articolo presente in home",
  "Sponsored Tag": "Presenza del tag sponsorizzato",
  "New self service request form description":
    "Inserisci le informazioni della campagna e scegli fra i siti del network abilitati al self service. Ricorda che con le campagne seguite hai accesso a un numero maggiore di siti, oltre che a un supporto degli specialisti ByTek.",
  "Writing texts": "Scrittura dei testi",
  "Enter the information relating to the writing of the texts":
    "Inserisci le informazioni relative alla scrittura dei testi",
  "I will write all the texts": "Scriverò tutti i testi",
  "I need the writing of the texts": "Ho bisogno della scrittura dei testi",
  "Sites Categories": "Categorie Siti",
  "Select the categories more right for the publication":
    "Seleziona le categorie di siti più adatte alla tua pubblicazione",

  Animali: "Animali",
  "Cibo e Cucina": "Cibo e Cucina",
  Eventi: "Eventi",
  Generalisti: "Generalisti",
  Lifestyle: "Lifestyle",
  Motori: "Motori",
  Recensioni: "Recensioni",
  Shopping: "Shopping",
  Turismo: "Turismo",

  Arredamento: "Arredamento",
  "Cinema e TV": "Cinema e TV",
  Famiglia: "Famiglia",
  "Giornale Locale": "Giornale Locale",
  Marketing: "Marketing",
  Musica: "Musica",
  Risparmio: "Risparmio",
  Sport: "Sport",

  "Arte e Cultura": "Arte e Cultura",
  Donna: "Donna",
  Fisco: "Fisco",
  "Giornali Locali": "Giornali Locali",
  Matrimonio: "Matrimonio",
  Politica: "Politica",
  "Salute e Benessere": "Salute e Benessere",
  Storia: "Storia",

  Bellezza: "Bellezza",
  "Economia e Finanza": "Economia e Finanza",
  Formazione: "Formazione",
  "Giornali Nazionali": "Giornali Nazionali",
  Moda: "Moda",
  Professioni: "Professioni",
  Scienza: "Scienza",
  Tech: "Tech",

  "e.g Publication Guest Post relevanty.it":
    "e.g Pubblicazione Guest Post relevanty.it",
  "View Site List": "Visualizza Lista Siti",
  "Select the language of the articles": "Seleziona la lingua degli articoli",
  "Articles Quality": "Qualità Articoli",
  "Articles Language": "Lingua Articoli",
  "Select the quality of the articles": "Seleziona la qualità degli articoli",
  "Articles Length": "Lunghezza Articoli",
  "Select Number of Words": "Seleziona Numero Parole",

  High: "Alta",
  Medium: "Media",
  Low: "Bassa",
  Categories: "Categorie",

  //'Write Article': 'Scrittura articolo',
  "URL to Upload": "URL da caricare",
  "Article to Upload": "Post da caricare",
  37: "Dettaglio articoli",
  "waiting for article details": "Dettaglio articoli",

  "Welcome to ReleVanty": "Benvenuto su ReleVanty",
  "Thank you for completing your registration on ReleVanty,":
    "Grazie per aver completato la tua iscrizione su ReleVanty,",
  "To start publishing a new sponsored content, select the type of campaign you want to create":
    "Per iniziare la pubblicazione di un nuovo contenuto sponsorizzato, seleziona la tipologia di campagna che vuoi creare",
  "Start Campaign Followed by Our Team":
    "Inizia Campagna Seguita dal Nostro Team",
  "Choose this type of campaign and be followed step by step, during publication, by our specialists":
    "Scegli questa tipologia di campagna e fatti seguire passo dopo passo, durante la pubblicazione, dai nostri specialist",
  "Start Self Service Campaign": "Inizia Campagna Self Service",
  "Choose this type of campaign and select in total autonomy, among the available sites, those suitable for your publication":
    "Scegli questa tipologia di campagna e seleziona in totale autonomia, tra i siti disponibili, quelli adatti alla tua pubblicazione",
  "New Self-service Request": "Nuova Campagna Self Service",
  "Does the offer involve writing the article?":
    "L'offerta prevede la scrittura dell'articolo?",
  "in payment": "In pagamento",
  "Order note": "Note Commessa",
  "Enter Order note": "Inserisci Note Commessa",
  "Are you sure you want to set this deadline?":
    "Sei sicuro di voler impostare questa scadenza?",
  "Article to be published at": "Articolo da pubblicare il",
  "e.g Publication Guest Post relevanty.it":
    "e.g Pubblicazione Guest Post relevanty.it",
  "View Site List": "Visualizza Lista Siti",
  "Select the language of the articles": "Seleziona la lingua degli articoli",
  "Articles Quality": "Qualità Articoli",
  "Articles Language": "Lingua Articoli",
  "Select the quality of the articles": "Seleziona la qualità degli articoli",
  "Articles Length": "Lunghezza Articoli",
  "Select Number of Words": "Seleziona Numero Parole",

  High: "Alta",
  Medium: "Media",
  Low: "Bassa",
  Categories: "Categorie",

  "Write Article": "Scrittura articolo",
  "URL to Upload": "URL da caricare",
  "Article to Upload": "Post da caricare",
  37: "Dettaglio articoli",
  "waiting for article details": "Dettaglio articoli",

  "Welcome to ReleVanty": "Benvenuto su ReleVanty",
  "Thank you for completing your registration on ReleVanty,":
    "Grazie per aver completato la tua iscrizione su ReleVanty,",
  "To start publishing a new sponsored content, select the type of campaign you want to create":
    "Per iniziare la pubblicazione di un nuovo contenuto sponsorizzato, seleziona la tipologia di campagna che vuoi creare",
  "Start Campaign Followed by Our Team":
    "Inizia Campagna Seguita dal Nostro Team",
  "Choose this type of campaign and be followed step by step, during publication, by our specialists":
    "Scegli questa tipologia di campagna e fatti seguire passo dopo passo, durante la pubblicazione, dai nostri specialist",
  "Start Self Service Campaign": "Inizia Campagna Self Service",
  "Choose this type of campaign and select in total autonomy, among the available sites, those suitable for your publication":
    "Scegli questa tipologia di campagna e seleziona in totale autonomia, tra i siti disponibili, quelli adatti alla tua pubblicazione",
  "New Self-service Request": "Nuova Campagna Self Service",
  "Does the offer involve writing the article?":
    "L'offerta prevede la scrittura dell'articolo?",
  "in payment": "In pagamento",
  "Order note": "Note Commessa",
  "Enter Order note": "Inserisci Note Commessa",
  "Are you sure you want to set this deadline?":
    "Sei sicuro di voler impostare questa scadenza?",
  "Article to be published at": "Articolo da pubblicare il",

  Destination: "Destinazione",
  Kind: "Tipo",
  Discount: "Sconto",
  Enabled: "Abilitato",
  "Are you sure you want to create the coupon":
    "Sei sicuro di voler creare il coupon",
  "Coupon Name": "Nome del coupon",
  "Coupon ID": "ID coupon",
  "Coupon Description": "Descrizione del coupon",
  "Kind of Coupon": "Tipo di coupon",
  "Kind of Discount": "Tipo di sconto",
  "Total Number of Coupons": "Numero totale di coupon",
  "Minimum Expense": "Spesa minima",
  "Date Of Coupon Expiring": "Data di scadenza del coupon",
  "Enable Coupon": "Abilita coupon",
  "Create Coupon": "Crea Coupon",
  Percent: "Per cento",
  "Managed platform": "Piattaforma gestita",
  "Self Service Platform": "Piattaforma self-service",
  "Limited Number": "Numero limitato",
  "Expire At": "Scade alle",
  "This coupon can't be used inside this campaign":
    "Questo coupon non è utilizzabile all'interno di questa campagna",
  "Is the job of an enterprise type?": "La commessa è di tipo enterprise?"
};

export default it;
