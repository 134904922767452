import logo from "assets/Logo-White.png";
import Copyright from "components/Access/Copyright";
import LoginForm from "components/Access/Forms/LoginForm";
import Language from "components/Access/Language";
import Title from "components/Access/Title";
import * as path from "constants/routes";
import i18n, { lang } from "i18n";
import Cookies from "js-cookie";
import { login } from "mutations";
import { User } from "queries/queries";
import React, { useState } from "react";
import { compose, graphql } from "react-apollo";
import { generatePath } from "react-router";
import { Container } from "reactstrap";
import { saveData } from "utils";

const Login = (props) => {
  const [state] = useState({ error: "" });
  const login = async (values, { setErrors }) => {
    const { email, password } = values;
    try {
      const response = await props.login({
        variables: {
          username: email,
          password: password,
        },
      });
      if (!response.data.login.error) {
        const token = response.data.login.token;
        if (
          (process.env.REACT_APP_ROLES_FOR_NEW_APP || "")
            .split(",")
            .includes(String(response.data.login.user.userTypology))
        ) {
          Cookies.set("auth-token", response.data.login.token, {
            domain: process.env.REACT_APP_ROOT_DOMAIN,
          });
          return (window.location.href = process.env.REACT_APP_ADVERTIZER_URL);
        }

        await saveData("token", token);
        const lang = response.data.login.user.lang;
        window.localStorage.setItem("relevantyLng", lang);
        
        i18n.changeLanguage(lang);
        window.dataLayer.push({
          loginStatus: "logged",
          language: "it",
          userID: response.data.login.user.id,
          hashedEmail: response.data.login.user.email,
          commercialRole: response.data.login.user.userTypologyDisplay,
          contentType: "Homepage",
          pageType: "Login",
          event: "wpcf7successfulsubmit",
          eventCategory: "account action",
          eventAction: "login",
        });
        props.history.push(path.DASHBOARD);
      } else {
        const errors = {};
        response.data.login.error.validationErrors.map((error) => {
          if (error.field === "__all__") {
            errors.username = error.messages.join(" ");
            errors.password = error.messages.join(" ");
          } else {
            errors[error] = error.messages;
          }
          return null;
        });
        setErrors(errors);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="access-bg full-screen centered-content">
      <Container>
        <Title
          descTop={i18n.t("Dont have an account?")}
          image={logo}
          to={generatePath(path.HOME, { lng: lang })}
          path={i18n.t("Registration")}
        />
        <Language />
        <LoginForm login={login} error={state.error} />
        <Copyright />
      </Container>
    </div>
  );
};

export default compose(
  graphql(login, { name: "login" }),
  graphql(User, {
    name: "getUser",
  })
)(Login);
