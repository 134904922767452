const en = {
  'Password has to be longer than x characters':
    'Password has to be longer than {{count}} characters!',
  enableSelfserviceTip: 'Vuoi che il tuo sito venga visto direttamente dagli advertiser? Clicca qui per rendere la tua proprietà visibile direttamente dai nostri clienti, potranno selezionarti attraverso la modalità self- service e il tuo sito avrà ancora più richieste.',
  'You have paid campaigns': 'You have {{ count }} paid campaigns',
  'New self service request form description': 'Enter the campaign information and choose from the self-service enabled sites in the network. Remember that with the campaigns you follow you have access to a greater number of sites, as well as support from the ByTek specialists.',

  'Animali': 'Animals',
  'Cibo e Cucina': 'Food and Cuisine',
  'Eventi': 'Events',
  'Generalisti': 'Generalists',
  'Lifestyle': 'Lifestyle',
  'Motori': 'Engines',
  'Recensioni': 'Reviews',
  'Shopping': 'Shopping',
  'Turismo': 'Tourism',
  'Arredamento': 'Furniture',
  'Cinema e TV': 'Cinema and TV',
  'Famiglia': 'Family',
  'Giornale Locale': 'Local journal',
  'Marketing': 'Marketing',
  'Musica': 'Music',
  'Risparmio': 'Savings',
  'Sport': 'Sport',


  'Arte e Cultura': 'Art and culture',
  'Donna': 'Donna',
  'Fisco': 'Tax',
  'Giornali Locali': 'Local journals',
  'Matrimonio': 'Wedding',
  'Politica': 'Politics',
  'Salute e Benessere': 'Greetings and Wellness',
  'Storia': 'History',


  'Bellezza': 'Beauty',
  'Economia e Finanza': 'Economy and Finance',
  'Formazione': 'Formation',
  'Giornali Nazionali': 'National journals',
  'Moda': 'Fashion',
  'Professioni': 'Professions',
  'Scienza': 'Science',
  'Tech': 'Technology',
}

export default en
