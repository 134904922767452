import React from 'react'
import { graphql } from 'react-apollo'

import swal from 'sweetalert'
import * as path from 'constants/routes'
import { resetPassword } from 'mutations'
import i18n from 'i18n'
import logo from 'assets/Logo-White.png'

import { ResetPasswordForm } from 'components/Forms/ResetPasswordForm'
import { Container } from 'reactstrap'

import Title from 'components/Access/Title'
import Copyright from 'components/Access/Copyright'

const ResetPassword = props => {
  const resetPassword = (values, { setErrors }) =>
    new Promise((resolve, reject) => {
      props
        .resetPassword({
          variables: {
            ...values
          }
        })
        .then(response => {
          if (!response.data.resetPassword.error.validationErrors.length) {
            if (response.data.resetPassword.success) {
              swal({
                title: 'Successfully!',
                text: 'Your password has been changed successfully!',
                icon: 'success'
              })
              props.history.push(path.DASHBOARD)
              resolve()
            } else {
              swal({
                title: 'Your account is unconfirmed',
                icon: 'warning'
              })
            }
          } else {
            const errors = {}
            response.data.resetPassword.error.validationErrors.map(error => {
              if (error.field === '__all__') {
                errors.new_password2 = error.messages.join(' ')
              } else if (error.field === 'new_password2') {
                errors.newPassword2 = error.messages.join(' ')
              } else {
                errors[error] = error.messages
              }
              return null
            })
            setErrors(errors)
            reject()
          }
        })
    })
  return (
    <div className='access-bg full-screen centered-content'>
      <Container>
        <Title descTop={i18n.t('Reset password')} image={logo} to={path.HOME} />
        <ResetPasswordForm
          resetPassword={resetPassword}
          uid={props.match.params.uid}
          confirmToken={props.match.params.confirmToken}
        />
        <Copyright />
      </Container>
    </div>
  )
}

export default graphql(resetPassword, { name: 'resetPassword' })(ResetPassword)
