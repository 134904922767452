import React from 'react'
import { Redirect } from 'react-router-dom'
import { graphql, compose } from 'react-apollo'
import { withRouter } from 'react-router'

import { verifyToken } from 'mutations'
import { User } from 'queries/queries'
// import i18n from 'i18n'

export const withAuth = WrappedComponent => {
  class PrivateRoute extends React.Component {
    async componentDidMount () {
      try {
        const token = JSON.parse(window.localStorage.getItem('token')).token
        await this.props.authorization({
          variables: { token }
        })
      } catch (error) {
        window.localStorage.removeItem('token')
        return this.props.history.push('/')
      }
    }

    render () {
      const user = this.props.getUser
      if (user.loading) {
        return null
      } else if (user.error) {
        return <Redirect to='/' />
      }

      // const lang = user.me.lang
      // window.localStorage.setItem('relevantyLng', lang)
      // i18n.changeLanguage(lang)
      return user.me.isConfirmed ? (
        <WrappedComponent {...this.props} />
      ) : (
        <Redirect to='/' />
      )
    }
  }
  return compose(
    graphql(verifyToken, {
      name: 'authorization'
    }),
    graphql(User, {
      name: 'getUser'
    })
  )(withRouter(PrivateRoute))
}
