import React, { useState } from 'react'

import CreateRequestForm from 'components/Forms/CreateRequestForm'
import Close from 'components/Icons/Close'

import { withAuth } from 'hocs/PrivateRoute'
import { onlyAdvertiser } from 'hocs/componentByRole'
import * as path from 'constants/routes'
import { graphql } from 'react-apollo'
import { createRequest } from 'mutations'
import { getRequests } from 'queries/queries'
import i18n from 'i18n'
import swal from 'sweetalert'

const CreateRequest = props => {
  const [state] = useState({ error: '' })
  const [isSubmitted, setSubmitted] = useState(false)
  const handleCreateRequest = async (values, { setErrors }) => {
    const willCreate = await swal({
      title: i18n.t('Are you sure?'),
      text: i18n.t('Are you sure you want to create the request?'),
      icon: 'warning',
      buttons: true,
      dangerMode: true
    })

    console.log(values)
    if (willCreate) {
      setSubmitted(true)
      return new Promise((resolve, reject) => {
        const {
          name,
          objective,
          topic,
          targetUrl,
          months,
          velocity,
          linkNumber,
          rangeBudget,
          language,
          description,
          domainAuthority,
          articleWriting,
          itemQuality,
          isApproval
        } = values
        props
          .createRequest({
            variables: {
              name,
              objective: objective || 10,
              topic,
              targetUrl,
              months: months.value || 1,
              velocity: velocity || 10,
              linkNumber,
              rangeBudget: rangeBudget.value || 0,
              language: language.value,
              description,
              domainAuthority: domainAuthority || null,
              articleWriting: articleWriting,
              itemQuality: itemQuality.value || 1,
              isApproval: isApproval,
              user: props.getUser.me.id
            },
            refetchQueries: [
              { query: getRequests, variables: { userId: props.getUser.me.id } }
            ]
          })
          .then(response => {
            if (response.data.createRequest.success) {
              window.dataLayer.push({
                event: 'gaEvent',
                eventCategory: 'app relevanty',
                eventAction: 'richiesta effettuata',
                eventLabel: props.getUser.me.email
              })
              props.history.push(path.REQUESTS)
              resolve()
            } else {
              const errors = {}
              response.data.createRequest.error.validationErrors.map(
                error => (errors[error.field] = error.messages.join(' '))
              )
              setErrors(errors)
              setSubmitted(false)
              // reject(new Error())
            }
          }).finally(() => {
            console.log('FINALLY')
            props.history.push(path.REQUESTS)
            setSubmitted(false)
          }
          )
      })
    }
  }
  return (
    <div className='row justify-content-md-center p-5 m-0'>
      <div className='col-8'>
        <CreateRequestForm
          handleCreateRequest={handleCreateRequest}
          error={state.error}
          errors={props}
          isSubmitted={isSubmitted}
        />
        <Close onClick={() => props.history.push(path.DASHBOARD)} />
      </div>
    </div>
  )
}

export default graphql(createRequest, { name: 'createRequest' })(
  withAuth(onlyAdvertiser(CreateRequest))
)
