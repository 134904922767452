import { SET_CATEGORIES, REMOVE_CATEGORY } from '../constants'

export const setCategories = (selectedRowKeys, categories) => ({
  type: SET_CATEGORIES,
  selectedRowKeys,
  categories
})

export const removeCategory = (categories, key) => ({
  type: REMOVE_CATEGORY,
  categories,
  key
})
