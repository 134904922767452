import React, { useState } from 'react'

import CreateSelfServiceRequestForm from 'components/Forms/CreateSelfServiceRequestForm/createSelfServiceRequestForm'
import SetPropertyComponent from 'components/Forms/CreateSelfServiceRequestForm/setProperty'
import Close from 'components/Icons/Close'

import { withAuth } from 'hocs/PrivateRoute'
import { onlyAdvertiser } from 'hocs/componentByRole'
import * as path from 'constants/routes'
import {graphql, Query} from 'react-apollo'
import { createSelfServiceRequest } from 'mutations'
import {getPropertyCategories, getRequests} from 'queries/queries'
import i18n from 'i18n'
import swal from 'sweetalert'
import './styles.css'
import Loader from "../../../../components/Loader";

const CreateSelfServiceRequest = props => {
  const [state] = useState({ error: '' })
  const [isSubmitted, setSubmitted] = useState(false)
  const defaultValues = {
    name: '',
    objective: 10,
    topic: '',
    targetUrl: '',
    months: 1,
    velocity: 20,
    linkNumber: 1,
    rangeBudget: 0,
    language: 'it',
    description: '',
    domainAuthority: '',
    articleWriting: 2,
    itemQuality: 1,
    isApproval: 2,
  }
  const [request, setRequest] = useState(defaultValues)
  const [step, setStep] = useState(1)

  const handlePrepareRequest = async (values, { setErrors }) => {
    values.offers = []
    if (typeof values.itemQuality === 'object' && values.itemQuality.hasOwnProperty('value')) { values.itemQuality = values.itemQuality.value }
    if (typeof values.rangeBudget === 'object' && values.rangeBudget.hasOwnProperty('value')) { values.rangeBudget = values.rangeBudget.value }
    values.priceAugmented = request.priceAugmented
    setRequest(values)
    setStep(2)
  }

  const [selectedOffersKeys, setOffersKeys] = React.useState([])
  const [selectedOffersKeysCount, setOffersKeysCount] = React.useState(0)
  const handleOffersKeys = (key, arrIndex, a) => {
    let offersCount = 0
    selectedOffersKeys[arrIndex] = key
    selectedOffersKeys.join()
    setOffersKeys(selectedOffersKeys)
    selectedOffersKeys.map(item => (offersCount += item.length))
    setOffersKeysCount(offersCount)
  }

  const willCreateMessage = (request) => {
    if (request.velocity !== 10 && request.articleWriting === 1) {
      return i18n.t('Once confirmed you will not able to change offers. Then, you must enter additional information for each offer.')
    } else {
      return i18n.t('Are you sure you want to create the request?')
    }
  }

  const handleCreateRequest = async () => {
    const willCreate = await swal({
      title: i18n.t('Are you sure?'),
      text: willCreateMessage(request),
      icon: 'warning',
      buttons: true,
      dangerMode: true
    })

    if (willCreate) {
      return new Promise((resolve, reject) => {
        const requestData = {
          ...request,
          language: request.articleLanguage,
          averageNumberWords: request.articleLength,
          itemQuality: request.articleQuality
        }
        delete requestData.articleLanguage
        delete requestData.articleLength
        delete requestData.articleQuality

        delete requestData.siteCategories
        let requestId = null
        const values = {
          requestData: requestData,
          selectedOffers: selectedOffersKeys.flat()
        }

        props
          .createSelfServiceRequest({
            variables: values,
            refetchQueries: [
              { query: getRequests, variables: { userId: props.getUser.me.id } }
            ]
          })
          .then(response => {
            if (response.data.createSelfServiceRequest.success) {
              requestId = response.data.createSelfServiceRequest.requestId
              props.history.push(`/requests/${requestId}`)
              resolve()
            } else {
              const errors = {}
              console.error(response.data.createSelfServiceRequest.errors)
              response.data.createSelfServiceRequest.errors.map(
                error => {
                  console.error(error)
                  return errors[error.field] = error
                } // .messages.join(' '))
              )
              // setErrors(errors)
              // reject(errors)
              reject(swal({
                title: i18n.t('Error'),
                text: i18n.t('An error occured.'),
                icon: 'error',
                buttons: true,
                dangerMode: true
              }))
            }
          }).finally(() => {
            console.log('FINALLY')
            setSubmitted(false)
          })
      })
    }
  }

  const stepBack = (currentStep) => {
    let previousStep
    if (currentStep === 4 && (request.articleWriting === 2 && request.velocity < 20)) { previousStep = 2 } else { previousStep = currentStep - 1 }
    setStep(previousStep)
  }

  const backToDashboard = () => props.history.push(path.DASHBOARD);

  return (
    <Query query={getPropertyCategories} variables={{hasProperties: true}}>
      {({loading, error, data}) => {
        if (loading) return <Loader/>
        if (error) return `Error! ${error.message}`

        return (
          <div className='row justify-content-center m-0 wrapper'
               data-component='Dashboard/Advertiser/CreateSelfServiceRequest/index'>
            <div className={`${step === 1 ? "col-8" : "col-10"} p-0`}>
              {step === 1 && (
                <CreateSelfServiceRequestForm
                  handlePrepareRequest={handlePrepareRequest}
                  initialValues={request}
                  error={state.error}
                  errors={props}
                  isSubmitted={isSubmitted}
                  handleBack={backToDashboard}
                  siteCategories={data.propertyCategories.map(el => el.name)}
                />
              )}

              {step === 2 && (
                <SetPropertyComponent
                  {...props}
                  jobOrder={request}
                  request={request}
                  setOffersKeys={handleOffersKeys}
                  selectedOffersKeysCount={selectedOffersKeysCount}
                  handleCreateRequest={handleCreateRequest}
                  handleSteps={stepBack}
                  propertyCategories={data.propertyCategories}
                />
              )}
            </div>
            <Close onClick={backToDashboard}/>
          </div>
        )
      }}
    </Query>
  )
}

export default graphql(createSelfServiceRequest, { name: 'createSelfServiceRequest' })(
  withAuth(onlyAdvertiser(CreateSelfServiceRequest))
)
