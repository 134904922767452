import logo from 'assets/Logo-White.png'
import Copyright from 'components/Access/Copyright'
import SignUpForm from 'components/Access/Forms/SignUp'
import Language from 'components/Access/Language'
import Title from 'components/Access/Title'
import * as path from 'constants/routes'
import i18n, { lang } from 'i18n'
import { register } from 'mutations'
import React, { useState } from 'react'
import { graphql } from 'react-apollo'
import { generatePath } from 'react-router'
import { Container } from 'reactstrap'
import swal from 'sweetalert'
import { saveData } from 'utils'

const AdvertiserSignUp = props => {
  const [state] = useState({ error: '' })
  const register = (values, { setErrors }) =>
    new Promise((resolve, reject) => {
      const {
        name,
        surname,
        email,
        password1,
        password2,
        userTypology,
        advertiserType,
        companyName,
        // privacy,
        // terms,
        lang
      } = values
      // console.log(values)
      props
        .register({
          variables: {
            name: name,
            surname: surname,
            email: email,
            password1: password1,
            password2: password2,
            userTypology: userTypology,
            advertiserType: advertiserType,
            companyName: companyName,
            lang: lang
          }
        })
        .then(response => {
          if (response.data.register.success) {
            const token = response.data.register.token

            saveData('token', token)
            swal(i18n.t('Confirmation'), i18n.t('Please, check your email!'), 'warning')
            window.dataLayer.push({
              contentType: 'Registrazione',
              pageType: 'Registrazione',
              event: 'wpcf7successfulsubmit',
              eventCategory: 'account action',
              eventAction: 'registrazione',
              eventLabel: 'advertiser'
            })
            props.history.push(path.SIGN_IN)
            resolve()
          } else {
            const errors = {}
            response.data.register.error.validationErrors.map(
              error => (errors[error.field] = error.messages.join(' '))
            )
            setErrors(errors)
            reject()
          }
        })
    })
  return (
    <div className='access-bg full-screen centered-content'>
      <Container>
        <Title
          descTop={i18n.t('Do you already have an account?')}
          image={logo}
          to={generatePath(path.SIGN_IN, { lng: lang })}
          path={i18n.t('Login')}
          title={i18n.t('New Advertiser User')}
        />
        <Language />
        <SignUpForm
          register={register}
          error={state.error}
          userTypology={2}
          advertiserType={props.advertiserType}
        />
        <Copyright />
      </Container>
    </div>
  )
}

export default graphql(register, { name: 'register' })(AdvertiserSignUp)
