import './style.css'

import { Table } from 'antd'
import Loader from 'components/Loader'
import download from 'downloadjs'
import i18n from 'i18n'
import { getPaginatedProperties } from 'queries/queries'
import React, { useReducer, useState } from 'react'
import { Query } from 'react-apollo'
import { connect } from 'react-redux'
import { loadData, moneyField, paginationRender } from 'utils'

import Filters from './Filters'
import RespectivePropertyInfo from './respectivePropertyInfo.js'

const CustomExpandIcon = (props) =>
  props.expanded ? (
    <i
      className='fas fa-minus'
      onClick={(e) => props.onExpand(props.record, e)}
    />
  ) : (
    <i
      className='fas fa-plus'
      style={{ color: '#555555' }}
      onClick={(e) => props.onExpand(props.record, e)}
    />
  )

const initialState = {
  showHeader: false,
  selectedRowKeys: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_HEADER':
      return {
        ...state,
        showHeader: true
      }
    case 'HIDDEN_HEADER':
      return {
        ...state,
        showHeader: false,
        selectedRowKeys: []
      }
    case 'SELECTED_ROW_KEYS':
      return {
        ...state,
        selectedRowKeys: action.payload
      }
    default:
      return state
  }
}

const PropertiesComponent = props => {
  const [state] = useReducer(reducer, initialState)

  const [formFilters, setFormFilters] = useState({
    search: '',
    priceFrom: undefined,
    priceTo: undefined,
    category: undefined,
    type: undefined,
    country: undefined,
    language: undefined,
    daFrom: undefined,
    daTo: undefined,
    publisher: undefined,
    selfService: false
  })

  const [tmpFormFilters, setTmpFormFilters] = useState({
    search: '',
    priceFrom: undefined,
    priceTo: undefined,
    category: undefined,
    type: undefined,
    country: undefined,
    language: undefined,
    daFrom: undefined,
    daTo: undefined,
    publisher: undefined,
    selfService: false
  })

  const handleChangeValue = (event) => {
    setTmpFormFilters({
      ...tmpFormFilters,
      search: event
    })
  }

  const handlePriceFromValue = (event) => {
    if (isNaN(event)) {
      event = undefined
    }
    setTmpFormFilters({
      ...tmpFormFilters,
      priceFrom: event
    })
  }

  const handlePriceToValue = (event) => {
    if (isNaN(event)) {
      event = undefined
    }
    setTmpFormFilters({
      ...tmpFormFilters,
      priceTo: event
    })
  }

  const handleDAFromValue = (event) => {
    if (isNaN(event)) {
      event = undefined
    }
    setTmpFormFilters({
      ...tmpFormFilters,
      daFrom: event
    })
  }

  const handleDAToValue = (event) => {
    if (isNaN(event)) {
      event = undefined
    }
    setTmpFormFilters({
      ...tmpFormFilters,
      daTo: event
    })
  }

  const handleClickOnSelect = (key, event) => {
    setTmpFormFilters({
      ...tmpFormFilters,
      [key]: event
    })
  }

  const handleChangePublisher = (event) => {
    setTmpFormFilters({
      ...tmpFormFilters,
      publisher: event
    })
  }

  const handleSelfServiceValue = (event) => {
    console.log('handleSelfServiceValue', event)
    setTmpFormFilters({
      ...tmpFormFilters,
      selfService: event.target.checked //= == 'true'
    })
  }

  const onFilterButtonClick = () => {
    setFormFilters({ ...tmpFormFilters })
  }

  const handleSetPage = page => setPage(page)

  const [page, setPage] = useState(1)
  React.useEffect(() => {
    setPage(page)
  }, [page])

  const columnsProperties = [
    {
      title: i18n.t('Property name'),
      dataIndex: 'name',
      render: item => item || '-',
      width: 250,
      className: 'property-name'
    },
    {
      title: i18n.t('Category'),
      dataIndex: 'categories',
      render: item => item.map(({ id, name }) => (name)).join(', ') || '-',
      width: 180
    },
    {
      title: i18n.t('Type'),
      dataIndex: 'propertyType',
      render: item => i18n.t(item) || '-',
      width: 150
    },
    {
      title: i18n.t('Price'),
      dataIndex: 'offers',
      render: item => {
        if (formFilters.priceFrom) {
          const itms = item.filter(o => o.price >= formFilters.priceFrom)
          return moneyField.format(itms[0].price)
        } else {
          let price = 0
          if (item[0] && item[0].price) {
            price = item[0].price
          }
          return moneyField.format(price)
        }
      },
      width: 110
    },
    {
      title: i18n.t('Monthly Traffic'),
      dataIndex: 'monthlyTraffic',
      render: item => i18n.t(item) || '-',
      width: 180

    },
    {
      title: i18n.t('Language'),
      dataIndex: 'language',
      render: item => item || '-',
      width: 96
    },
    {
      title: i18n.t('DA'),
      dataIndex: 'domainAuthorityRating',
      render: item => item || '-',
      width: 58
    },
    {
      title: '',
      dataIndex: 'eye',
      className: 'text-center',
      render: item => item || ''
    }
  ]

  const { showHeader } = state

  const [fetching, setFetching] = useState(false)
  const fetchFile = async () => {
    const API_HOST = process.env.REACT_APP_API_HOST
    const url = `${API_HOST}/properties/list_csv`
    const filename = 'properties.csv'
    setFetching(true)

    try {
      const resp = await window.fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${loadData('token')}`
        },
        body: JSON.stringify(tmpFormFilters)
      })

      const blob = await resp.blob()
      download(blob, filename)

      if (!resp.ok) {
        throw Error(resp.statusText)
      }

      setFetching(false)
    } catch (error) {
      console.log(error.toString())
    }
  }

  return (
    <Query
      query={getPaginatedProperties}
      fetchPolicy='network-only'
      variables={{
        page: page,
        pageSize: 10,
        userId: false,
        withOffer: false,
        // selfService: false,
        ...formFilters
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />
        if (error) return `Error! ${error.message}`

        let offers = []
        for (const prop in data.paginatedProperties.objects) {
          offers = offers.concat(data.paginatedProperties.objects[prop].offers)
        }
        state.offers = offers

        const items = data.paginatedProperties.objects
        return (
          <>
            <div className='mt-4 text'>
              {i18n.t('Sites Present')}:
              <span className='pl-3' style={{ color: '#9777ba' }}>
                {data.paginatedProperties ? data.paginatedProperties.total : 0}
              </span>
            </div>
            <div className='table-container'>
              <Filters
                filters={tmpFormFilters}
                onChangeSearch={handleChangeValue}
                handleClickOnSelect={handleClickOnSelect}
                handlePriceFromValue={handlePriceFromValue}
                handlePriceToValue={handlePriceToValue}
                handleDAFromValue={handleDAFromValue}
                handleDAToValue={handleDAToValue}
                onFilterButtonClick={onFilterButtonClick}
                propertyCategories={props.propertyCategories}
                onChangePublisher={handleChangePublisher}
                handleSelfServiceValue={handleSelfServiceValue}
                fetching={fetching}
                fetchFile={fetchFile}
                foundItems={items.length}
              />
              <Table
                style={{ border: 'none' }}
                showHeader
                className='offers-table offers-table-none'
                columns={columnsProperties}
                rowKey='id'
              />
              <Table
                rowSelection={null}
                showHeader={showHeader}
                className='offers-table offers-table-background'
                pagination={{
                  itemRender: paginationRender,
                  total: data.paginatedProperties.total,
                  current: page,
                  onChange: handleSetPage
                }}
                columns={columnsProperties}
                dataSource={items}
                expandIcon={CustomExpandIcon}
                expandIconAsCell={false}
                expandIconColumnIndex={columnsProperties.length - 1}
                expandedRowRender={item => (
                  <RespectivePropertyInfo
                    data={item}
                    setOffersKeys={props.setOffersKeys}
                    jobOrder={props.jobOrder}
                    substituteClickHandle={props.substituteClickHandle}
                    formFilters={formFilters}
                  />
                )}
                rowKey='id'
              />
            </div>
          </>
        )
      }}
    </Query>
  )
}

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  }
}

export default connect(mapStateToProps)(PropertiesComponent)
