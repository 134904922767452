import './styles.scss'

import { Badge } from 'antd'
import Loader from 'components/Loader'
import * as path from 'constants/routes'
import i18n from 'i18n'
import { MDBDropdown } from 'mdbreact'
import { getNotificationsCount, getUserUnreadMessages } from 'queries/queries'
import React, { useState } from 'react'
import { Query } from 'react-apollo'
import IosChatbubbles from 'react-ionicons/lib/IosChatbubbles'
import IosNotifications from 'react-ionicons/lib/IosNotifications'
import { Link } from 'react-router-dom'

const Notifications = props => {
  let permission = null
  const audio = new Audio('notification.oga')
  const [counter, setCounter] = useState(null)
  return (
    <MDBDropdown>
      <div>
        {(props.user.userTypology === 1 || props.user.userTypology === 2) && (
          <Query query={getUserUnreadMessages} variables={{ userId: props.user.id }} pollInterval={5000}>
            {({ data, loading }) => {
              if (loading) {
                return <Loader />
              } else {
                try {
                  Notification.requestPermission().then(function (result) {
                    permission = result
                  })
                  // console.log('permission', permission)

                  if (counter && data.userUnreadMessages.count > 0 && data.userUnreadMessages.count > counter) {
                    // console.warn('New message')
                    try {
                      audio.play()
                    } catch (error) {
                      console.log(error.toString())
                    }
                    if (permission === 'granted') {
                      console.warn('permission', permission)
                      new Notification('Relevanty', { body: i18n.t('New chat message') })
                    }
                  }
                } catch (error) {
                  console.log(error.toString())
                }
                setCounter(data.userUnreadMessages.count)

                return (
                  <Link to={path.CHATS}>
                    <Badge count={counter}>
                      <IosChatbubbles fontSize='30px' color='white' />
                    </Badge>
                  </Link>
                )
              }
            }}
          </Query>
        )}
        <Query query={getNotificationsCount} variables={{ userId: props.user.id }}>
          {({ data, loading }) => {
            if (loading) return <Loader />
            return (
              <>
                {data && data.notificationsCount && (
                  <Link to={path.NOTIFICATIONS}>
                    <Badge count={data.notificationsCount.count}>
                      <IosNotifications fontSize='30px' color='white' />
                    </Badge>
                  </Link>
                )}
              </>
            )
          }}
        </Query>
      </div>
    </MDBDropdown>
  )
}

export default Notifications
