import React from 'react'
import { Container } from 'mdbreact'
import i18n from '../../i18n'
import './style.css'

const PageNotFound = () => (
  <Container>
    <div className='wrapp'>
      <h1>{i18n.t('Page not found')}</h1>
    </div>
  </Container>
)

export default PageNotFound
