import './style.css'

import IconAdd from 'components/Icons/Add'
import { CREATE_SELF_SERVICE_REQUEST } from 'constants/routes'
import i18n from 'i18n'
import { Container, Navbar } from 'mdbreact'
import { createRoom } from 'mutations'
import { User } from 'queries/queries'
import React from 'react'
import { compose, graphql } from 'react-apollo'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

import logo from '../../../assets/icona-Logo.png'

const Child = ({
  title,
  actionName,
  path,
  icon: Icon,
  chat,
  createRoom,
  history,
  advertiserId,
  jobOrderId,
  ownerId,
  hideSelf,
  getUser
}) => {
  const handleCreateRoom = async (clientId) => {
    const response = await createRoom({
      variables: {
        users: [parseInt(clientId), advertiserId],
        jobOrder: jobOrderId
      }
    })
    if (!response.data.createRoom.error) {
      // history.push({
      //   pathname: `/chat/${response.data.createRoom.room.id}`,
      //   state: { clientId }
      // })
      window.location.href = `/chat/${response.data.createRoom.room.id}`
    } else {
      swal({
        title: response.data.createRoom.error.validationErrors[0].messages,
        icon: 'error'
      })
    }
  }
  const me = getUser.me
  return (
    <Navbar className='flexible-navbar' dark expand='md' data-component='NavBar/ChildNavBar/index.jsx'>
      <Container>
        <div className='child-navbar'>
          <div className='flex-centr'>
            <img src={logo} className='logo' alt='' />
            <h4>{title}</h4>
          </div>
          {me && me.userTypology === 2 && !hideSelf && (
            <div className='flex-centr'>
              <Link to={CREATE_SELF_SERVICE_REQUEST}><p>{i18n.t('New Self Service Request')}</p></Link>
              <Link to={CREATE_SELF_SERVICE_REQUEST}>
                <IconAdd />
              </Link>
            </div>
          )}
          {actionName && path && (
            <div className='flex-centr'>
              <Link to={path}>
                <p>{actionName}</p>
              </Link>
              <Link to={path}>
                <Icon />
              </Link>
            </div>
          )}
          {chat && ownerId && (
            <div className='chat-block'>
              {i18n.t('Job order chat')}
              <button onClick={() => handleCreateRoom(ownerId)}>
                <i className='fas fa-comments' />
              </button>
            </div>
          )}
        </div>
      </Container>
    </Navbar>
  )
}

export default compose(
  graphql(User, { name: 'getUser' }),
  graphql(createRoom, { name: 'createRoom' })
)(Child)
