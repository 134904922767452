import { Icon, Table } from 'antd'
import Loader from 'components/Loader'
import i18n from 'i18n'
import { generateSso } from 'mutations'
import { getPaginatedUsers } from 'queries/queries'
import React, { useState } from 'react'
import { Query, graphql } from 'react-apollo'
import swal from 'sweetalert'
import { elipsedText, paginationRender } from 'utils'

import Filters from './Filters'
import RespectiveUserInfo from './respectiveUserInfo.js'

const CustomExpandIcon = (props) =>
  props.expanded ? (
    <i
      className='fas fa-minus'
      onClick={(e) => props.onExpand(props.record, e)}
    />
  ) : (
    <i
      className='fas fa-plus'
      style={{ color: '#555555' }}
      onClick={(e) => props.onExpand(props.record, e)}
    />
  )

const ShowUsersComponent = props => {
  const [page, setPage] = useState(1)

  const [formFilters, setFormFilters] = useState({
    name: undefined,
    surname: undefined,
    email: undefined,
    isConfirmed: undefined,
    status: undefined,
    advertiserType: undefined,
    selfService: undefined
  })

  const [tmpFormFilters, setTmpFormFilters] = useState({
    name: undefined,
    surname: undefined,
    email: undefined,
    isConfirmed: undefined,
    status: undefined,
    advertiserType: undefined,
    selfService: undefined
  })

  const onFilterButtonClick = () => {
    setFormFilters({ ...tmpFormFilters })
  }

  const handleChangeValue = (key, event) => {
    setTmpFormFilters({
      ...tmpFormFilters,
      [key]: event
    })
  }

  const impersonate = async (userId) => {
    const response = await props.generateSso({
      variables: { userId: userId }
    })
    if (
      !response.data.generateSso.error ||
      !response.data.generateSso.errors.length
    ) {
      await swal({
        title: 'URL SSO valida per i prossimi 10 minuti',
        text: `${process.env.REACT_APP_PUBLIC_URL}/sso/${response.data.generateSso.token}`,
        icon: 'success',
        buttons: false,
        dangerMode: false
      })
    } else {
      swal({
        title: 'Error',
        icon: 'error'
      })
    }
  }

  const columnsUsers = [
    {
      title: i18n.t('Profile Name'),
      dataIndex: 'userName',
      render: item => item || '-',
      width: 200,
      className: 'user-name'
    },
    {
      title: i18n.t('Email'),
      dataIndex: 'email',
      render: item => elipsedText(item, 30),
      width: 200
    },
    {
      title: i18n.t('Status'),
      dataIndex: 'isConfirmed',
      render: item => (
        <>
          {item && (
            <Icon type='check' style={{ fontSize: '1rem', color: 'green' }} />
          )}
          {!item && (
            <Icon type='cross' style={{ fontSize: '1rem', color: 'red' }} />
          )}
        </>
      ),
      width: 50
    },
    {
      title: i18n.t('userType'),
      dataIndex: 'userTypologyDisplay',
      render: item => i18n.t(item) || '-',
      width: 130
    },
    {
      title: i18n.t('advertiserType'),
      dataIndex: 'advertiserTypeDisplay',
      render: item => i18n.t(item) || '-',
      width: 130
    },    
    {
      title: '',
      dataIndex: 'eye',
      className: 'text-center',
      render: item => item || '',
      width: 50
    }
  ]

  return (
    <Query
      query={getPaginatedUsers}
      fetchPolicy='network-only'
      variables={{
        page: page,
        pageSize: 10,
        ...formFilters
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />
        if (error) return `Error! ${error.message}`

        const items = data.paginatedUsers.objects

        return (
          <>
            <div className='mt-4 mx-4 text'>
              {i18n.t('Users Present')}:
              <span className='pl-3' style={{ color: '#9777ba' }}>
                {data.paginatedUsers ? data.paginatedUsers.total : 0}
              </span>
            </div>
            <div className='table-container'>
              <Filters
                filters={tmpFormFilters}
                handleChangeValue={handleChangeValue}
                onFilterButtonClick={onFilterButtonClick}
              />
              <Table
                rowSelection={null}
                rowKey='id'
                className=''
                pagination={{
                  itemRender: paginationRender,
                  total: data.paginatedUsers.total,
                  current: page,
                  onChange: setPage
                }}
                columns={columnsUsers}
                dataSource={items}
                expandIcon={CustomExpandIcon}
                expandIconAsCell={false}
                expandIconColumnIndex={columnsUsers.length - 1}
                expandedRowRender={item => (
                  <RespectiveUserInfo
                    data={item}
                    impersonate={impersonate}
                  // setOffersKeys={props.setOffersKeys}
                  // jobOrder={props.jobOrder}
                  // substituteClickHandle={props.substituteClickHandle}
                  // formFilters={formFilters}
                  />
                )}
              />
            </div>
          </>
        )
      }}
    </Query>
  )
}

export default graphql(generateSso, { name: 'generateSso' })(ShowUsersComponent)
