import React, { useState } from 'react'
import { Query, graphql } from 'react-apollo'

import Loader from 'components/Loader'
import swal from 'sweetalert'

import { editOffer } from 'mutations'
import { getProperty, getOffer } from 'queries/queries'
import { withAuth } from 'hocs/PrivateRoute'
import { onlyPublisher } from 'hocs/componentByRole'

import EditOfferForm from 'components/Forms/EditOfferForm'
import Close from 'components/Icons/Close'
import i18n from 'i18n'

import * as path from 'constants/routes'

const EditOffer = props => {
  const [state] = useState({ error: '' })
  const handleEditOffer = (values, { setErrors }) =>
    new Promise((resolve, reject) => {
      const {
        name,
        description,
        price,
        link,
        linkType,
        publicationType,
        prohibitedArticles,
        isTemporary,
        expiresAt,
        articleWriting,
        timeForWriting,
        language
      } = values
      props
        .editOffer({
          variables: {
            property: props.match.params.propertyId,
            offerId: props.match.params.offerId,
            name: name.value || name,
            description: description,
            price: price,
            link: link,
            linkType: linkType.value || linkType,
            publicationType: publicationType.value || publicationType,
            prohibitedArticles: prohibitedArticles,
            isTemporary: isTemporary === 'true',
            expiresAt: isTemporary === 'true' ? expiresAt : null,
            articleWriting: articleWriting === 'true',
            timeForWriting: articleWriting === 'true' ? parseInt(timeForWriting.value) : null,
            language: articleWriting === 'true' ? language.value : null,
          },
          refetchQueries: [
            {
              query: getProperty,
              variables: { id: props.match.params.propertyId }
            }
          ]
        })
        .then(response => {
          if (response.data.updateOffer.success) {
            let cheaper = response.data.updateOffer.cheaperOffers;
            if (cheaper > 0) {
              swal({
                title: i18n.t('Please reduce price to appear on Self Service'),
                text: cheaper > 1 ?
                  i18n.t('There are cheaper offers', { number: cheaper }) : 
                  i18n.t('There is a cheaper offer'),
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
              })
            }
            props.history.push(
              path.SAVED_ACTION_OFFER,
              props.match.params.propertyId
            )
            resolve()
          } else {
            const errors = {}
            response.data.updateOffer.error.validationErrors.map(
              error => (errors[error.field] = error.messages.join(' '))
            )
            setErrors(errors)
            reject()
          }
        })
    })
  return (
    <div className='row justify-content-md-center p-5 m-0'>
      <Query query={getOffer} variables={{ id: props.match.params.offerId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />
          if (error) return `Error! ${error.message}`
          return (
            <div className='col-8'>
              <EditOfferForm
                handleEditOffer={handleEditOffer}
                error={state.error}
                errors={props}
                defaultValues={data.offer}
              />
              <Close onClick={() => props.history.push(path.PROPERTIES)} />
            </div>
          )
        }}
      </Query>
    </div>
  )
}

export default graphql(editOffer, { name: 'editOffer' })(
  withAuth(onlyPublisher(EditOffer))
)
