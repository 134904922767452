import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router'

import * as path from './constants/routes'

import Main from './pages/Main'
import PublisherSignUp from './pages/Access/SignUp/Publisher'
import AdvertiserType from './pages/Access/SignUp/Advertiser/AdvertiserType'
import AdvertiserSignUp from './pages/Access/SignUp/Advertiser'
import Login from './pages/Access/Login'
import SsoLogin from './pages/Access/SsoLogin'
import ConfirmEmail from './pages/Access/ConfirmEmail'
import ForgotPassword from './pages/Access/ForgotPassword'
import ResetPassword from './pages/Access/ResetPassword'
import CreateProperty from './pages/Dashboard/Publisher/CreateProperty'
import EditProperty from './pages/Dashboard/Publisher/EditProperty'
import SavedProperty from './components/Dashboard/Publisher/Properties/SavedProperty'
import CreateOffer from './pages/Dashboard/Publisher/CreateOffer'
import EditOffer from './pages/Dashboard/Publisher/EditOffer'
import SavedOffer from './components/Dashboard/Publisher/Properties/Property/SavedOffer'
import CreateRequest from './pages/Dashboard/Advertiser/CreateRequest'
import AdminCreateRequest from './pages/Dashboard/Client/CreateRequest'
import CreateSelfServiceRequest from './pages/Dashboard/Advertiser/CreateSelfServiceRequest'
import UpdateSelfServiceRequest from './pages/Dashboard/Advertiser/UpdateSelfServiceRequest'
import CategoriesSelection from './pages/Dashboard/Client/CategoriesSelection'
import ShowProperties from './pages/Dashboard/Client/ShowProperties'
import ShowUsers from './pages/Dashboard/Client/ShowUsers'
import ShowCoupons from './pages/Dashboard/Client/ShowCoupons'
import CreateCoupon from './pages/Dashboard/Client/CreateCoupon'
import AdminEditUser from './pages/Dashboard/Client/AdminEditUser'
import OrderMargin from './pages/Dashboard/Client/OrderMargin'
import OrderUpdateMargin from './pages/Dashboard/Client/OrderUpdateMargin'
import OrderSetOwner from './pages/Dashboard/Client/OrderSetOwner'
import Dashboard from './components/Dashboard'
import Loader from './components/Loader'

const Properties = lazy(() => import('./pages/Dashboard/Publisher/Properties'))
const Property = lazy(() =>
  import('./pages/Dashboard/Publisher/Properties/Property')
)
const Proposals = lazy(() => import('./pages/Dashboard/Publisher/Proposals'))
const Proposal = lazy(() =>
  import('./pages/Dashboard/Publisher/Proposals/Proposal')
)

const MainDashboard = lazy(() => import('./pages/Dashboard'))
const OrdersTabs = lazy(() => import('./pages/Dashboard/Client/OrdersTabs'))
const InvoicesTabs = lazy(() =>
  import('./pages/Dashboard/Client/InvoicesTabs')
)
const OrderSaving = lazy(() => import('./pages/Dashboard/Client/OrderSaving'))
const Order = lazy(() => import('./pages/Dashboard/Client/Order'))
const OrderChat = lazy(() => import('./pages/Dashboard/Client/OrderChat'))
// const SubstituteOrderOffer = lazy(() =>
//   import('./pages/Dashboard/Client/SubstituteOrderOffer')
// )
const AddOrderOffer = lazy(() =>
  import('./pages/Dashboard/Client/AddOrderOffer')
)
const TotalMarginality = lazy(() =>
  import('./pages/Dashboard/Client/TotalMarginality')
)

const Request = lazy(() =>
  import('./pages/Dashboard/Advertiser/Requests/Request')
)

const PayRequest = lazy(() =>
  import('./pages/Dashboard/Advertiser/Requests/PayRequest')
)

const Requests = lazy(() => import('./pages/Dashboard/Advertiser/Requests'))

const Profile = lazy(() => import('./pages/Dashboard/Profile'))
const ConfirmPasswordReset = lazy(() =>
  import('./pages/Access/ConfirmPasswordReset')
)
const Notifications = lazy(() => import('./pages/Dashboard/Notifications'))
const PageNotFound = lazy(() => import('./components/PageNotFound'))
const Chats = lazy(() => import('./pages/Dashboard/Chats'))

export default () => (
  <Switch>
    <Route exact path={path.HOME} component={Main} />
    <Route exact path={path.PUBLISHER_SIGN_UP} component={PublisherSignUp} />
    <Route exact path={path.SSO_LOGIN} component={SsoLogin} />
    <Route
      exact
      path={path.ADVERTISER_TYPE}
      render={(props) => <AdvertiserType {...props} />}
    />
    <Route
      exact
      path={path.ADVERTISER_COMPANY_SIGN_UP}
      render={(props) => <AdvertiserSignUp {...props} advertiserType={2} />}
    />
    <Route
      exact
      path={path.ADVERTISER_FREELANCE_SIGN_UP}
      render={(props) => <AdvertiserSignUp {...props} advertiserType={1} />}
    />
    <Route exact path={path.SIGN_IN} component={Login} />
    <Route
      exact
      path={path.CREATE_PROPERTY}
      render={(props) => <CreateProperty {...props} />}
    />
    <Route
      exact
      path={path.EDIT_PROPERTY}
      render={(props) => <EditProperty {...props} />}
    />
    <Route
      exact
      path={path.SAVED_ACTION_PROPERY}
      render={(props) => <SavedProperty {...props} />}
    />
    <Route
      exact
      path={path.CREATE_OFFER}
      render={(props) => <CreateOffer {...props} />}
    />
    <Route
      exact
      path={path.EDIT_OFFER}
      render={(props) => <EditOffer {...props} />}
    />
    <Route
      exact
      path={path.SAVED_ACTION_OFFER}
      render={(props) => <SavedOffer {...props} />}
    />
    <Route
      exact
      path={path.CREATE_REQUEST}
      render={(props) => <CreateRequest {...props} />}
    />
    <Route
      exact
      path={path.CREATE_SELF_SERVICE_REQUEST}
      render={props => <CreateSelfServiceRequest {...props} />}
    />
    <Route
      exact
      path={path.UPDATE_SELF_SERVICE_REQUEST}
      render={props => <UpdateSelfServiceRequest {...props} />}
    />
    <Route
      exact
      path={path.CONFIRM_EMAIL}
      render={(props) => <ConfirmEmail {...props} />}
    />
    <Route
      exact
      path={path.FORGOT_PASSWORD}
      render={(props) => <ForgotPassword {...props} />}
    />
    <Route
      exact
      path={path.RESEND_ACTIVATION}
      render={(props) => <ForgotPassword {...props} />}
    />
    <Route
      exact
      path={path.CATEGORIES_SELECTION}
      render={(props) => <CategoriesSelection {...props} />}
    />
    <Route exact path={path.ORDER_MARGIN} component={OrderMargin} />
    <Route exact path={path.ORDER_UPDATE_MARGIN} component={OrderUpdateMargin} />
    <Route exact path={path.ORDER_SET_OWNER} component={OrderSetOwner} />
    <Route
      exact
      path={path.RESET_PASSWORD}
      render={(props) => <ResetPassword {...props} />}
    />
    <Dashboard>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            exact
            path={path.PROPERTIES}
            render={(props) => <Properties {...props} />}
          />
          <Route
            exact
            path={path.PROPERTY}
            render={(props) => <Property {...props} />}
          />
          <Route
            path={path.DASHBOARD}
            render={(props) => <MainDashboard {...props} />}
          />
          <Route
            exact
            path={path.REQUEST}
            render={(props) => <Request {...props} />}
          />
          <Route
            exact
            path={path.REQUESTS}
            render={(props) => <Requests {...props} />}
          />
          <Route
            exact
            path={path.PAY_REQUEST}
            render={(props) => <PayRequest {...props} />}
          />
          <Route
            exact
            path={path.PROFILE}
            render={(props) => <Profile {...props} />}
          />
          <Route
            exact
            path={path.CONFIRM_PASSWORD_RESET}
            render={(props) => <ConfirmPasswordReset {...props} />}
          />
          <Route
            exact
            path={path.SAVE_ORDERS}
            render={(props) => <OrderSaving {...props} />}
          />
          <Route
            exact
            path={path.TOTAL_MARGINALITY}
            render={(props) => <TotalMarginality type='total' {...props} />}
          />
          <Route
            exact
            path={path.PERSONALIZED_MARGIN}
            render={(props) => (
              <TotalMarginality type='personalized' {...props} />
            )}
          />
          <Route
            exact
            path={path.ORDER}
            render={(props) => <Order {...props} />}
          />
          <Route
            exact
            path={path.ORDER_CHAT}
            render={(props) => <OrderChat {...props} />}
          />
          <Route
            exact
            path={path.ADD_ORDER_OFFER}
            render={(props) => <AddOrderOffer {...props} />}
          />
          <Route
            exact
            path={path.PROPOSALS}
            render={(props) => <Proposals {...props} />}
          />
          <Route
            exact
            path={path.PROPOSAL}
            render={(props) => <Proposal {...props} />}
          />
          <Route
            exact
            path={path.NOTIFICATIONS}
            render={(props) => <Notifications {...props} />}
          />
          <Route
            path={path.INVOICES}
            render={(props) => <InvoicesTabs {...props} />}
          />
          <Route
            path={path.SHOWPROPERTIES}
            render={(props) => <ShowProperties {...props} />}
          />
          <Route
            path={path.SHOWUSERS}
            render={(props) => <ShowUsers {...props} />}
          />
          <Route
            path={path.SHOWCOUPONS}
            render={(props) => <ShowCoupons {...props} />}
          />
          <Route
            path={path.CREATE_COUPON}
            render={(props) => <CreateCoupon {...props} />}
          />
          <Route
            path={path.ADMIN_EDIT_USER}
            render={(props) => <AdminEditUser {...props} />}
          />
          <Route
            exact
            path={path.ADMIN_CREATE_REQUEST}
            render={(props) => <AdminCreateRequest {...props} />}
          />
          <Route
            exact
            path={path.CHATS}
            render={(props) => <Chats {...props} />}
          />
          <OrdersTabs />
          {/* <InvoicesTabs /> */}
          <Route render={(props) => <PageNotFound {...props} />} />
        </Switch>
      </Suspense>
    </Dashboard>
  </Switch>
)
