import React from 'react'

import NavBar from 'components/NavBar'
import Footer from 'components/Footer'
import { DEBUG } from '../../constants'
import { withAuth } from 'hocs/PrivateRoute'

const Dashboard = props => (
  <div className='flexible-content'>
    <div className={'main-container' + (DEBUG === 'true' ? ' debug' : '')}>
      <NavBar {...props} />
      {props.children}
      <Footer />
    </div>
  </div>
)

export default withAuth(Dashboard)
