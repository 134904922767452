import React from 'react'
import { Link } from 'react-router-dom'

import { Row, Col } from 'antd'

import Close from 'components/Icons/Close'
import { DefaultButtonIcon } from 'components/Forms/DefaultButton'

import i18n from 'i18n'

const SavedOffer = props => {
  const { location, history } = props
  const backToProperty = `/properties/${location.state}`
  return (
    <div 
      className='row justify-content-md-center p-5 center' 
      data-component='Dashboard/Publisher/Properties/Property/SavedOffer'>
      <div className='col-6'>
        <h1 className='title'>{i18n.t('Saved Offer')}</h1>
        <p className='subtitle'>{i18n.t('Congratulations!')}</p>
        <p className='subtitle'>
          {i18n.t('Your offer has been successfully changed')}
        </p>
        <div className='p-5'>
          <Row>
            <Col span={12}>
              <Link to={backToProperty}>
                <DefaultButtonIcon
                  label={i18n.t('Back to the Offers')}
                  icon={<i className='fas fa-sign-out-alt' />}
                  type='default'
                />
              </Link>
            </Col>
            <Link to={`/properties/${location.state}/create-offer`}>
              <Col span={12}>
                <DefaultButtonIcon
                  label={i18n.t('New offer')}
                  icon={<i className='fas fa-plus' />}
                  type='default'
                />
              </Col>
            </Link>
          </Row>
        </div>
        <Close onClick={() => history.push(backToProperty)} />
      </div>
    </div>
  )
}

export default SavedOffer
