require('dotenv').config()
export const BACKEND_URL = process.env.REACT_APP_API_HOST
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY'
export const SET_OFFERS = 'SET_OFFERS'

export const SET_BADGE_NOTIFICATION = 'SET_BADGE_NOTIFICATION'

export const TOKEN = 'token'
export const MIN_PASSWORD_LENGTH = 8

// Proposal STATUS constants
export const PROPOSAL_TO_APPROVE = 1
export const PROPOSAL_APPROVED = 2
export const PROPOSAL_IN_REVIEW = 3
export const PROPOSAL_URL_TO_UPLOAD = 4
export const PROPOSAL_UPLOAD_INVOICE = 5
export const PROPOSAL_IN_PAYMENT = 6
export const PROPOSAL_PAID = 7
export const PROPOSAL_ARTICLE_TO_UPLOAD = 8

export const PROPOSAL_STATUSES = {}
PROPOSAL_STATUSES[PROPOSAL_TO_APPROVE] = 'To Approve'
PROPOSAL_STATUSES[PROPOSAL_APPROVED] = 'Approved'
PROPOSAL_STATUSES[PROPOSAL_IN_REVIEW] = 'In Review'
PROPOSAL_STATUSES[PROPOSAL_URL_TO_UPLOAD] = 'URL to Upload'
PROPOSAL_STATUSES[PROPOSAL_ARTICLE_TO_UPLOAD] = 'Article to Upload'
PROPOSAL_STATUSES[PROPOSAL_UPLOAD_INVOICE] = 'Upload Invoice'
PROPOSAL_STATUSES[PROPOSAL_IN_PAYMENT] = 'In Payment'
PROPOSAL_STATUSES[PROPOSAL_PAID] = 'Paid'

// JobOrder STATUS constants
export const JOB_ORDER_TO_COMPOSE = 1
export const JOB_ORDER_IN_APPROVAL = 2
export const JOB_ORDER_IN_PAYMENT = 3
export const JOB_ORDER_WBT_APPROVAL = 35
export const JOB_ORDER_WAD = 37
export const JOB_ORDER_UNDER_PROCESSING = 4
export const JOB_ORDER_CONCLUDED = 20

export const JOB_ORDER_STATUSES = {}
JOB_ORDER_STATUSES[JOB_ORDER_TO_COMPOSE] = 'to compose'
JOB_ORDER_STATUSES[JOB_ORDER_IN_APPROVAL] = 'in approval'
JOB_ORDER_STATUSES[JOB_ORDER_IN_PAYMENT] = 'in payment'
JOB_ORDER_STATUSES[JOB_ORDER_UNDER_PROCESSING] = 'under processing'
JOB_ORDER_STATUSES[JOB_ORDER_CONCLUDED] = 'concluded'
JOB_ORDER_STATUSES[JOB_ORDER_WAD] = 'article definition'

// Reuqest STATUS Constants
export const REQUEST_IN_PROGRESS = 1
export const REQUEST_TO_BE_APPROVED = 2
export const REQUEST_TO_PAY = 3
export const REQUEST_WBT_APPROVAL = 35
export const REQUEST_TO_SET_ARTICLES = 37
export const REQUEST_ACTIVE = 4
export const REQUEST_CLOSED = 5
export const REQUEST_DELETED = 6

export const REQUEST_STATUSES = {}
REQUEST_STATUSES[REQUEST_IN_PROGRESS] = 'in progress'
REQUEST_STATUSES[REQUEST_TO_BE_APPROVED] = 'to be approved'
REQUEST_STATUSES[REQUEST_TO_PAY] = 'to pay'
REQUEST_STATUSES[REQUEST_ACTIVE] = 'active'
REQUEST_STATUSES[REQUEST_CLOSED] = 'closed'
REQUEST_STATUSES[REQUEST_DELETED] = 'deleted'


export const REQUEST_COLORS = {}
REQUEST_COLORS[JOB_ORDER_TO_COMPOSE] = '#898bd3'
REQUEST_COLORS[REQUEST_TO_BE_APPROVED] = '#9fcb74'
REQUEST_COLORS[REQUEST_TO_PAY] = '#f2ca71'
REQUEST_COLORS[REQUEST_ACTIVE] = '#9fcb74'
REQUEST_COLORS[REQUEST_CLOSED] = '#898bd3'
REQUEST_COLORS[REQUEST_DELETED] = '#f2ca71'

// User Role constants
export const CLIENT_ROLE = 1
export const ADVERTISER_ROLE = 2
export const PUBLISHER_ROLE = 3

// Post approval STATUS Constants
export const POST_APPROVAL_POST_TO_UPLOAD = 10
export const POST_APPROVAL_ADVERTISER_POST_UNDER_REVISION = 20
export const POST_APPROVAL_ADVERTISER_POST_REFUSED = 30
export const POST_APPROVAL_ADVERTISER_POST_APPROVED = 40
export const POST_APPROVAL_PUBLISHER_POST_REFUSED = 50
export const POST_APPROVAL_PUBLISHER_POST_APPROVED = 60

export const POST_APPROVAL_STATUSES = {}
POST_APPROVAL_STATUSES[POST_APPROVAL_POST_TO_UPLOAD] = 'Post to upload'
POST_APPROVAL_STATUSES[POST_APPROVAL_ADVERTISER_POST_UNDER_REVISION] = 'Advertiser post under revision'
POST_APPROVAL_STATUSES[POST_APPROVAL_ADVERTISER_POST_REFUSED] = 'Advertiser post refused'
POST_APPROVAL_STATUSES[POST_APPROVAL_ADVERTISER_POST_APPROVED] = 'Advertiser post approved'
POST_APPROVAL_STATUSES[POST_APPROVAL_PUBLISHER_POST_REFUSED] = 'Publisher post refused'
POST_APPROVAL_STATUSES[POST_APPROVAL_PUBLISHER_POST_APPROVED] = 'Publisher post approved'

export const POST_APPROVAL_COLORS = {}
POST_APPROVAL_COLORS[POST_APPROVAL_POST_TO_UPLOAD] = '#898bd3'
POST_APPROVAL_COLORS[POST_APPROVAL_ADVERTISER_POST_UNDER_REVISION] = '#9fcb74'
POST_APPROVAL_COLORS[POST_APPROVAL_ADVERTISER_POST_REFUSED] = '#f2ca71'
POST_APPROVAL_COLORS[POST_APPROVAL_ADVERTISER_POST_APPROVED] = '#9fcb74'
POST_APPROVAL_COLORS[POST_APPROVAL_PUBLISHER_POST_REFUSED] = '#898bd3'
POST_APPROVAL_COLORS[POST_APPROVAL_PUBLISHER_POST_APPROVED] = '#f2ca71'

export const DEBUG = process.env.REACT_APP_DEBUG