import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Table } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { paginationRender, moneyField } from 'utils'
import i18n from 'i18n'

import { setOffers } from 'actions/orders'

const columns1 = [
  {
    dataIndex: 'x',
    render: (item) => item || '-',
    width: 62
  },
  {
    title: i18n.t('Offer Name'),
    dataIndex: 'name',
    render: (item) => item || '-',
    width: 250
  },
  {
    title: i18n.t('Price'),
    dataIndex: 'price',
    width: 200
  },
  {
    title: i18n.t('Type'),
    dataIndex: 'linkType',
    render: (item) => i18n.t(item) || '-',
    width: 150
  },
  {
    title: i18n.t('Publication'),
    dataIndex: 'publicationType',
    render: (item) => i18n.t(item) || '-'
  },
  {
    title: '',
    dataIndex: '',
    width: 1
  }
]

const CustomExpandIcon = (props) =>
  props.expanded ? (
    <i
      className='fas fa-minus'
      onClick={(e) => props.onExpand(props.record, e)}
    />
  ) : (
    <i
      className='fas fa-plus'
      style={{ color: '#555555' }}
      onClick={(e) => props.onExpand(props.record, e)}
    />
  )

const OffersTable = ({
  data,
  match,
  deleteOffers,
  userType,
  setOffersKeys,
  setOffers,
  jobOrder,
  property,
  substituteClickHandle,
  showButton,
  setShowButton
}) => {
  const stateOffers =
    userType === 1
      ? jobOrder && jobOrder.offers
        .map((item) => item.offer)
        .filter(
          (orderItem) =>
            property.offers
              .map((propertyItem) => propertyItem.id)
              .includes(orderItem.id) && orderItem
        )
      : []

  const [selectedRowKeys, setRowKeys] = React.useState(
    stateOffers.map((item) => item.id)
  )

  userType === 1 &&
    React.useEffect(() => {
      // setOffers(stateOffers, property.id)
      // setOffersKeys(selectedRowKeys, property.id)
      if (stateOffers[0]) {
        setOffers(stateOffers, stateOffers[0].id)
        setOffersKeys(selectedRowKeys, stateOffers[0].id)
      }
      setRowKeys(selectedRowKeys)
    }, [])

  const onSelectChange = (selectedRowKeys, item) => {
    if (userType === 1) {
      setOffers(item, data[0].id)
      setOffersKeys(selectedRowKeys, data[0].id)
      setRowKeys(selectedRowKeys)
    } else {
      setRowKeys(selectedRowKeys)
    }
  }

  const handleDeleteOffers = async () => {
    await deleteOffers(selectedRowKeys)
    setRowKeys([])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const columns = [
    {
      title: () => (
        <>
          <span>{i18n.t('Select all')}</span>
          {!userType && (
            <button
              className='table-btn'
              type='primary'
              onClick={handleDeleteOffers}
            >
              <span>{i18n.t('Delete')} </span>
            </button>
          )}
        </>
      ),
      dataIndex: 'name',
      render: (item) => item || '-',
      width: 250
    },
    {
      dataIndex: 'price',
      render: (item) => moneyField.format(item) || '',
      width: 200
    },
    {
      dataIndex: 'linkType',
      render: (item) => i18n.t(item) || '-',
      width: 150
    },
    {
      dataIndex: 'publicationType',
      render: (item) => i18n.t(item) || '-'
    },
    {
      title: '',
      dataIndex: '',
      width: 1
    }
  ]

  return (
    <>
      <Table
        showHeader
        className='offers-table offers-table-none'
        columns={columns1}
        rowKey='id'
      />
      <Table
        className='offers-table offers-table-background'
        rowSelection={rowSelection}
        showHeader={selectedRowKeys.length >= 1}
        columns={columns}
        dataSource={data}
        expandIcon={CustomExpandIcon}
        expandIconAsCell={false}
        expandIconColumnIndex={5}
        pagination={{
          itemRender: paginationRender
        }}
        expandedRowRender={(item) =>
          !userType ? (
            <div className='offer-detail'>
              <p className='label m-0'>{item.description}</p>
              <div className='my-3 border-bottom' />
              <span className='label float-left'>
                <span className='mr-2' style={{ fontSize: '18px' }}>
                  {i18n.t('Prohibited items')}
                </span>
                {item.prohibitedArticles}
              </span>
              <div className='float-right'>
                <div className='actions'>
                  <Link
                    to={`/properties/${match.params.id}/edit-offer/${item.id}/`}
                  >
                    <button>
                      <i className='fas fa-edit' />
                    </button>
                  </Link>
                  <button
                    onClick={() => deleteOffers([item.id])}
                    className='delete'
                  >
                    <i className='far fa-trash-alt' />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className='offer-detail'>
              <span className='label'>{i18n.t('Description')}</span>
              <br />
              {item.description}
              {
                item.language && (
                  <div>
                    <br />
                    <span className='label'>{i18n.t('Article Language')}: </span>
                    {item.language}
                  </div>
                )
              }
              <span className='label'>{i18n.t('Non-Allowed Topics')}: </span>
              {item.prohibitedArticles}
              <div className='my-3 border-bottom' />
              {substituteClickHandle ? (
                <>
                  {!showButton && (
                    <div
                      style={{ cursor: 'pointer' }}
                      className='float-right text-success'
                      onClick={(e) => {
                        e.preventDefault()
                        substituteClickHandle(item)
                        setShowButton(false)
                      }}
                    >
                      {i18n.t('Perform Replacement')}
                      <i className='far fa-lg fa-check-circle text-success pl-2' />
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{ cursor: 'pointer' }}
                  className='float-right text-success'
                  onClick={(e) => {
                    e.preventDefault()
                    const checkbox =
                      document.querySelectorAll(
                        `[data-row-key="${item.id}"]`
                      )[1] ||
                      document.querySelectorAll(
                        `[data-row-key="${item.id}"]`
                      )[0]
                    checkbox
                      .querySelector('.ant-table-selection-column')
                      .querySelector('.ant-checkbox-input')
                      .click()
                  }}
                >
                  {i18n.t('Enter in the Job Order')}
                  <i className='far fa-lg fa-check-circle text-success pl-2' />
                </div>
              )}
            </div>
          )}
        rowKey='id'
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders.offers
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setOffers }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OffersTable))
