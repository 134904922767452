import React, { useState } from 'react'
import i18n from 'i18n'
import { Formik, Form } from 'formik'
import Select from 'react-select'
import DefaultButton from 'components/Forms/DefaultButton'
import { BUTTON_COLOR } from 'constants/colors'
import Loader from 'components/Loader'


const OrderOwnerForm = props => {
  const [selectedOwner, setSelectedOwner] = useState(props.owner ? props.owner.id : null)

  if (props.clients.loading) return <Loader />
  const ownerValues = props.clients.clients.sort((a, b) => a.userName.localeCompare(b.userName)).map(({ id, userName }) => ({
    label: userName,
    value: id
  }))

  const submitForm = (values, actions) => {
    values.owner = selectedOwner
    return props.onSubmit(values, actions)
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={submitForm}
    >
      {props => {
        const {
          // values,
          // touched,
          // errors,
          // setFieldValue,
          setFieldTouched
        } = props
        return(
          <Form className='mt-5' data-component='components/Forms/OrderOwnerForm'>
            <div className='col-6 m-auto justify-content-center'>
              <h1 className='pb-3'>{i18n.t('Owner of the Job Order')}</h1>
              <div className=''>
                <div className='position-relative form-group'>
                  <label className='label-color'>{i18n.t('Owner')}</label>
                  <Select
                    id='owner'
                    options={ownerValues}
                    multi={false}
                    clearable={true}
                    onChange={value => setSelectedOwner((value && value.value) ? value.value : null)}
                    onBlur={() => setFieldTouched('owner')}
                    value={selectedOwner}
                    className='field-select'
                  />                    
                </div>
              </div>

              <div className=''>
                <DefaultButton
                  type='submit'
                  style={{
                    backgroundColor: BUTTON_COLOR,
                    width: '100%'
                  }}
                >
                  {i18n.t('Enter Owner')}
                </DefaultButton>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OrderOwnerForm
