import * as path from 'constants/routes'
import i18n from 'i18n'
import Cookies from "js-cookie";
import { ssoLogin } from 'mutations'
import React, { useEffect } from 'react'
import { compose, graphql } from 'react-apollo'
import { saveData } from 'utils'

const SsoLogin = props => {
  useEffect(() => {
    async function doLogin () {
      try {
        const response = await props.ssoLogin({
          variables: {
            ssotoken: props.match.params.token
          }
        })
        if (!response.data.ssoLogin.error ||
          !response.data.ssoLogin.errors.length) {
          const token = response.data.ssoLogin.token
          
          if (
            (process.env.REACT_APP_ROLES_FOR_NEW_APP || "")
              .split(",")
              .includes(String(response.data.ssoLogin.user.userTypology))
          ) {
            Cookies.set("auth-token", response.data.ssoLogin.token, {
              domain: process.env.REACT_APP_ROOT_DOMAIN,
            });
            return (window.location.href = process.env.REACT_APP_ADVERTIZER_URL);
          }

          await saveData('token', token)          

          const lang = response.data.ssoLogin.user.lang
          window.localStorage.setItem('relevantyLng', lang)
          i18n.changeLanguage(lang)
          window.dataLayer.push({
            loginStatus: 'logged',
            language: 'it',
            userID: response.data.ssoLogin.user.id,
            hashedEmail: response.data.ssoLogin.user.email,
            commercialRole: response.data.ssoLogin.user.userTypologyDisplay,
            contentType: 'Homepage',
            pageType: 'Login',
            event: 'wpcf7successfulsubmit',
            eventCategory: 'account action',
            eventAction: 'login'
          })
          props.history.push(path.DASHBOARD)
        } else {
          props.history.push('/')
        }
      } catch (error) {
        props.history.push('/')
      }
    }
    doLogin()
  })
  return (<></>)
}

export default compose(
  graphql(ssoLogin, { name: 'ssoLogin' })
)(SsoLogin)
