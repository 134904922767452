import React from "react";

import { Field, Form, Formik } from "formik";
import DefaultField from "../DefaultField";

import { CreateRequestSchema } from "./validation";
import {
  labelFor,
  languages,
  objectiveValues,
  requestQualities,
} from "utils/api";
import i18n from "i18n";

import "react-select/dist/react-select.css";
import classnames from "classnames";
import Radio from "../NewComponents/Radio";
import Checkbox from "../NewComponents/Checkbox";
import Button from "../NewComponents/Button";
import Select from "../NewComponents/Select";
import { getFromArrayByKey } from "../../../utils/arrays";

const UpdateSelfServiceRequestForm = (props) => {
  const submitForm = (values, actions) => {
    values.objective = values.objective.value;
    values.articleLanguage = values.articleLanguage
      ? values.articleLanguage.value
      : null;
    values.articleQuality = values.articleQuality
      ? values.articleQuality.value
      : null;
    return props.handlePrepareRequest(values, actions);
  };

  const handleBack = () => props.handleBack();

  const { language, itemQuality, averageNumberWords, objective } =
    props.initialValues;

  const initialValues = {
    ...props.initialValues,
    articleLanguage:
      language && getFromArrayByKey(languages, "value", language),
    articleQuality:
      itemQuality && getFromArrayByKey(requestQualities, "value", itemQuality),
    articleLength: averageNumberWords,
    objective: getFromArrayByKey(objectiveValues, "value", objective),
    siteCategories: new Set(),
  };
  delete initialValues.language;
  delete initialValues.itemQuality;
  delete initialValues.averageNumberWords;

  const handleChangeArticleQuality = (setFieldValue) => (value) => {
    setFieldValue("articleQuality", value);

    const qualityLength = {
      1: 600,
      2: 500,
      3: 400,
    };
    setFieldValue("articleLength", qualityLength[value.value]);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={CreateRequestSchema}
      onSubmit={submitForm}
    >
      {({ setFieldValue, errors, values, touched }) => (
        <div data-component="Forms/UpdateSelfServiceRequestForm/updateSelfServiceRequestForm">
          <p className="title">{i18n.t("Update Self-service Request")}</p>
          <Form>
            <div className="w-100">
              <p className="label m-0">{i18n.t("Request Title")}</p>
              <DefaultField
                name="name"
                type="text"
                errors={errors.name}
                touched={touched.name}
                placeholder={i18n.t("e.g Publication Guest Post relevanty.it")}
              />
            </div>
            <div className="d-flex mt-4">
              <div className="col-6 p-0 pr-1">
                <Select
                  options={objectiveValues}
                  label={i18n.t("Objective")}
                  value={values.objective}
                  onChange={(value) => setFieldValue("objective", value)}
                />
              </div>
              <div className="col-6 p-0 pl-1">
                <p className="label m-0">
                  {labelFor(
                    values.objective.value,
                    "targetUrl",
                    i18n.t("Target Url")
                  )}
                </p>
                <DefaultField
                  name="targetUrl"
                  type="url"
                  placeholder="e.g https://www.relevanty.it"
                  errors={errors.targetUrl}
                  touched={touched.targetUrl}
                />
              </div>
            </div>

            <div className="position-relative form-group col-12 p-0 mt-4">
              <p className="label m-0">
                {labelFor(values.objective.value, "description")}
              </p>
              <Field
                name="description"
                rows={8}
                type="text"
                className={classnames(
                  "form-control rounded-0 description-input p-3",
                  { "is-invalid": errors.description && touched.description }
                )}
                placeholder={labelFor(
                  values.objective.value,
                  "descriptionLabel"
                )}
                component="textarea"
              />
              <div className="invalid-feedback">{i18n.t("Is required!")}</div>
            </div>
            <p className="sub-title">{i18n.t("Writing texts")}</p>
            <p className="description">
              {i18n.t(
                "Enter the information relating to the writing of the texts"
              )}
            </p>

            <div className="d-flex flex-column">
              <Radio
                label={i18n.t("I will write all the texts")}
                checked={values.articleWriting === 2}
                onChange={() => setFieldValue("articleWriting", 2)}
                className="mb-3"
              />
              <Radio
                label={i18n.t("I need the writing of the texts")}
                checked={values.articleWriting === 1}
                onChange={() => setFieldValue("articleWriting", 1)}
              />
            </div>
            {values.articleWriting === 1 && (
              <div className="d-flex flex-row mt-3 justify-content-between">
                <Select
                  options={languages}
                  label={i18n.t("Articles Language")}
                  placeholder={i18n.t("Select the language of the articles")}
                  containerClassName="p-0 w-33"
                  onChange={(value) => setFieldValue("articleLanguage", value)}
                  error={errors.articleLanguage}
                  touched={touched.articleLanguage}
                  value={values.articleLanguage}
                />
                <Select
                  options={requestQualities}
                  label={i18n.t("Articles Quality")}
                  placeholder={i18n.t("Select the quality of the articles")}
                  containerClassName="p-0 w-33"
                  onChange={handleChangeArticleQuality(setFieldValue)}
                  error={errors.articleQuality}
                  touched={touched.articleQuality}
                  value={values.articleQuality}
                />
                <div className="p-0 w-33">
                  <p className="label m-0">{i18n.t("Articles Length")}</p>
                  <DefaultField
                    name="articleLength"
                    placeholder={i18n.t("Select the quality of the articles")}
                    errors={errors.articleLength}
                    touched={touched.articleLength}
                    disabled
                  />
                </div>
              </div>
            )}
            <p className="sub-title">{i18n.t("Sites Categories")}</p>
            <p className="description">
              {i18n.t("Select the categories more right for the publication")}
            </p>
            <div className="d-flex flex-wrap mb-3">
              {props.siteCategories.map((name) => (
                <div className="col-3 p-0" key={name}>
                  <Checkbox
                    checked={values.siteCategories.has(name)}
                    label={i18n.t(name)}
                    className="mb-2"
                    onChange={() => {
                      values.siteCategories.has(name)
                        ? values.siteCategories.delete(name)
                        : values.siteCategories.add(name);
                      setFieldValue("siteCategories", values.siteCategories);
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex w-100 justify-content-center mt-4">
              <Button
                text={i18n.t("Come back")}
                className="mr-2 secondary-button"
                onClick={handleBack}
              />
              {!props.isSubmitted && (
                <Button
                  text={i18n.t("View Site List")}
                  type="submit"
                  className="primary-button"
                />
              )}
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default UpdateSelfServiceRequestForm;
