import React from 'react'

import './style.css'
import CrossIcon from "../../../assets/Cross.svg";

const Close = ({ onClick }) => (
  <img
    src={CrossIcon}
    alt='cross'
    onClick={onClick}
    className='on-close'
  />
)

export default Close
