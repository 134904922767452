import Yup from 'yup'
import i18n from 'i18n'

// export const CreateRequestSchema = Yup.object().shape({
//   name: Yup.string().max(49).required(i18n.t('Is required!')),
//   selectedObjective: Yup.number().integer().required(),
//   language: Yup.string().required(i18n.t('Is required!')),
//   topic: Yup.string().required(i18n.t('Is required!')),
//   targetUrl: Yup.string().url().required(i18n.t('Is required!')),
//   itemQuality: Yup.string().required(i18n.t('Is required!')),
//   description: Yup.string().required(i18n.t('Is required!')),

//   linkNumber: Yup.number().required(i18n.t('Is required!')),
//   deadline: Yup.date().required(i18n.t('Is required!'))

// })

export const CreateRequestSchema10 = Yup.object().shape({
  name: Yup.string().max(49).required(i18n.t('Is required!')),
  selectedObjective: Yup.number().integer().required(),
  language: Yup.string().required(i18n.t('Is required!')),
  topic: Yup.string().required(i18n.t('Is required!')),
  targetUrl: Yup.string().url().required(i18n.t('Is required!')),
  itemQuality: Yup.string().required(i18n.t('Is required!')),
  description: Yup.string().required(i18n.t('Is required!')),

  linkNumber: Yup.number().required(i18n.t('Is required!'))
  // domainAuthority: Yup.number().integer().required(i18n.t('Is required!')),
})

export const CreateRequestSchema20 = Yup.object().shape({
  name: Yup.string().max(49).required(i18n.t('Is required!')),
  selectedObjective: Yup.number().integer().required(),
  language: Yup.string().required(i18n.t('Is required!')),
  topic: Yup.string().required(i18n.t('Is required!')),
  targetUrl: Yup.string().url().required(i18n.t('Is required!')),
  itemQuality: Yup.string().required(i18n.t('Is required!')),
  description: Yup.string().required(i18n.t('Is required!'))
})

export const CreateRequestSchema30 = Yup.object().shape({
  name: Yup.string().max(49).required(i18n.t('Is required!')),
  selectedObjective: Yup.number().integer().required(),
  language: Yup.string().required(i18n.t('Is required!')),
  topic: Yup.string().required(i18n.t('Is required!')),
  targetUrl: Yup.string().url().required(i18n.t('Is required!')),
  itemQuality: Yup.string().required(i18n.t('Is required!')),
  description: Yup.string().required(i18n.t('Is required!'))
})
