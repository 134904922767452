import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Table } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { paginationRender, moneyField } from 'utils'
import i18n from 'i18n'

import { setOffers } from 'actions/orders'

// const columns1 = [
//   {
//     dataIndex: 'x',
//     render: (item) => item || '-',
//     width: 62
//   },
//   {
//     title: i18n.t('Offer Name'),
//     dataIndex: 'name',
//     render: (item) => item || '-',
//     width: 356
//   },
//   {
//     title: i18n.t('Price'),
//     dataIndex: 'price',
//     width: 230
//   },
//   {
//     title: i18n.t('Type'),
//     dataIndex: 'linkType',
//     render: (item) => i18n.t(item) || '-',
//     width: 190
//   },
//   {
//     title: i18n.t('Publication'),
//     dataIndex: 'publicationType',
//     render: (item) => i18n.t(item) || '-'
//   },
//   {
//     title: '',
//     dataIndex: '',
//     width: 1
//   }
// ]

const CustomExpandIcon = (props) =>
  props.expanded ? (
    <i
      className='fas fa-minus'
      onClick={(e) => props.onExpand(props.record, e)}
    />
  ) : (
    <i
      className='fas fa-plus'
      style={{ color: '#555555' }}
      onClick={(e) => props.onExpand(props.record, e)}
    />
  )

const OffersTable = ({
  data,
  match,
  deleteOffers,
  userType,
  setOffersKeys,
  setOffers,
  jobOrder,
  property,
  substituteClickHandle,
  showButton,
  setShowButton
}) => {
  const stateOffers = []
  // userType === 1
  //   ? jobOrder.offers
  //       .map((item) => item.offer)
  //       .filter(
  //         (orderItem) =>
  //           property.offers
  //             .map((propertyItem) => propertyItem.id)
  //             .includes(orderItem.id) && orderItem
  //       )
  //   : [];

  const [selectedRowKeys, setRowKeys] = React.useState(
    stateOffers.map((item) => item.id)
  )

  userType === 1 &&
    React.useEffect(() => {
      // setOffers(stateOffers, property.id)
      // setOffersKeys(selectedRowKeys, property.id)
      if (stateOffers[0]) {
        setOffers(stateOffers, stateOffers[0].id)
        setOffersKeys(selectedRowKeys, stateOffers[0].id)
      }
      setRowKeys(selectedRowKeys)
    }, [])

  // const onSelectChange = (selectedRowKeys, item) => {
  //   if (userType === 1) {
  //     setOffers(item, data[0].id)
  //     setOffersKeys(selectedRowKeys, data[0].id)
  //     setRowKeys(selectedRowKeys)
  //   } else {
  //     setRowKeys(selectedRowKeys)
  //   }
  // }

  // const handleDeleteOffers = async () => {
  //   await deleteOffers(selectedRowKeys)
  //   setRowKeys([])
  // }

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange
  // }

  const columns = [
    {
      title: i18n.t('name'),
      // title: () => (
      //   <>
      //     <span>{i18n.t("Select all")}</span>
      //     {!userType && (
      //       <button
      //         className="table-btn"
      //         type="primary"
      //         onClick={handleDeleteOffers}
      //       >
      //         <span>{i18n.t("Delete")} </span>
      //       </button>
      //     )}
      //   </>
      // ),
      dataIndex: 'name',
      render: (item) => item || '-',
      width: 356
    },
    {
      dataIndex: 'price',
      render: (item) => moneyField.format(item) || '',
      width: 230
    },
    {
      dataIndex: 'linkType',
      render: (item) => i18n.t(item) || '-',
      width: 190
    },
    {
      dataIndex: 'publicationType',
      render: (item) => i18n.t(item) || '-'
    },
    {
      title: '',
      dataIndex: '',
      width: 1
    }
  ]

  return (
    <>
      <Table
        className='offers-table offers-table-background'
        rowSelection={null}
        showHeader={selectedRowKeys.length >= 1}
        columns={columns}
        dataSource={data}
        expandIcon={CustomExpandIcon}
        expandIconAsCell={false}
        expandIconColumnIndex={4}
        pagination={{
          itemRender: paginationRender
        }}
        expandedRowRender={(item) =>
          !userType ? (
            <div className='offer-detail'>
              <span className='label'>{item.description}</span>
              <div className='my-3 border-bottom' />
              <span className='label float-left'>
                <span className='mr-2' style={{ fontSize: '18px' }}>
                  {i18n.t('Prohibited items')}
                </span>
                {item.prohibitedArticles}
              </span>
              <div className='float-right'>
                <div className='actions'>
                  <Link
                    to={`/properties/${match.params.id}/edit-offer/${item.id}/`}
                  >
                    <button>
                      <i className='fas fa-edit' />
                    </button>
                  </Link>
                  <button
                    onClick={() => deleteOffers([item.id])}
                    className='delete  delete-offer-button'
                  >
                    <i className='far fa-trash-alt' />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className='offer-detail'>
              <span className='label'>{i18n.t('Description')}</span>
              <br />
              {item.description}
              <br />
              {
                item.language && (
                  <div>
                    <span className='label'>{i18n.t('Language')}: </span>
                    {item.language}
                    <br />
                  </div>
                )
              }
              <span className='label'>{i18n.t('Non-Allowed Topics')}: </span>
              {item.prohibitedArticles}
            </div>
          )}
        rowKey='id'
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders.offers
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setOffers }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OffersTable))
