import React from 'react'

import './styles.css'
import classnames from "classnames";

const Button = ({className, type = 'button', onClick, text, extraClass}) => (
  <button
    className={classnames('custom-button', className, {extraClass})}
    type={type}
    onClick={onClick}
  >
    {text}
  </button>
)

export default Button