import Yup from 'yup'
import i18n from 'i18n'

export const CreateRequestSchema = Yup.object().shape({
  name: Yup.string().max(49).required(i18n.t('Is required!')),
  objective: Yup.object().required(),
  targetUrl: Yup.string().url().required(i18n.t('Is required!')),
  description: Yup.string().required(i18n.t('Is required!')),

  articleWriting: Yup.number().required(i18n.t('Is required!')),

  articleLanguage: Yup.object().nullable().when('articleWriting', {
    is: 1,
    then: Yup.object().required('Is required!')
  }),
  articleQuality: Yup.object().nullable().when('articleWriting', {
    is: 1,
    then: Yup.object().required('Is required!')
  }),
  articleLength: Yup.number().nullable().when('articleWriting', {
    is: 1,
    then: Yup.number().required('Is required!')
  }),
})