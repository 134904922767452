import React from 'react'

import { Input, Select } from 'antd'
import DefaultSelect from 'components/Forms/DefaultSelect'
import DefaultButton from 'components/Forms/DefaultButton'
import i18n from 'i18n'
import { BUTTON_COLOR } from 'constants/colors'

const Option = Select.Option

const Filters = ({
  handleChangeValue,
  onFilterButtonClick,
  filters
}) => (
  <>
    <div className='Filters row'>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Name')}
          onBlur={event => handleChangeValue('name', event.target.value)}
          defaultValue={filters.name}
        />
      </div>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Surname')}
          onBlur={event => handleChangeValue('surname', event.target.value)}
          defaultValue={filters.surname}
        />
      </div>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Email')}
          onBlur={event => handleChangeValue('email', event.target.value)}
          defaultValue={filters.email}
        />
      </div>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t('Status')}
          onChange={event => handleChangeValue('isConfirmed', event)}
          defaultValue={filters.isConfirmed}
        >
          <Option value='' key=''>
            <p />
          </Option>
          <Option value={1} key='1'>
            {i18n.t('Active')}
          </Option>
          <Option value={0} key='0'>
            {i18n.t('Inactive')}
          </Option>
        </DefaultSelect>
      </div>
    </div>

    <div className='Filters row mb-4'>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t('userType')}
          onChange={event => handleChangeValue('status', event)}
          defaultValue={filters.status}
        >
          <Option value='' key=''>
            <p />
          </Option>
          <Option value='1' key='1'>
            {i18n.t('Client')}
          </Option>
          <Option value='2' key='2'>
            {i18n.t('Advertiser')}
          </Option>
          <Option value='3' key='3'>
            {i18n.t('Publisher')}
          </Option>
        </DefaultSelect>
      </div>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t('advertiserType')}
          onChange={event => handleChangeValue('advertiserType', event)}
          defaultValue={filters.advertiserType}
        >
          <Option value='' key=''>
            <p />
          </Option>
          <Option value='1' key='1'>
            {i18n.t('Freelance')}
          </Option>
          <Option value='2' key='2'>
            {i18n.t('Company')}
          </Option>
        </DefaultSelect>
      </div>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t('Self Service')}
          onChange={event => handleChangeValue('selfService', event)}
          defaultValue={filters.selfService}
        >
          <Option value='' key=''>
            {i18n.t('tutti')}
          </Option>
          <Option value={1} key='1'>
            {i18n.t('si')}
          </Option>
          <Option value={0} key='0'>
            {i18n.t('no')}
          </Option>
        </DefaultSelect>
      </div>
      <div className='col-3'>
        <DefaultButton
          type='submit'
          style={{ background: BUTTON_COLOR, width: '100%' }}
          onClick={onFilterButtonClick}
        >
          {i18n.t('Filter')}
        </DefaultButton>
      </div>
    </div>
  </>
)

export default Filters
