import React, {useState} from 'react'
import './styles.css'
import SelectArrowUp from 'assets/SelectArrowUp.svg'
import SelectArrowDown from 'assets/SelectArrowDown.svg'
import classnames from "classnames";

const Select = ({options, placeholder, label, containerClassName, value, onChange, error, touched}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = newValue => () => {
    onChange && onChange(newValue)
    setIsOpen(false);
  };
  return (
    <div className={containerClassName}>
      {label && <p className='select-label'>{label}</p>}
      <div className='select-wrapper position-relative'>
        <div
          className={classnames("select-header d-flex justify-content-between", {'error': error && touched})}
          onClick={toggling}
        >
          <span>{(value && value.label) || placeholder}</span>
          <img src={isOpen ? SelectArrowDown : SelectArrowUp} alt="arrow"/>
        </div>
        {
          isOpen && (
            // bc of position absolute
            <ul className={classnames("select-options-container", containerClassName)}>
              {options.map(option => (
                <div className='select-option' onClick={onOptionClicked(option)} key={option.value}>
                  {option.label}
                </div>
              ))}
            </ul>
          )
        }
      </div>
      { error && touched && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  )
}

export default Select