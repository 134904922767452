import React, { useState } from 'react'
import { graphql } from 'react-apollo'

import CreateOfferForm from 'components/Forms/CreateOfferForm'
import Close from 'components/Icons/Close'
import swal from 'sweetalert'
import { createOffer } from 'mutations'
import { getProperty } from 'queries/queries'
import { withAuth } from 'hocs/PrivateRoute'
import { onlyPublisher } from 'hocs/componentByRole'
import i18n from 'i18n'
import * as path from 'constants/routes'

const CreateOffer = props => {
  const [state] = useState({ error: '' })
  const handleCreateOffer = (values, { setErrors }) =>
    new Promise((resolve, reject) => {
      const {
        name,
        description,
        price,
        link,
        linkType,
        publicationType,
        prohibitedArticles,
        isTemporary,
        expiresAt,
        articleWriting,
        timeForWriting,
        language,
        articleInHome,
        sponsoredTag,
      } = values
      props
        .createOffer({
          variables: {
            name: name.value || name,
            description: description,
            price: price,
            link: link,
            linkType: linkType.value || linkType,
            publicationType: publicationType.value || publicationType,
            property: props.match.params.id,
            prohibitedArticles: prohibitedArticles,
            isTemporary: isTemporary === 'true',
            articleWriting: articleWriting === 'true',
            timeForWriting: articleWriting === 'true' ? parseInt(timeForWriting.value) : null,
            language: articleWriting === 'true' ? language.value : null,
            expiresAt: isTemporary === 'true' ? expiresAt : null,
            articleInHome: articleInHome === 'true',
            sponsoredTag: sponsoredTag === 'true',
          },
          refetchQueries: [
            {
              query: getProperty,
              variables: { id: props.match.params.id }
            }
          ]
        })
        .then(response => {
          if (response.data.createOffer.success) {
            let cheaper = response.data.createOffer.cheaperOffers
            if (cheaper > 0) {
              swal({
                title: i18n.t('Please reduce price to appear on Self Service'),
                text: cheaper > 1 ?
                  i18n.t('There are cheaper offers', { number: cheaper }) : 
                  i18n.t('There is a cheaper offer'),
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
              })
            }
            props.history.push(
              path.SAVED_ACTION_OFFER, 
              props.match.params.id
            )
            resolve()
          } else {
            const errors = {}
            response.data.createOffer.error.validationErrors.map(
              error => (errors[error.field] = error.messages.join(' '))
            )
            setErrors(errors)
            reject()
          }
        })
    })
  return (
    <div 
      className='row justify-content-md-center p-5 m-0' 
      data-component='Dashboard/Publisher/CreateOffer'>
      <div className='col-8'>
        <CreateOfferForm
          handleCreateOffer={handleCreateOffer}
          error={state.error}
          errors={props}
        />
        <Close onClick={() => props.history.push(path.PROPERTIES)} />
      </div>
    </div>
  )
}

export default graphql(createOffer, { name: 'createOffer' })(
  withAuth(onlyPublisher(CreateOffer))
)
