import React from 'react'
import { graphql } from 'react-apollo'

import { User } from '../queries/queries'

export const userInfo = WrappedComponent => {
  const UserComponent = ({ user }) => {
    if (user.loading) {
      return null
    }
    return <WrappedComponent {...user} />
  }
  return graphql(User, { name: 'user' })(UserComponent)
}
