import React from 'react'
import { Formik, Form } from 'formik'

import { ResetPasswordSchema } from './validation'
import DefaultField from 'components/Forms/DefaultField'
import DefaultButton from 'components/Forms/DefaultButton'
import i18n from 'i18n'

export const ResetPasswordForm = ({ uid, confirmToken, resetPassword }) => (
  <Formik
    initialValues={{
      newPassword1: '',
      newPassword2: '',
      userId: uid,
      confirmToken: confirmToken
    }}
    validationSchema={ResetPasswordSchema}
    onSubmit={resetPassword}
  >
    {() => (
      <div className='wrapp-form'>
        <Form>
          <DefaultField
            name='newPassword1'
            type='password'
            label='Password:'
            placeholder={i18n.t('Enter Password')}
          />
          <DefaultField
            name='newPassword2'
            type='password'
            label={i18n.t('Password confirmation')}
            placeholder={i18n.t('Repeat the password')}
          />
          <DefaultButton type='submit'>{i18n.t('Submit')}</DefaultButton>
        </Form>
      </div>
    )}
  </Formik>
)
