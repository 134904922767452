import DefaultButton from "components/Forms/DefaultButton";
import ReactSelectField from "components/Forms/ReactSelectField";
import { BUTTON_COLOR } from "constants/colors";
import { Field, Form, Formik } from "formik";
import i18n from "i18n";
import React from "react";
import { moneyField } from "utils";

import DefaultField from "../DefaultField";
import RadioButton from "../RadioButton";
import { OrderMarginSchema } from "./validation";

const marginOptions = [
  { label: i18n.t("Overall Marginality"), value: 1 },
  // { label: i18n.t('Personalized Marginality'), value: 2 }
];

const marginTypeOptions = [
  { label: i18n.t("Percentage Value"), value: 1 },
  { label: i18n.t("Numerical Value"), value: 2 },
];

const selectInitialValue = (options, value) => {
  let initial = options.filter((x) => {
    return x.value === value;
  });
  if (!initial) {
    initial = options;
  }
  console.log(initial, options, value);
  return initial[0];
};

const OrderMarginForm = (props) => (
  <Formik
    initialValues={{
      margin: selectInitialValue(marginOptions, props.marginId),
      marginType: selectInitialValue(marginTypeOptions, props.marginTypeId),
      marginValue: props.marginValue || "",
    }}
    validationSchema={OrderMarginSchema}
    onSubmit={props.onSubmit}
  >
    {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
      <Form className="mt-5">
        <div className="col-6 m-auto justify-content-center">
          <h1 className="pb-3">{i18n.t("Construction of the Job Order")}</h1>

          <>
            <p className="pt-2 pb-2">
              {i18n.t(
                "Select the margin mode you want to apply to the realized order."
              )}
            </p>
            <div>
              <ReactSelectField
                name="margin"
                fieldType="margin"
                options={marginOptions}
                value={values.margin}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.margin}
                touched={touched.margin}
                clearable={false}
                disabled
                placeholder={i18n.t("Overall Marginality")}
              />
            </div>
            <p className="pt-2 pb-2">{i18n.t("Select the type of margin")}</p>
            <div className="row">
              <div className="col-6">
                <ReactSelectField
                  name="marginType"
                  fieldType="marginType"
                  options={marginTypeOptions}
                  value={values.marginType}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={errors.marginType}
                  touched={touched.marginType}
                  placeholder={i18n.t("Percentage Value")}
                />
              </div>
              {(values.margin && values.margin.value !== 2) ||
              !values.margin ? (
                <div className="col-6">
                  <DefaultField
                    name="marginValue"
                    type="text"
                    label={false}
                    errors={errors.marginValue}
                    touched={touched.marginValue}
                    placeholder={i18n.t("Enter Value")}
                  />
                </div>
              ) : (
                <div />
              )}
            </div>
          </>
          <span>
            {i18n.t("Additional article margin is")}:&nbsp;
            {moneyField.format(props.additionalArticleMargin)}
          </span>
          <div className="text-center">
            <DefaultButton
              type="submit"
              style={{
                backgroundColor: BUTTON_COLOR,
                marginTop: "60px",
                width: "445.04px",
              }}
            >
              {i18n.t("Enter Margin")}
            </DefaultButton>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default OrderMarginForm;
