import './styles.css'

import * as path from 'constants/routes'
import { userInfo } from 'hocs/userInfo'
import i18n from 'i18n'
import {
  Collapse,
  Container,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarNav,
  MDBNavbarToggler,
  Navbar,
  NavbarNav
} from 'mdbreact'
import React, { useCallback, useState } from 'react'
import { withRouter } from 'react-router-dom'

import Profile from './Profile'

export default userInfo(
  withRouter(props => {
    const [collapse, handleClick] = useState('')

    const toggleCollapse = useCallback(collapseID => () =>
      handleClick((!collapse && collapseID) || '')
    )

    const handleLogout = () => {
      window.dataLayer.push({
        loginStatus: 'logged',
        language: 'it',
        userID: props.me.id,
        hashedEmail: props.me.email,
        commercialRole: props.me.userTypologyDisplay,
        event: 'wpcf7successfulsubmit',
        eventCategory: 'account action',
        eventAction: 'logout'
      })
      window.localStorage.clear()
      window.location.href = '/login'
    }

    return (
      <>
        <Navbar className='flexible-navbar' dark expand='md'>
          <Container>
            <MDBNavbarToggler onClick={toggleCollapse('navbarCollapse13')} />
            <MDBCollapse id='navbarCollapse13' isOpen={collapse} navbar>
              <MDBNavbarNav left>
                <MDBNavItem>
                  <MDBNavLink to={path.DASHBOARD}>Dashboard</MDBNavLink>
                </MDBNavItem>
                {props.me.userTypology === 3 && (
                  <>
                    <MDBNavItem>
                      <MDBNavLink to={path.PROPERTIES}>
                        {i18n.t('Properties')}
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to={path.PROPOSALS}>
                        {i18n.t('Proposals')}
                      </MDBNavLink>
                    </MDBNavItem>
                  </>
                )}
                {props.me.userTypology === 2 && (
                  <MDBNavItem>
                    <MDBNavLink to={path.REQUESTS}>
                      {i18n.t('Requests')}
                    </MDBNavLink>
                  </MDBNavItem>
                )}
                {props.me.userTypology === 1 && (
                  <>
                    <MDBNavItem>
                      <MDBNavLink to={path.ORDERS}>
                        {i18n.t('Orders')}
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to={path.INVOICES}>
                        {i18n.t('Invoices')}
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to={path.SHOWPROPERTIES}>
                        {i18n.t('Properties')}
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to={path.SHOWUSERS}>
                        {i18n.t('Users')}
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to={path.SHOWCOUPONS}>
                        {i18n.t('Coupons')}
                      </MDBNavLink>
                    </MDBNavItem>
                  </>
                )}
              </MDBNavbarNav>
            </MDBCollapse>
            <Collapse navbar>
              <NavbarNav right>
                <Profile user={props.me} handleLogout={handleLogout} />
              </NavbarNav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    )
  })
)
