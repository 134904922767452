import React from 'react'
import Select from 'react-select'
import i18n from 'i18n'

import 'react-select/dist/react-select.css'

const ReactSelectField = React.memo(
  ({
    onBlur,
    onChange,
    error,
    fieldType,
    name,
    options,
    placeholder,
    multi,
    disabled,
    touched,
    clearable,
    value
  }) => {
    const handleChange = (fieldType, value) => {
      onChange(fieldType, value)
    }

    const handleBlur = fieldType => {
      onBlur(fieldType, true)
    }

    return (
      <div className='mb-3'>
        <Select
          id={fieldType}
          options={options}
          multi={multi}
          onChange={value => handleChange(fieldType, value)}
          onBlur={() => handleBlur(fieldType)}
          value={value}
          className={
            !!error && touched ? 'border border-danger' : 'field-select'
          }
          clearable={clearable}
          disabled={disabled}
          placeholder={placeholder}
        />
        {!!error && touched && (
          <div className='invalid-feedback d-block'>
            {name} {i18n.t('is required!')}
          </div>
        )}
      </div>
    )
  }
)

export default ReactSelectField
