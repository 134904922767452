import gql from 'graphql-tag'

export const register = gql`
  mutation register(
    $name: String!
    $surname: String!
    $email: String!
    $password1: String!
    $password2: String!
    $userTypology: String!
    $advertiserType: Int
    $companyName: String
    $lang: String!
  ) {
    register(
      name: $name
      surname: $surname
      email: $email
      password1: $password1
      password2: $password2
      userTypology: $userTypology
      advertiserType: $advertiserType
      companyName: $companyName
      lang: $lang
    ) {
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      success
      token
      user {
        id
        name
        surname
        email
        userTypology
        userTypologyDisplay
        advertiserType
        lang
        company {
          name
        }
      }
    }
  }
`

export const login = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      token
      user {
        id
        email
        name
        surname
        lang
        userTypologyDisplay
        userTypology
      }
    }
  }
`

export const resetPassword = gql`
  mutation resetPassword(
    $newPassword1: String!
    $newPassword2: String!
    $confirmToken: String!
    $userId: Int!
  ) {
    resetPassword(
      newPassword1: $newPassword1
      newPassword2: $newPassword2
      confirmToken: $confirmToken
      userId: $userId
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const checkToken = gql`
  mutation checkToken($confirmToken: String!, $userId: Int!) {
    checkToken(confirmToken: $confirmToken, userId: $userId) {
      success
    }
  }
`

export const verifyToken = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`

export const confirmEmail = gql`
  mutation confirmEmail($email: String!, $resendActivation: String) {
    confirmEmail(email: $email, resendActivation: $resendActivation) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const editUser = gql`
  mutation editUser(
    $name: String
    $surname: String
    $email: String!
    $newEmail: String
    $lang: String
    $password1: String
    $password2: String
  ) {
    editUser(
      name: $name
      surname: $surname
      email: $email
      lang: $lang
      newEmail: $newEmail
      password1: $password1
      password2: $password2
    ) {
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      user {
        id
        name
        lang
        surname
        email
      }
      token
    }
  }
`

export const adminEditUser = gql`
  mutation adminUserEditMutation(
    $id: ID!,
    $userData: AdminUserInput!
  ) {
    adminEditUser(
      userId: $id,
      userData: $userData
    ) {
      userId,
      success,
      errors
    }
  }
`

export const createProperty = gql`
  mutation createProperty(
    $name: String!
    $description: String!
    $url: String!
    $monthlyTraffic: Int!
    $articleWriting: String!
    $language: String!
    $country: String!
    $category: ID
    $categories: [ID]!
    $propertyType: String!
    $userId: ID!,
    $enableSelfService: Boolean!
  ) {
    createProperty(
      name: $name
      description: $description
      url: $url
      monthlyTraffic: $monthlyTraffic
      articleWriting: $articleWriting
      language: $language
      country: $country
      category: $category
      categories: $categories
      propertyType: $propertyType
      user: $userId,
      enableSelfService: $enableSelfService
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      property {
        id
        name
        description
        url
        monthlyTraffic
        articleWriting
        language
        country
        enableSelfService
        category {
          id
          name
        }
        propertyType
        user {
          id
          name
        }
      }
    }
  }
`

export const updateProperty = gql`
  mutation updateProperty(
    $propertyId: Int!
    $name: String!
    $description: String!
    $url: String!
    $monthlyTraffic: Int!
    $articleWriting: String!
    $language: String!
    $country: String!
    $category: ID
    $categories: [ID]
    $propertyType: String!
    $userId: ID!
    $enableSelfService: Boolean!
  ) {
    updateProperty(
      propertyId: $propertyId
      name: $name
      description: $description
      url: $url
      monthlyTraffic: $monthlyTraffic
      articleWriting: $articleWriting
      language: $language
      country: $country
      category: $category
      categories: $categories
      propertyType: $propertyType
      user: $userId
      enableSelfService: $enableSelfService
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      property {
        id
        name
        description
        url
        monthlyTraffic
        articleWriting
        language
        country
        enableSelfService
        category {
          id
          name
        }
        propertyType
        user {
          id
          name
        }
      }
    }
  }
`

export const deleteProperties = gql`
  mutation deleteProperties($properties: [Int]) {
    deleteProperties(properties: $properties) {
      success
      errors
    }
  }
`

export const createOffer = gql`
  mutation createOffer(
    $offerId: Int
    $name: String!
    $description: String!
    $price: Float!
    $linkType: String!
    $publicationType: String!
    $property: ID!
    $prohibitedArticles: String!
    $isTemporary: Boolean
    $expiresAt: Date
    $articleWriting: Boolean
    $timeForWriting: Int
    $language: String
    $articleInHome: Boolean
    $sponsoredTag: Boolean
  ) {
    createOffer(
      offerId: $offerId
      name: $name
      description: $description
      price: $price
      linkType: $linkType
      publicationType: $publicationType
      property: $property
      prohibitedArticles: $prohibitedArticles
      isTemporary: $isTemporary
      expiresAt: $expiresAt
      articleWriting: $articleWriting
      timeForWriting: $timeForWriting
      language: $language   
      articleInHome: $articleInHome
      sponsoredTag: $sponsoredTag
    ) {
      success
      cheaperOffers
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const editOffer = gql`
  mutation updateOffer(
    $offerId: Int!
    $name: String!
    $description: String!
    $price: Float!
    $linkType: String!
    $publicationType: String!
    $prohibitedArticles: String!
    $property: ID!
    $isTemporary: Boolean
    $expiresAt: Date    
    $articleWriting: Boolean
    $timeForWriting: Int
    $language: String
  ) {
    updateOffer(
      offerId: $offerId
      name: $name
      description: $description
      price: $price
      linkType: $linkType
      publicationType: $publicationType
      prohibitedArticles: $prohibitedArticles
      property: $property
      isTemporary: $isTemporary
      expiresAt: $expiresAt      
      articleWriting: $articleWriting
      timeForWriting: $timeForWriting
      language: $language
    ) {
      success
      cheaperOffers      
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const deleteOffer = gql`
  mutation deleteOffer($offerId: Int!) {
    deleteOffer(offerId: $offerId) {
      success
      errors
    }
  }
`

export const deleteOffers = gql`
  mutation deleteOffers($offers: [Int], $proposalId: Int, $ref: Boolean) {
    deleteOffers(offers: $offers, proposalId: $proposalId, ref: $ref) {
      success
      proposalDeleted
      errors
    }
  }
`

export const createRequest = gql`
  mutation createRequest(
    $name: String!
    $objective: String!,
    $topic: String!,
    $targetUrl: String!,
    $months: Int,
    $velocity: String!,
    $linkNumber: Int
    $rangeBudget: String!
    $language: String!
    $deadline: DateTime
    $description: String!
    $domainAuthority: Int
    $articleWriting: String!
    $itemQuality: String!
    $isApproval: String!
    $user: ID!
  ) {
    createRequest(
      name: $name,
      objective: $objective,
      topic: $topic,
      targetUrl: $targetUrl,
      months: $months,
      velocity: $velocity,
      linkNumber: $linkNumber,
      rangeBudget: $rangeBudget,
      language: $language,
      deadline: $deadline,
      description: $description,
      domainAuthority: $domainAuthority,
      articleWriting: $articleWriting,
      itemQuality: $itemQuality,
      isApproval: $isApproval,
      user: $user,
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      request {
        id
        name
        linkNumber
        rangeBudget
        language
        deadline
        description
        domainAuthority
        domainRating
        organicTraffic
        articleWriting
        averageNumberWords
        itemQuality
        isApproval
        user {
          id
          name
        }
      }
    }
  }
`

export const adminCreateRequest = gql`
  mutation adminCreateRequest(
    $name: String!
    $objective: String!,
    $topic: String!,
    $targetUrl: String!,
    $months: Int,
    $velocity: String!,
    $linkNumber: Int
    $rangeBudget: String!
    $language: String!
    $deadline: DateTime
    $description: String!
    $articleWriting: String!
    $itemQuality: String!
    $isApproval: String!
    $user: ID!
  ) {
    adminCreateRequest(
      name: $name,
      objective: $objective,
      topic: $topic,
      targetUrl: $targetUrl,
      months: $months,
      velocity: $velocity,
      linkNumber: $linkNumber,
      rangeBudget: $rangeBudget,
      language: $language,
      deadline: $deadline,
      description: $description,
      articleWriting: $articleWriting,
      itemQuality: $itemQuality,
      isApproval: $isApproval,
      user: $user,
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      request {
        id
        name
        linkNumber
        rangeBudget
        language
        deadline
        description
        domainAuthority
        domainRating
        organicTraffic
        articleWriting
        averageNumberWords
        itemQuality
        isApproval
        user {
          id
          name
        }
        joborder {
          id
        }
      }
    }
  }
`


export const adminCreateCoupon = gql`
  mutation adminCreateCoupon(
    $name: String!
    $code: String!
    $destination: Int!
    $kind: Int!
    $limitedNumber: Int
    $minimumExpense: Int
    $expireAt: DateTime
    $categories: String
    $enabled: Boolean
    $discountValue: Int!
    $discountKind: Int!
  ) {
    adminCreateCoupon(
      name: $name
      code: $code
      destination: $destination
      kind: $kind
      limitedNumber: $limitedNumber
      minimumExpense: $minimumExpense
      expireAt: $expireAt
      categories: $categories
      enabled: $enabled
      discountValue: $discountValue
      discountKind: $discountKind
      ) {
        success
        error {
          __typename
          ... on ValidationErrors {
            validationErrors {
              field
              messages
            }
          }
        }
        coupon {
          id
          name
          code
          destination
          kind
          limitedNumber
          minimumExpense
          expireAt
          categories
          enabled
          discountValue
          discountKind
        } 
      }
    }
`

export const toggleCouponEnabled = gql`
  mutation toggleCouponEnabled(
    $couponId: Int!
  ) {
    toggleCouponEnabled(
      couponId: $couponId
    ) {
      success
      errors
      enabled
    }
  }
`


export const createSelfServiceRequest = gql`
  mutation createSelfServiceRequest(
    $requestData: RequestInput!,
    $selectedOffers: [Int]!
  ) {
    createSelfServiceRequest(
      requestData: $requestData,
      selectedOffers: $selectedOffers
    ) {
      requestId,
      success,
      errors
    }
  }
`

export const updateSelfServiceRequest = gql`
  mutation updateSelfServiceRequest(
    $id: ID!,
    $requestData: RequestInput!,
    $selectedOffers: [Int]!
  ) {
    updateSelfServiceRequest(
      requestId: $id,
      requestData: $requestData,
      selectedOffers: $selectedOffers
    ) {
      requestId,
      success,
      errors
    }
  }
`

export const deleteRequest = gql`
  mutation deleteRequest($requestId: Int) {
    deleteRequest(requestId: $requestId) {
      success
      errors
    }
  }
`

export const editBillingData = gql`
  mutation editBillingData(
    $userId: Int!
    $companyName: String
    $vat: String!
    $billingAddress: String!
    $cap: String!
    $city: String!
    $province: String!
    $state: String!
    $pec: String
    $useElectronicInvoice: Boolean!
    $electronicInvoiceCode: String
  ) {
    editBillingData(
      userId: $userId
      companyName: $companyName
      vat: $vat
      billingAddress: $billingAddress
      cap: $cap
      city: $city
      province: $province
      state: $state
      pec: $pec
      useElectronicInvoice: $useElectronicInvoice
      electronicInvoiceCode: $electronicInvoiceCode
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const deleteOrders = gql`
  mutation deleteOrders($orders: [Int]) {
    deleteOrders(orders: $orders) {
      success
      errors
    }
  }
`

export const setMargin = gql`
  mutation setMargin(
    $orderId: Int!
    $margin: String!
    $marginType: String!
    $marginValue: Int
  ) {
    setMargin(
      orderId: $orderId
      margin: $margin
      marginType: $marginType
      marginValue: $marginValue
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const setOwner = gql`
  mutation setOwner(
    $orderId: Int!
    $owner: ID
  ) {
    setOwner(
      orderId: $orderId
      owner: $owner
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const toggleOnHold = gql`
  mutation toggleOnHold(
    $orderId: Int!
  ) {
    toggleOnHold(
      orderId: $orderId
    ) {
      success
      errors
      onHold
    }
  }
`

export const setPersonalizedMargin = gql`
  mutation setPersonalizedMargin($extendedOfferId: Int!, $marginValue: Int) {
    setPersonalizedMargin(
      extendedOfferId: $extendedOfferId
      marginValue: $marginValue
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const setJobOrderOffers = gql`
  mutation setJobOrderOffers(
    $orderId: Int!
    $offers: [Int!]
    $delete: Boolean
  ) {
    setJobOrderOffers(orderId: $orderId, offers: $offers, delete: $delete) {
      success
      errors
    }
  }
`

export const duplicateJobOrderOffers = gql`
  mutation duplicateJobOrderOffers(
    $orderId: Int!
    $offers: [Int!]
  ) {
    duplicateJobOrderOffers(orderId: $orderId, offers: $offers) {
      success
      errors
    }
  }
`

export const selfServiceDuplicateOffer = gql`
  mutation duplicateJobOrderOffers(
    $offerId: Int!
  ) {
    selfServiceDuplicateOffer(offerId: $offerId) {
      success
      errors
    }
  }
`

export const substituteJobOrderOffers = gql`
  mutation substituteJobOrderOffers(
    $orderId: Int!
    $replacedOffer: Int!
    $suggestedOffer: Int!
  ) {
    substituteJobOrderOffers(
      orderId: $orderId
      replacedOffer: $replacedOffer
      suggestedOffer: $suggestedOffer
    ) {
      success
      errors
    }
  }
`

export const changeOrderStatus = gql`
  mutation changeOrderStatus($orderId: Int, $status: Int) {
    changeOrderStatus(orderId: $orderId, status: $status) {
      success
      errors
    }
  }
`

export const deleteNotification = gql`
  mutation deleteNotification($notifications: [Int]) {
    deleteNotification(notifications: $notifications) {
      errors
      success
    }
  }
`

export const changeNotificationStatus = gql`
  mutation changeNotificationStatus($notifications: [Int]) {
    changeNotificationStatus(notifications: $notifications) {
      errors
      success
    }
  }
`

export const setBaseOrderInfo = gql`
  mutation setBaseOrderInfo(
    $orderId: Int!
    $proposalName: String!
    $proposalDescription: String!
  ) {
    setBaseOrderInfo(
      orderId: $orderId
      proposalName: $proposalName
      proposalDescription: $proposalDescription
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const approveProposalOffers = gql`
  mutation approveProposalOffers($offers: [Int], $proposalId: Int!) {
    approveProposalOffers(offers: $offers, proposalId: $proposalId) {
      errors
      success
      proposalApproved
    }
  }
`

export const createArticle = gql`
  mutation createArticle(
    $wordNumber: Int!
    $subject: String
    $publicationType: String
    $follow: String
    $anchorText: String
    $targetUrl: String
    $extendedOffers: [ID]!
    $deadline: DateTime!
  ) {
    createArticle(
      wordNumber: $wordNumber
      subject: $subject
      publicationType: $publicationType
      follow: $follow
      anchorText: $anchorText
      targetUrl: $targetUrl
      extendedOffers: $extendedOffers
      deadline: $deadline
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const advertiserOffersRefusal = gql`
  mutation advertiserOffersRefusal($offers: [Int], $reason: String!) {
    advertiserOffersRefusal(offers: $offers, reason: $reason) {
      errors
      success
    }
  }
`

export const advertiserOffersApprove = gql`
  mutation advertiserOffersApprove($offers: [Int]) {
    advertiserOffersApprove(offers: $offers) {
      errors
      success
    }
  }
`

export const articleRefusal = gql`
  mutation articleRefusal(
    $isAdvertiser: Boolean!
    $offerId: Int!
    $reason: String!
  ) {
    articleRefusal(
      offerId: $offerId
      reason: $reason
      isAdvertiser: $isAdvertiser
    ) {
      errors
      success
    }
  }
`

export const urlRefusal = gql`
  mutation urlRefusal(
    $isAdvertiser: Boolean!
    $offerId: Int!
    $reason: String!
  ) {
    urlRefusal(
      offerId: $offerId
      reason: $reason
      isAdvertiser: $isAdvertiser
    ) {
      errors
      success
    }
  }
`

export const createCharge = gql`
  mutation createCharge(
    $stripeToken: String!
    $cardToken: String!
    $email: String!
    $requestId: Int!
    $ev: String!
  ) {
    createCharge(
      stripeToken: $stripeToken
      cardToken: $cardToken
      email: $email
      requestId: $requestId
      ev: $ev
    ) {
      errors
      success
    }
  }
`

export const addCoupon = gql`
  mutation addCoupon(
    $couponCode: String!
    $jobOrderId: Int!
  ) {
    addCoupon(
      couponCode: $couponCode
      jobOrderId: $jobOrderId
    ) {
      error
      success
    }
  }
`

export const createIntent = gql`
  mutation createIntent(
    $stripeToken: String!
    $email: String!
    $requestId: Int!
  ) {
    createIntent(
      stripeToken: $stripeToken
      email: $email
      requestId: $requestId
    ) {
      errors
      success
      intent
    }
  }
`

export const uploadOrApproveFile = gql`
  mutation uploadOrApproveFile(
    $approval: Boolean
    $file: String
    $fileName: String
    $offerId: Int!
    $isAdvertiser: Boolean
    $isPublisher: Boolean
  ) {
    uploadOrApproveFile(
      approval: $approval
      file: $file
      fileName: $fileName
      offerId: $offerId
      isAdvertiser: $isAdvertiser
      isPublisher: $isPublisher
    ) {
      errors
    }
  }
`

export const uploadOrConcludeFile = gql`
  mutation uploadOrConcludeFile(
    $file: String
    $fileName: String
    $proposalId: Int!
    $jobOrderId: Int!
    $extendedOfferId: Int!
  ) {
    uploadOrConcludeFile(
      file: $file
      fileName: $fileName
      proposalId: $proposalId
      jobOrderId: $jobOrderId
      extendedOfferId: $extendedOfferId
    ) {
      errors
    }
  }
`

export const uploadInvoice = gql`
  mutation uploadInvoice($file: String, $fileName: String, $jobOrderId: Int!) {
    uploadInvoice(file: $file, fileName: $fileName, jobOrderId: $jobOrderId) {
      errors
    }
  }
`

export const removePublisherInvoice = gql`
  mutation removePublisherInvoice($proposalId: Int!) {
    removePublisherInvoice(proposalId: $proposalId) {
      errors
    }
  }
`

export const uploadCover = gql`
  mutation uploadCover(
    $file: String
    $fileName: String
    $extendedOfferId: Int!
  ) {
    uploadCover(
      file: $file
      fileName: $fileName
      extendedOfferId: $extendedOfferId
    ) {
      errors
    }
  }
`

export const urlUploadOrApprove = gql`
  mutation urlUploadOrApprove(
    $urlToUpload: String
    $offerId: Int!
    $urlApproval: Boolean
  ) {
    urlUploadOrApprove(
      urlToUpload: $urlToUpload
      offerId: $offerId
      urlApproval: $urlApproval
    ) {
      errors
    }
  }
`

export const confirmationPaymentStatus = gql`
  mutation confirmationPaymentStatus($proposalId: Int!) {
    confirmationPaymentStatus(proposalId: $proposalId) {
      errors
    }
  }
`

export const proposalInvoicedAt = gql`
  mutation proposalInvoicedAt($proposalId: Int!, $invoicedAt: String!) {
    proposalInvoicedAt(proposalId: $proposalId, invoicedAt: $invoicedAt) {
      errors
    }
  }
`

export const uploadBankTransfer = gql`
  mutation uploadBankTransfer(
    $file: String
    $fileName: String
    $requestId: Int!
  ) {
    uploadBankTransfer(
      file: $file
      fileName: $fileName
      requestId: $requestId
    ) {
      errors
    }
  }
`

export const confirmBankTransfer = gql`
  mutation confirmBankTransfer($requestId: Int!) {
    confirmBankTransfer(requestId: $requestId) {
      errors
    }
  }
`
export const createMessage = gql`
  mutation createMessage($text: String!, $room: ID!, $file: String) {
    createMessage(text: $text, room: $room, file: $file) {
      message {
        id
        text
        file
        seen
        sender {
          id
          name          
        }
        time
      }
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const readMessage = gql`
  mutation readMessage($messageId: Int!) {
    readMessage(messageId: $messageId) {
      errors
    }
  }
`

export const createRoom = gql`
  mutation createRoom($users: [ID]!, $jobOrder: ID!) {
    createRoom(users: $users, jobOrder: $jobOrder) {
      room {
        id
        jobOrder {
          id
        }
      }
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`

export const addOfferToJobOrder = gql`
  mutation addOfferToJobOrder(
    $orderId: Int!
    $offerId: Int!
  ) {
    addOfferToJobOrder(
      orderId: $orderId
      offerId: $offerId
    ) {
      errors
      success
    }
  }
`

export const generateSso = gql`
  mutation generateSso(
    $userId: Int!
  ) {
    generateSso(
      userId: $userId
    ) {
      errors
      success
      token
    }
  }
`
export const ssoLogin = gql`
  mutation ssoLogin(
    $ssotoken: String!
  ) {
    ssoLogin(
      ssotoken: $ssotoken
    ) {
      errors
      success
      token
      user {
        id
        email
        name
        surname
        lang
        userTypologyDisplay
        userTypology
      }
    }
  }
`

export const doArticleToBePublishedAt = gql`
  mutation articleToBePublishedAt(
    $articleToBePublishedAt: String!
    $offerId: Int!
  ) {
    articleToBePublishedAt(
      articleToBePublishedAt: $articleToBePublishedAt
      offerId: $offerId
    ) {
      errors
      success
    }
  }
`
