import React from 'react'
import i18n from 'i18n'
import { Container } from 'mdbreact'
import ChildNavBar from 'components/NavBar/ChildNavBar'
import ShowUsersComponent from 'components/Dashboard/Client/ShowCoupons'
import { withAuth } from 'hocs/PrivateRoute'
import { onlyClient } from 'hocs/componentByRole'

const showCoupons = props => (
  <>
    <ChildNavBar title={i18n.t('Coupons')} />
    <div className='pt-5'>
      <Container>
        <ShowUsersComponent {...props} />
      </Container>
    </div>
  </>
)

export default withAuth(onlyClient(showCoupons))
