import React from 'react'

import Filters from './Filters'
import OffersTable from './Table'

import i18n from 'i18n'

const OffersList = ({
  data,
  onChangeSearch,
  onChangeSelect,
  searchvalue,
  deleteOffers,
  userType,
  setOffersKeys,
  jobOrder,
  property,
  substituteClickHandle,
  showButton,
  setShowButton
}) => (
  <div className='table-container' data-component='Dashboard/Publisher/Properties/Property/OffersList/index'>
    {!userType && <h2 className='title'>{i18n.t('List of Offers')}</h2>}
    {!userType && (
      <Filters
        searchPlaceholder={i18n.t('Search for offer')}
        onChangeSearch={onChangeSearch}
        onChangeSelect={onChangeSelect}
        searchvalue={searchvalue}
        deleteOffers={deleteOffers}
      />
    )}
    <OffersTable
      data={data}
      setOffersKeys={setOffersKeys}
      deleteOffers={deleteOffers}
      userType={userType || null}
      jobOrder={jobOrder || null}
      property={property || null}
      substituteClickHandle={substituteClickHandle || null}
      showButton={showButton}
      setShowButton={setShowButton}
    />
  </div>
)

export default OffersList
