import React from 'react'
import { generatePath } from 'react-router'

import logo from 'assets/Logo-White.png'
import advertiserIcon from 'assets/Advertiser.png'
import publisherIcon from 'assets/Publisher.png'
import Title from 'components/Access/Title'
import Language from 'components/Access/Language'
import UserType from 'components/UserType'
import Copyright from 'components/Access/Copyright'
import DefaultButton from 'components/Forms/DefaultButton'

import * as path from 'constants/routes'
import i18n, { lang } from 'i18n'

import './style.scss'

const Main = () => {
  window.dataLayer.push({
    loginStatus: 'not logged',
    language: lang,
    contentType: 'Homepage',
    pageType: 'Homepage'
  })

  return (
    <div className='main access-bg'>
      <Title
        descTop={i18n.t('Do you already have an account?')}
        image={logo}
        to={generatePath(path.SIGN_IN, { lng: lang })}
        path={i18n.t('Login')}
        title={i18n.t('Select the type of user')}
      />
      <Language />
      <div className='card-wrapp'>
        <UserType
          image={publisherIcon}
          type='Publisher'
          path={generatePath(path.PUBLISHER_SIGN_UP, { lng: lang })}
        >
          <h4 className='pt-4 pb-4 m-0'>
            {i18n.t('If you own a blog, website or network, and want to start monetize by posting new high - quality sponsored content.')}{' '}
          </h4>
          <DefaultButton>{i18n.t('Create User')}</DefaultButton>
        </UserType>
        
        <UserType
          image={advertiserIcon}
          type='Advertiser'
          href={`${process.env.REACT_APP_ADVERTIZER_URL}/signup`}
        >
          <h4 className='pt-4 pb-4 m-0'>
            {i18n.t('If you want to increase the value and authority of your site due to the publication of new, original and high quality contents on numerous sites.')}{' '}
          </h4>
          <DefaultButton>{i18n.t('Create User')}</DefaultButton>
        </UserType>
      </div>
      <Copyright />
    </div>
  )
}

export default Main
