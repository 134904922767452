import React from 'react'
import { graphql } from 'react-apollo'
// import { generatePath } from 'react-router'
import { Container } from 'reactstrap'
import { Formik, Form } from 'formik'

import Yup from 'yup'
import DefaultField from 'components/Forms/DefaultField'
import DefaultButton from 'components/Forms/DefaultButton'
import Title from 'components/Access/Title'
import Copyright from 'components/Access/Copyright'
import Language from 'components/Access/Language'

import i18n from 'i18n'
import swal from 'sweetalert'
import logo from 'assets/Logo-White.png'
import * as path from 'constants/routes'
import { confirmEmail } from 'mutations'

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('E-mail is not valid!'))
    .required(i18n.t('E-mail is required!'))
})

const ForgotPassword = props => {
  const forgotPassword = (values, { setErrors }) =>
    new Promise((resolve, reject) => {
      const { email } = values
      const resendActivation = props.match.path.includes('forgot')
        ? null
        : true
      props
        .confirmEmail({
          variables: {
            email,
            resendActivation
          }
        })
        .then(response => {
          if (!response.data.confirmEmail.error.validationErrors.length) {
            if (response.data.confirmEmail.success) {
              swal(i18n.t('Confirmation'), i18n.t('Please, check your email!'), 'warning')
            }
            props.history.push(path.SIGN_IN)
            resolve()
          } else {
            const errors = {}
            response.data.confirmEmail.error.validationErrors.map(
              error => (errors[error.field] = error.messages.join(' '))
            )
            setErrors(errors)
            reject()
          }
        })
    })
  return (
    <div className='access-bg full-screen centered-content'>
      <Container>
        <Title
          descTop={
            props.match.path.includes('forgot')
              ? i18n.t('Forgot password?')
              : i18n.t("Didn't receive activation email?")
          }
          image={logo}
          to={path.HOME}
        />
        <Language />
        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={ForgotPasswordSchema}
          onSubmit={forgotPassword}
        >
          {() => (
            <div className='wrapp-form'>
              <Form>
                <DefaultField
                  name='email'
                  type='email'
                  label='Email:'
                  placeholder={i18n.t('username@email')}
                />
                <DefaultButton type='submit'>{i18n.t('Submit')}</DefaultButton>
              </Form>
            </div>
          )}
        </Formik>
        <Copyright />
      </Container>
    </div>
  )
}

export default graphql(confirmEmail, { name: 'confirmEmail' })(ForgotPassword)
