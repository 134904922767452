import { Table } from "antd";
import Filters from "components/Dashboard/Client/Properties/Filters";
import Loader from "components/Loader";
import i18n from "i18n";
import { getPaginatedProperties } from "queries/queries";
import React, { useReducer, useState } from "react";
import { Query } from "react-apollo";
import { connect } from "react-redux";
import { moneyField, paginationRender } from "utils";

import it from "../../../lang/it";
import RespectivePropertyInfo from "./respectivePropertyInfo.js";

const CustomExpandIcon = (props) =>
  props.expanded ? (
    <i
      className="fas fa-minus"
      onClick={(e) => props.onExpand(props.record, e)}
    />
  ) : (
    <i
      className="fas fa-plus"
      style={{ color: "#555555" }}
      onClick={(e) => props.onExpand(props.record, e)}
    />
  );

const initialState = {
  showHeader: false,
  selectedRowKeys: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SHOW_HEADER":
      return {
        ...state,
        showHeader: true,
      };
    case "HIDDEN_HEADER":
      return {
        ...state,
        showHeader: false,
        selectedRowKeys: [],
      };
    case "SELECTED_ROW_KEYS":
      return {
        ...state,
        selectedRowKeys: action.payload,
      };
    default:
      return state;
  }
};

const PropertiesComponent = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { request } = props;

  const initialFilters = {
    search: "",
    priceFrom: undefined,
    priceTo: undefined,
    category: [...request.siteCategories].map((name) => it[name]),
    type: undefined,
    country: undefined,
    language: request.articleLanguage || undefined,
    daFrom: undefined,
    daTo: undefined,
    url: undefined,
  };
  const [formFilters, setFormFilters] = useState({ ...initialFilters });
  const [tmpFormFilters, setTmpFormFilters] = useState({ ...initialFilters });

  const handleChangeValue = (event) => {
    setTmpFormFilters({
      ...tmpFormFilters,
      search: event,
    });
  };

  const handlePriceFromValue = (event) => {
    if (isNaN(event)) {
      event = undefined;
    }
    setTmpFormFilters({
      ...tmpFormFilters,
      priceFrom: event,
    });
  };

  const handlePriceToValue = (event) => {
    if (isNaN(event)) {
      event = undefined;
    }
    setTmpFormFilters({
      ...tmpFormFilters,
      priceTo: event,
    });
  };

  const handleDAFromValue = (event) => {
    if (isNaN(event)) {
      event = undefined;
    }
    setTmpFormFilters({
      ...tmpFormFilters,
      daFrom: event,
    });
  };

  const handleDAToValue = (event) => {
    if (isNaN(event)) {
      event = undefined;
    }
    setTmpFormFilters({
      ...tmpFormFilters,
      daTo: event,
    });
  };

  const handleClickOnSelect = (key, event) => {
    setTmpFormFilters({
      ...tmpFormFilters,
      [key]: event,
    });
  };

  const handleChangeUrl = (event) => {
    setTmpFormFilters({
      ...tmpFormFilters,
      url: event,
    });
  };

  const onFilterButtonClick = () => {
    setFormFilters({ ...tmpFormFilters });
  };

  const onSelectChange = (selectedRowKeys) => {
    dispatch({
      type: "SELECTED_ROW_KEYS",
      payload: selectedRowKeys,
    });
    selectedRowKeys.length
      ? dispatch({
          type: "SHOW_HEADER",
        })
      : dispatch({
          type: "HIDDEN_HEADER",
        });
  };

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    onChange: onSelectChange,
  };

  const [page, setPage] = useState(1);

  const applyPriceIncrease = (data) => {
    // discard properties without cheapest offers
    const properties = data
      .filter((item) => item.cheapestOffers.length > 0)
      .map((item) => {
        // add 30% to all prices
        item.cheapestOffers.forEach((offer) => {
          if (!offer.hasOwnProperty("original_price")) {
            offer.original_price = offer.price;
          }
          const price = offer.original_price;
          let increase = 0;

          let velocityNum = request.velocity;
          velocityNum = 10; // force velocity to 40%

          switch (velocityNum) {
            case 30: // controlled
              increase = (price / 100) * 45;
              break;
            case 20: // slow
              increase = (price / 100) * 35;
              break;
            case 10: // standard
              increase = (price / 100) * 40;
              break;
            default:
              increase = 0;
          }
          const articlePrice = 0;
          // if (request.articleWriting === 1) {
          //   // + prezzo commissione articolo * numero articoli
          //   const quality = request.itemQuality.value ? request.itemQuality.value : request.itemQuality
          //   switch (quality) {
          //     case 1: articlePrice = 30; break // Alta: 30€ -> 1
          //     case 2: articlePrice = 20; break // Media: 20€ -> 2
          //     case 3: articlePrice = 10; break // Bassa: 10€ -> 3
          //   }
          // }

          // OriginalPrice / (1 - 0,4) -> https://teamwork.devinterface.com/#/tasks/35758934
          // arrotondati alla decina più vicina
          offer.price = Math.ceil(price / 0.6 / 10) * 10;
        });
        return item;
      });
    // console.log('properties:', properties)
    return properties;
  };

  const columnsProperties = [
    {
      title: "",
      dataIndex: "globus",
      className: "text-center",
      render: (item) =>
        i18n.t(item) || (
          <i
            className="fa fa-lg fa-globe text-center"
            style={{ color: "#BAA4D0" }}
            aria-hidden="true"
          />
        ),
      // width: 62
    },
    {
      title: () => i18n.t("Property name"),
      dataIndex: "name",
      render: (item) => item || "-",
      // width: 250
    },
    {
      title: i18n.t("Category"),
      dataIndex: "categories",
      render: (item) => item.map(({ id, name }) => name).join(", ") || "-",
      // width: 210
    },

    {
      title: i18n.t("Type"),
      dataIndex: "propertyType",
      render: (item) => i18n.t(item) || "-",
      // width: 150
    },
    {
      title: i18n.t("Price"),
      dataIndex: "offers",
      render: (item) => {
        if (item && item.length) {
          if (formFilters.priceFrom) {
            console.log(item);
            const itms = item.filter((o) => o.price >= formFilters.priceFrom);
            if (itms[0]) {
              return moneyField.format(itms[0].price);
            } else {
              return moneyField.format(item[0].price);
            }
          } else {
            return moneyField.format(item[0].price) || null;
          }
        }
      },
      // width: 170
    },
    {
      title: i18n.t("Monthly Traffic"),
      dataIndex: "monthlyTraffic",
      render: (item) => i18n.t(item) || "-",
      // width: 170
    },

    {
      title: i18n.t("Language"),
      dataIndex: "language",
      render: (item) => item || "-",
      // width: 170
    },
    {
      title: i18n.t("DA"),
      dataIndex: "domainAuthorityRating",
      render: (item) => item || "-",
      // width: 150
    },
    {
      title: "",
      dataIndex: "eye",
      className: "text-center",
      render: (item) => item || "",
      // width: 150
    },
  ];

  // const { showHeader } = state
  const queryVariables = {
    page: page,
    pageSize: 10,
    userId: false,
    withOffer: true,
    selfService: true,
    ...formFilters,
    categories: formFilters.category,
  };
  delete queryVariables.category;

  return (
    <Query
      query={getPaginatedProperties}
      fetchPolicy="network-only"
      variables={queryVariables}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) return `Error! ${error.message}`;

        let offers = [];
        for (const prop in data.paginatedProperties.objects) {
          //offers = offers.concat(data.paginatedProperties.objects[prop].offers)
          offers = offers.concat(
            data.paginatedProperties.objects[prop].cheapestOffers
          );
        }
        state.offers = offers;

        const items = applyPriceIncrease(data.paginatedProperties.objects);
        return (
          <div data-component="Forms/CreateSelfServiceRequestForm/property">
            <div className="mt-4 text">
              {i18n.t("Sites Present")}:
              <span className="pl-3" style={{ color: "#9777ba" }}>
                {data.paginatedProperties ? data.paginatedProperties.total : 0}
              </span>
            </div>
            <div className="table-container">
              <Filters
                filters={tmpFormFilters}
                onChangeSearch={handleChangeValue}
                handleClickOnSelect={handleClickOnSelect}
                handlePriceFromValue={handlePriceFromValue}
                handlePriceToValue={handlePriceToValue}
                handleDAFromValue={handleDAFromValue}
                handleDAToValue={handleDAToValue}
                onFilterButtonClick={onFilterButtonClick}
                propertyCategories={props.propertyCategories}
                onChangeUrl={handleChangeUrl}
                multipleCategories
              />
              <Table
                rowSelection={props.userType === 1 ? null : rowSelection}
                showHeader
                className="offers-table"
                pagination={{
                  itemRender: paginationRender,
                  total: data.paginatedProperties.total,
                  current: page,
                  onChange: setPage,
                }}
                columns={columnsProperties}
                dataSource={items}
                expandIcon={CustomExpandIcon}
                expandIconAsCell={false}
                expandIconColumnIndex={8}
                expandedRowRender={(item) => (
                  <RespectivePropertyInfo
                    data={item}
                    setOffersKeys={props.setOffersKeys}
                    jobOrder={props.jobOrder}
                    substituteClickHandle={props.substituteClickHandle}
                    formFilters={formFilters}
                  />
                )}
                rowKey="id"
              />
            </div>
          </div>
        );
      }}
    </Query>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories.categories,
  };
};

export default connect(mapStateToProps)(PropertiesComponent);
