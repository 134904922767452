// ACCSESS
const lang_prefix = '/:lng(en|it)'
export const SIGN_IN = lang_prefix + '/login'
export const PUBLISHER_SIGN_UP = lang_prefix + '/publisher/signup'
export const ADVERTISER_TYPE = lang_prefix + '/advertiser/type'
export const ADVERTISER_COMPANY_SIGN_UP = lang_prefix + '/advertiser/company/signup'
export const ADVERTISER_FREELANCE_SIGN_UP = lang_prefix + '/advertiser/freelance/signup'

export const CONFIRM_PASSWORD_RESET = '/confirm-password-reset'
export const CONFIRM_EMAIL = '/confirm-email/:uid/:confirmToken'
export const RESET_PASSWORD = '/reset-password/:uid/:confirmToken'
export const FORGOT_PASSWORD = lang_prefix + '/forgot-password'
export const RESEND_ACTIVATION = lang_prefix + '/resend-activation'

export const SSO_LOGIN = '/sso/:token'

// ARE COMMON ROUTES
export const DASHBOARD = '/dashboard'
export const HOME = lang_prefix + '?'
export const PROFILE = '/profile'

// PUBLISHER
export const COMPLETE_PUBLISHER = '/complete-publisher'
export const CREATE_PROPERTY = '/create-property'
export const EDIT_PROPERTY = '/edit-property/:id'
export const SAVED_ACTION_PROPERY = '/saved-action/property'
export const SAVED_ACTION_OFFER = '/saved-action/offer'
export const PROPERTIES = '/properties'
export const PROPERTY = '/properties/:id'
export const CREATE_OFFER = '/properties/:id/create-offer'
export const EDIT_OFFER = '/properties/:propertyId/edit-offer/:offerId/'
export const PROPOSALS = '/proposals'
export const PROPOSAL = '/proposals/:id'

// ADVERTISER
export const CREATE_REQUEST = '/create-request'
export const CREATE_SELF_SERVICE_REQUEST = '/create-self-service-request'
export const UPDATE_SELF_SERVICE_REQUEST = '/update-self-service-request/:id'
export const REQUESTS = '/requests'
export const REQUEST = '/requests/:id'
export const PAY_REQUEST = '/requests/:id/pay'
export const REQUEST_TO_APPROVE = '/requests/:id/request-to-approve'
export const ACTIVE_REQUEST = '/requests/:id/active-request'
export const UNDER_PROCESSING = '/requests/:id/under-processing'
export const COMPLETED_REQUEST = '/requests/:id/completed-request'
export const REQUEST_TO_PAYMENT = '/requests/:id/request-to-payment'

// CLIENT
export const ORDERS = '/orders'
export const INVOICES = '/invoices'
export const ORDER_DETAIL = '/orders/new-order/:id'
export const ORDERS_ON_HOLD = '/orders/f/on-hold'
export const ORDERS_IN_DRAFT = '/orders/f/draft'
export const ORDERS_APPROVAL = '/orders/f/approval'
export const ORDERS_PAYMENT = '/orders/f/payment'
export const CATEGORIES_SELECTION = '/orders/:id/category-selection'
export const SAVE_ORDERS = '/orders/:id/save'
export const ORDER_MARGIN = '/orders/:id/margin'
export const ORDER_UPDATE_MARGIN = '/orders/:id/update_margin'
export const ORDER_SET_OWNER = '/orders/:id/set_owner'
export const TOTAL_MARGINALITY = '/orders/:id/total-marginality'
export const PERSONALIZED_MARGIN = '/orders/:id/personalized-margin'
export const ORDER = '/orders/:id'
export const CHATS = '/chats'
export const ORDER_CHAT = '/chat/:id/'
export const NOTIFICATIONS = '/notifications'
export const NOTIFICATIONS_ORDERS_APPROVAL =
  '/notifications/offers-in-approval'
export const NOTIFICATIONS_ACTIVE_ORDERS = '/notifications/active-orders'
export const SHOWPROPERTIES = '/showproperties'
export const ADD_ORDER_OFFER =
  '/orders/:orderId/add-offer'
export const SHOWUSERS = '/showusers'
export const SHOWCOUPONS = '/showcoupons'
export const CREATE_COUPON = '/create-coupon'
export const ADMIN_EDIT_USER = '/edit-users/:userId'
export const ADMIN_CREATE_REQUEST = '/client-create-request'
