import React, { useState } from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import i18n from 'i18n'
import OffersList from 'components/Dashboard/Publisher/Properties/Property/OffersList'

import 'pages/Dashboard/Publisher/Properties/style.scss'

const RespectivePropertyInfo = (props) => {
  const [showButton, setShowButton] = useState(false)

  //const { url, propertyType, country, offers } = props.data
  const { url, propertyType, country, cheapestOffers } = props.data

  const today = moment().format('YYYY-MM-DD')

  const filterTemporaryOffers = offers => {
    const standard_offers = offers.filter(item => (!item.isTemporary))
    const temporary_offers = offers.filter(item => (item.isTemporary))

    let filtered = []
    for (const i in temporary_offers) {
      const offer = temporary_offers[i]
      if (offer.expiresAt >= today) {
        filtered.push(offer)
      }
    }
    if (filtered.length === 0) {
      filtered = removeOffersOutOfPrice(standard_offers)
    }
    return filtered
  }

  const removeOffersOutOfPrice = offers => {
    if (props.formFilters.priceFrom && props.formFilters.priceTo) {
      return offers.filter(o => o.price >= props.formFilters.priceFrom && o.price <= props.formFilters.priceTo)
    } else if (props.formFilters.priceTo) {
      return offers.filter(o => o.price <= props.formFilters.priceTo)
    } else if (props.formFilters.priceFrom) {
      return offers.filter(o => o.price >= props.formFilters.priceFrom)
    } else {
      return offers
    }
  }

  return (
    <div 
      className='content property border-0 p-2 mt-1' 
      data-component='Forms/CreateSelfServiceRequestForm/respectivePropertyInfo'>
      <Row>
        <Col span={6}>
          <span className='label'>URL:</span>
          <span className='content'>
            <a href={url} rel='noopener noreferrer' target='_blank'>
              {url}
            </a>
          </span>
        </Col>
      </Row>
      <div className='border-bottom mb-3'>
        <Row>
          <Col span={6}>
            <span className='label'>{i18n.t('Type')}</span>
            <span className='content'>{propertyType}</span>
          </Col>
          <Col span={6}>
            <span className='label'>{i18n.t('Publication')}:</span>
            <span className='content'>Definitiva</span>
          </Col>
          <Col span={6}>
            <span className='label'>{i18n.t('Country')}</span>
            <span className='content'>{country}</span>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={6}>
          <span className='label'>Offerte Presenti</span>
        </Col>
      </Row>
      <OffersList
        //data={filterTemporaryOffers(offers)}
        data={filterTemporaryOffers(cheapestOffers)}
        userType={1}
        setOffersKeys={props.setOffersKeys}
        // jobOrder={props.jobOrder.jobOrder}
        jobOrder={props.jobOrder}
        property={props.data}
        substituteClickHandle={props.substituteClickHandle}
        showButton={showButton}
        setShowButton={setShowButton}
      />
    </div>
  )
}

export default RespectivePropertyInfo
