import React from "react";
import "./styles.css";
import classnames from "classnames";


const Radio = ({onChange, label, className, checked}) => {
  return (
    <div
      className={classnames('radio-wrapper', className)}
      onClick={onChange}
    >
      <div
        className={classnames('outer-circle', {'unselected': !checked})}
      >
        <div
          className={classnames('inner-circle', {'unselected-circle': !checked })}
        />
      </div>
      <div className="helper-text">{label}</div>
    </div>
  );
}

export default Radio