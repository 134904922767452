import React from 'react'

const ProfileName = ({ user }) => {
  const printCompanyName = () => (
    user.company && user.company.name
      ? `${user.company.name}`
      : user.email
  )
  const printUserName = () => (
    user.name || user.surname
      ? `${user.name} ${user.surname}`
      : user.email
  )
  return (
    <>
      {user.userTypology === 2
        ? printCompanyName()
        : printUserName()}
    </>
  )
}

export default ProfileName
