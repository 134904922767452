import React from 'react'
import { Link } from 'react-router-dom'

import './style.scss'

const Title = ({ descTop, descBottom, image, to, path, title, style }) => (
  <div className='suggestion' style={style}>
    {image && <img src={image} alt='' height='60px' width='auto' />}
    {descTop && (
      <p className='description' style={{ color: 'white' }}>
        {descTop}{' '}
        <strong style={{ paddingLeft: '5px' }}>
          <Link to={to}>{path}</Link>
        </strong>
      </p>
    )}
    {title && <h2 className='title'>{title}</h2>}
    {descBottom && <p className='description'>{descBottom}</p>}
  </div>
)

export default Title
