import { SET_CATEGORIES, REMOVE_CATEGORY } from '../constants'

const initialState = {
  selectedRowKeys: [],
  categories: []
}

export const categories = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        selectedRowKeys: action.selectedRowKeys,
        categories: action.categories
      }
    case REMOVE_CATEGORY:
      return {
        ...state,
        categories: action.categories,
        selectedRowKeys: [
          ...state.selectedRowKeys.filter(item => item !== action.key)
        ]
      }
    default:
      return state
  }
}
