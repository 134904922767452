import React from 'react'

const Notification = ({ color, title, width, fontSize }) => (
  <div
    className='d-flex align-items-center'
    style={{
      height: '40px',
      width: width || '100%',
      padding: '2px 10px',
      borderRadius: '14.5px',
      fontSize: fontSize || null,
      backgroundColor: color
    }}
  >
    <i className='fa fa-circle pr-3 text-white' />
    <p className='m-0 text-white'>{title}</p>
  </div>
)

export default Notification
