import React from 'react'

import { Field } from 'formik'
import { Checkbox } from 'antd'

const DefaultCheckbox = ({ name, type, label, checked }) => (
  <Field name={name} type={type} component={CheckboxComponent} label={label} checked={checked} />
)

export const CheckboxComponent = props => (
  <Checkbox className='checkbox' onChange={props.onChange} checked={props.checked}>
    {props.label}
  </Checkbox>
)

export default DefaultCheckbox
