import { Field, Form, Formik } from 'formik'
import i18n from 'i18n'
import React from 'react'

import DefaultButtom from '../DefaultButton'
import RadioButton from '../RadioButton'
import ReactSelectField from '../ReactSelectField'

const userTypes = [
  { label: i18n.t('Client'), value: '1' },
  { label: i18n.t('Advertiser'), value: '2' },
  { label: i18n.t('Publisher'), value: '3' }
]
const advertiserTypes = [
  { label: i18n.t('Freelance'), value: '1' },
  { label: i18n.t('Company'), value: '2' }
]

const AdminEditUserForm = props => (
  <Formik
    initialValues={{
      id: props.defaultValues.id,
      userName: props.defaultValues.userName,
      name: props.defaultValues.name,
      surname: props.defaultValues.surname,
      email: props.defaultValues.email,
      company: props.defaultValues.company,
      isConfirmed: props.defaultValues.isConfirmed.toString(),
      status: props.defaultValues.status,
      advertiserType: props.defaultValues.advertiserType,
    }}
    onSubmit={props.handleEditUser}
  >
    {props => {
      const {
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched
      } = props
      const status_value = values.status.value || values.status
      return (
        <Form>
          <div className='row'>
            <div className='col-12 mb-5'>
              <h3 className='title text-left p-0'>
                {i18n.t('Edit profile')}
              </h3>
              <table className='table table-borderless table-sm text-left'>
                <tbody>
                  <tr>
                    <th>{i18n.t('Id')}</th><td>{values.id}</td>
                  </tr>
                  <tr>
                    <th>{i18n.t('Name')}</th><td>{values.name}</td>
                  </tr>
                  <tr>
                    <th>{i18n.t('Surname')}</th><td>{values.suname}</td>
                  </tr>
                  <tr>
                    <th>{i18n.t('Email')}</th><td>{values.email}</td>
                  </tr>
                  {values.company && (
                    <tr>
                      <th>{i18n.t('Company')}</th><td>{values.company.name}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12'>
              <label>{i18n.t('userType')}</label>
              <ReactSelectField
                name='status'
                fieldType='status'
                options={userTypes}
                value={values.status}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.status}
                touched={touched.status}
                placeholder={i18n.t('userType')}
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12'>
              <label>{i18n.t('advertiserType')}</label>
              <ReactSelectField
                name='advertiserType'
                fieldType='advertiserType'
                options={advertiserTypes}
                value={values.advertiserType}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.advertiserType}
                touched={touched.advertiserType}
                placeholder={i18n.t('advertiserType')}
                disabled={parseInt(status_value) !== 2}
              />
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-6'>
              <label>{i18n.t('Status')}</label>
              <Field
                component={RadioButton}
                name='isConfirmed'
                id='conf_true'
                value
                label={i18n.t('Active')}
                checked={values.isConfirmed === 'true'}
              />
              <Field
                component={RadioButton}
                name='isConfirmed'
                id='conf_false'
                value={false}
                label={i18n.t('Inactive')}
                checked={values.isConfirmed === 'false'}
              />
            </div>
            
          </div>

          <div className='row'>
            <div className='col-12'>
              <DefaultButtom type='submit'>
                {i18n.t('Save')}
              </DefaultButtom>
            </div>
          </div>
        </Form>
      )
    }}
  </Formik>
)

export default AdminEditUserForm
