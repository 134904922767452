import React from 'react'

import Title from 'components/Access/Title'
import { Row, Col } from 'antd'
import { Formik, Form, Field } from 'formik'
import DefaultField from '../DefaultField'
import DefaultButton from '../DefaultButton'
import ReactSelectField from '../ReactSelectField'

import { BUTTON_COLOR } from 'constants/colors'
import { CreatePropertySchema } from './validation'
import { languages, countries, propertyTypes, articleWriting, selfServiceOptions } from 'utils/api'

import 'react-select/dist/react-select.css'
import i18n from 'i18n'

const CreatePropertyForm = props => {
  const propertyCategories = props.propertyCategories.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => ({
    label: name,
    value: id
  }))
  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        url: '',
        monthlyTraffic: '',
        language: '',
        country: '',
        articleWriting: 1,
        category: '',
        categories: [],
        type: '',
        enableSelfService: true
      }}
      validationSchema={CreatePropertySchema}
      onSubmit={props.handleCreateProperty}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched
        } = props
        return (
          <>
            <Title
              title={i18n.t('New property')}
              style={{ color: '#555555' }}
            />
            <Form>
              <DefaultField
                name='name'
                type='text'
                label={i18n.t('Name Property')}
                placeholder={i18n.t('Enter the name of the property')}
              />
              <div className='position-relative form-group'>
                <label>{i18n.t('Description')}</label>
                <Field
                  name='description'
                  rows={8}
                  type='text'
                  className={
                    props.errors.description && props.touched.description
                      ? 'form-control is-invalid rounded-0'
                      : 'form-control rounded-0'
                  }
                  label={i18n.t('Description')}
                  placeholder={i18n.t('Enter the description of the property')}
                  component='textarea'
                />
                <div className='invalid-feedback'>
                  {i18n.t('Description is required!')}
                </div>
              </div>
              <Row gutter={32}>
                <Col span={14}>
                  <DefaultField
                    name='url'
                    type='text'
                    label='URL:'
                    placeholder='es. https://www.nomesito.it'
                  />
                </Col>
                <Col span={10}>
                  <DefaultField
                    name='monthlyTraffic'
                    type='number'
                    label={i18n.t('Monthly Traffic')}
                    placeholder='es. 5000'
                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={7}>
                  <label>{i18n.t('Language')}</label>
                  <ReactSelectField
                    name='Language'
                    fieldType='language'
                    options={languages}
                    value={values.language}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.language}
                    touched={touched.language}
                    placeholder={i18n.t('Select language')}
                  />
                </Col>
                <Col span={8}>
                  <label>Country:</label>
                  <ReactSelectField
                    name='Country'
                    fieldType='country'
                    options={countries}
                    value={values.country}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.country}
                    touched={touched.country}
                    placeholder={i18n.t('Select Country')}
                  />
                </Col>
                <Col span={9}>
                  <label>{i18n.t('Article Writing')}</label>
                  <ReactSelectField
                    name={i18n.t('Writing Article')}
                    fieldType='articleWriting'
                    options={articleWriting}
                    value={values.articleWriting}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.articleWriting}
                    touched={touched.articleWriting}
                    placeholder={i18n.t('Select Article')}
                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <label>{i18n.t('Category')}</label>
                  <ReactSelectField
                    name='Categories'
                    fieldType='categories'
                    options={propertyCategories}
                    multi
                    value={values.categories}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.categories}
                    touched={touched.categories}
                    placeholder={i18n.t('Select the category of property')}
                  />
                </Col>
                <Col span={12}>
                  <label>{i18n.t('Type')}</label>
                  <ReactSelectField
                    name='Type'
                    fieldType='type'
                    options={propertyTypes}
                    value={values.type}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.type}
                    touched={touched.type}
                    placeholder={i18n.t('Select the type of property')}
                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={32}>
                  <label>{i18n.t('enableSelfserviceTip')}</label>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <ReactSelectField
                    name='Enable Self Service'
                    fieldType='enableSelfService'
                    options={selfServiceOptions}
                    value={values.enableSelfService}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.enableSelfService}
                    touched={touched.enableSelfService}
                  />
                </Col>
              </Row>
              <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                <DefaultButton
                  type='submit'
                  style={{ backgroundColor: BUTTON_COLOR, padding: '0 80px' }}
                >
                  {i18n.t('Create Property')}
                </DefaultButton>
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default CreatePropertyForm
