import React from 'react'
import { withRouter, Link } from 'react-router-dom'

import { Row, Col } from 'antd'
import { DefaultButtonIcon } from 'components/Forms/DefaultButton'
import Close from 'components/Icons/Close'

import * as path from 'constants/routes'

import i18n from 'i18n'

const SavedProperty = ({ location, history }) => (
  <div className='row justify-content-md-center p-5 center'>
    <div className='col-6'>
      <h1 className='title'>{i18n.t('Saved Property')}</h1>
      <p className='subtitle'>{i18n.t('Congratulations!')}</p>
      <p className='subtitle'>
        {i18n.t('Your property has been successfully created')}
        <br /><br />
        <strong>Inserisci subito un'offerta per la tua proprietà!</strong>
      </p>
      <div className='p-5'>
        <Row>
          <Col span={8}>
            <Link to={path.PROPERTIES}>
              <DefaultButtonIcon
                label={i18n.t('Back to Properties')}
                icon={<i className='fas fa-sign-out-alt' />}
                type='default'
              />
            </Link>
          </Col>
          <Link to={`/properties/${location.state}/create-offer`}>
            <Col span={8}>
              <DefaultButtonIcon
                label={i18n.t('New offer')}
                icon={<i className='fas fa-plus' />}
                type='default'
              />
            </Col>
          </Link>

          <Link to={path.CREATE_PROPERTY}>
            <Col span={8}>
              <DefaultButtonIcon
                label={i18n.t('New property')}
                icon={<i className='fas fa-plus' />}
                type='default'
              />
            </Col>
          </Link>
        </Row>
      </div>
      <Close onClick={() => history.push(path.PROPERTIES)} />
    </div>
  </div>
)

export default withRouter(SavedProperty)
