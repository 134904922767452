import Yup from 'yup'
import i18n from 'i18n'
export const EditOfferSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('Name is required!')),
  description: Yup.string().required(i18n.t('Description is required!')),
  price: Yup.number()
    .integer(i18n.t('Price is required!'))
    .required(i18n.t('Price is required!')),
  linkType: Yup.string().required(i18n.t('Link type is required!')),
  publicationType: Yup.string().required(i18n.t('Public type is required!')),
  prohibitedArticles: Yup.string().required(i18n.t('Prohibited Articles is required!')),
  isTemporary: Yup.boolean(),
  expiresAt: Yup.string().nullable().when('isTemporary', {
    is: true,
    then: Yup.string().required('Expire date is required!')
  }),
  articleWriting: Yup.boolean(),
  timeForWriting: Yup.string().nullable().when('articleWriting', {
    is: true,
    then: Yup.string().required('Time for writing is required!')
  }),
  language: Yup.string().nullable().when('articleWriting', {
    is: true,
    then: Yup.string().required('Article language is required!')
  }),
})
