import * as path from '../constants'

const initialState = {
  offers: []
}

export const orders = (state = initialState, action) => {
  switch (action.type) {
    case path.SET_OFFERS:
      const offers = state.offers
      offers[action.idx] = action.offer
      offers.join()
      return {
        ...state,
        offers: offers
      }
    default:
      return state
  }
}
