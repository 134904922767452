import React from 'react'
import { graphql } from 'react-apollo'

import CategorySelectionComponent from 'components/Dashboard/Client/CategoriesSelection'
import Close from 'components/Icons/Close'
import Loader from 'components/Loader'

import * as path from 'constants/routes'
import { withAuth } from 'hocs/PrivateRoute'
import { onlyClient } from 'hocs/componentByRole'
import { getJobOrder } from 'queries/queries'

const categoriesSelection = props => {
  if (props.getJobOrder.loading) return <Loader />
  return (
    <div className='row justify-content-md-center p-5 m-0'>
      <div className='col-8'>
        <CategorySelectionComponent {...props} />
        <Close onClick={() => props.history.push(path.ORDERS)} />
      </div>
    </div>
  )
}

export default graphql(getJobOrder, {
  name: 'getJobOrder',
  options: props => ({ variables: { id: props.match.params.id } })
})(withAuth(onlyClient(categoriesSelection)))
