import OrderMarginForm from 'components/Forms/OrderMarginForm'
import Close from 'components/Icons/Close'
import Loader from 'components/Loader'
import { withAuth } from 'hocs/PrivateRoute'
import { onlyClient } from 'hocs/componentByRole'
import { setMargin } from 'mutations'
import { getJobOrder } from 'queries/queries'
import React from 'react'
import { Query, graphql } from 'react-apollo'
import { camelize } from 'utils'

const OrderUpdateMargin = props => {
  const handleSetMargin = async (values, { setErrors }) => {
    const response = await props.setMargin({
      variables: {
        orderId: props.match.params.id,
        margin: values.margin.value,
        marginType: values.marginType.value,
        marginValue:
          values.margin.value === 1 ? values.marginValue || null : null
      },
      refetchQueries: [
        {
          query: getJobOrder,
          variables: { id: props.match.params.id }
        }
      ]
    })
    if (response.data.setMargin.error.validationErrors.length) {
      const errors = {}
      response.data.setMargin.error.validationErrors.map(error => {
        errors[camelize(error.field)] = error.messages.join(' ')
        return errors
      })
      setErrors(errors)
    } else {
      props.history.goBack()
    }
  }

  return (
    <Query
      query={getJobOrder}
      variables={{ id: props.match.params.id }}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loader />
        if (error) return `Error! ${error.message}`
        return (
          <div className='row justify-content-md-center p-5 m-0'>
            <div className='confirm-order col-8'>
              <OrderMarginForm
                {...props}
                {...data.jobOrder}
                onSubmit={handleSetMargin}
              />
              <Close onClick={() => props.history.goBack()} />
            </div>
          </div>
        )
      }}
    </Query>
  )
}

export default graphql(setMargin, { name: 'setMargin' })(
  withAuth(onlyClient(OrderUpdateMargin))
)
