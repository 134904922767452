import React, { useState } from "react";

import UpdateSelfServiceRequestForm from "components/Forms/UpdateSelfServiceRequestForm/updateSelfServiceRequestForm";
import SetPropertyComponent from "components/Forms/UpdateSelfServiceRequestForm/setProperty";
import Close from "components/Icons/Close";

import { withAuth } from "hocs/PrivateRoute";
import { onlyAdvertiser } from "hocs/componentByRole";
import * as path from "constants/routes";
import { graphql, Query } from "react-apollo";
import { updateSelfServiceRequest } from "mutations";
import {
  getPropertyCategories,
  getRequest,
  getSelfServiceRequest,
} from "queries/queries";
import i18n from "i18n";
import swal from "sweetalert";
import Loader from "components/Loader";
// import moment from 'moment'

const UpdateSelfServiceRequest = (props) => {
  const [state] = useState({ error: "" });
  const [isSubmitted, setSubmitted] = useState(false);
  const [request, setRequest] = useState(false);
  const [step, setStep] = useState(1);

  const handlePrepareRequest = async (values, { setErrors }) => {
    values.offers = [];
    values.userId = parseInt(props.getUser.me.id);
    if (
      typeof values.itemQuality === "object" &&
      values.itemQuality.hasOwnProperty("value")
    ) {
      values.itemQuality = values.itemQuality.value;
    }
    if (
      typeof values.language === "object" &&
      values.language.hasOwnProperty("value")
    ) {
      values.language = values.language.value;
    }
    if (
      typeof values.rangeBudget === "object" &&
      values.rangeBudget.hasOwnProperty("value")
    ) {
      values.rangeBudget = values.rangeBudget.value;
    }
    values.priceAugmented = request.priceAugmented;
    setRequest(values);
    setStep(2);
  };

  const [selectedOffersKeys, setOffersKeys] = React.useState([]);
  const [selectedOffersKeysCount, setOffersKeysCount] = React.useState(0);

  const handleOffersKeys = (key, arrIndex, a) => {
    let offersCount = 0;
    // console.log('key', key)
    // console.log('index', arrIndex)
    selectedOffersKeys[arrIndex] = key;
    selectedOffersKeys.join();
    setOffersKeys(selectedOffersKeys);
    // console.log('selectedOffersKeys', selectedOffersKeys)
    selectedOffersKeys.map((item) => (offersCount += item.length));
    setOffersKeysCount(offersCount);
  };

  const willCreateMessage = (request) => {
    if (request.velocity !== 10 && request.articleWriting === 1) {
      return i18n.t(
        "Once confirmed you must enter additional information for each offer."
      );
    } else {
      return i18n.t("Are you sure you want to update the request?");
    }
  };

  const handleUpdateRequest = async () => {
    const willCreate = await swal({
      title: i18n.t("Are you sure?"),
      text: willCreateMessage(request),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willCreate) {
      return new Promise((resolve, reject) => {
        const requestId = request.id;
        const requestData = {
          ...request,
          language: request.articleLanguage,
          averageNumberWords: request.articleLength,
          itemQuality: request.articleQuality,
        };
        // remove unrequired fields
        Object.keys(requestData)
          .filter((key) =>
            [
              "articleLanguage",
              "articleLength",
              "articleQuality",
              "siteCategories",
              "__typename",
              "jobOrder",
              "isSelfService",
              "userId",
              "paidAt",
            ].includes(key)
          )
          .forEach((key) => delete requestData[key]);
        const values = {
          id: requestId,
          requestData: requestData,
          selectedOffers: selectedOffersKeys.flat(),
        };
        resolve();
        setSubmitted(false);

        props
          .updateSelfServiceRequest({
            variables: values,
            refetchQueries: [
              {
                query: getSelfServiceRequest,
                variables: {
                  requestId: requestId,
                  userId: props.getUser.me.id,
                },
              },
              { query: getRequest, variables: { requestId: requestId } },
            ],
          })
          .then((response) => {
            if (response.data.updateSelfServiceRequest.success) {
              // props.history.push(`/requests/${requestId}`)
              window.location.href = `/requests/${requestId}`;
              resolve();
            } else {
              const errors = {};
              response.data.updateSelfServiceRequest.errors.map((error) => {
                console.error(error);
                return (errors[error.field] = error);
              });
              reject(
                swal({
                  title: i18n.t("Error"),
                  text: i18n.t("An error occured."),
                  icon: "error",
                  buttons: true,
                  dangerMode: true,
                })
              );
            }
          })
          .finally(() => {
            console.log("FINALLY");
            setSubmitted(false);
          });
      });
    }
  };

  const stepBack = (currentStep) => {
    const previousStep = currentStep - 1;
    setStep(previousStep);
  };
  const backToDashboard = () => props.history.push(path.DASHBOARD);
  return (
    <Query
      query={getSelfServiceRequest}
      variables={{ requestId: props.match.params.id }}
    >
      {({
        loading: loadingSelfService,
        error: errorSelfService,
        data: dataSelfService,
      }) => {
        if (loadingSelfService) return <Loader />;
        if (errorSelfService) return `Error! ${errorSelfService.message}`;
        if (dataSelfService && dataSelfService.selfServiceRequest && !request) {
          setRequest(dataSelfService.selfServiceRequest);
        }
        return (
          request && (
            <Query
              query={getPropertyCategories}
              variables={{ hasProperties: true }}
            >
              {({
                loading: loadingProperties,
                error: errorProperties,
                data: dataProperties,
              }) => {
                if (loadingProperties) return <Loader />;
                if (errorProperties) return `Error! ${errorProperties.message}`;
                console.log(dataProperties.propertyCategories);
                return (
                  <div
                    className="row justify-content-center m-0 wrapper"
                    data-component="Dashboard/Advertiser/UpdateSelfServiceRequest/index"
                  >
                    <div className={`${step === 1 ? "col-8" : "col-10"} p-0`}>
                      {step === 1 && (
                        <UpdateSelfServiceRequestForm
                          handlePrepareRequest={handlePrepareRequest}
                          initialValues={request}
                          error={state.error}
                          errors={props}
                          isSubmitted={isSubmitted}
                          handleBack={backToDashboard}
                          siteCategories={dataProperties.propertyCategories.map(
                            (el) => el.name
                          )}
                        />
                      )}

                      {step === 2 && (
                        <SetPropertyComponent
                          {...props}
                          jobOrder={request.jobOrder}
                          request={request}
                          setOffersKeys={handleOffersKeys}
                          selectedOffersKeysCount={selectedOffersKeysCount}
                          handleCreateRequest={handleUpdateRequest}
                          handleSteps={stepBack}
                          propertyCategories={dataProperties.propertyCategories}
                        />
                      )}
                    </div>
                    <Close onClick={() => props.history.push(path.DASHBOARD)} />
                  </div>
                );
              }}
            </Query>
          )
        );
      }}
    </Query>
  );
};

export default graphql(updateSelfServiceRequest, {
  name: "updateSelfServiceRequest",
})(withAuth(onlyAdvertiser(UpdateSelfServiceRequest)));
