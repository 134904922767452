import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { generatePath } from 'react-router'
import PropTypes from 'prop-types'

import './style.scss'

class Language extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    const languages = ['en', 'it']
    const links = languages.map((lang, key) => {
      const to = generatePath(match.path, {
        lng: lang
      })
      return(
        <NavLink onClick={this.forceUpdate} key={key} to={to} activeStyle={{ fontWeight: 'bold' }}>{lang}</NavLink>
      )   
    })
    return (
      <div className='lang-switcher'>
        {links}
      </div>
    )    
  }
}

export default withRouter(Language)
