import PageNotFound from 'components/PageNotFound'
import React from 'react'

/*
    1 - client
    2 - advertiser
    3 - publisher
*/

export const onlyPublisher = WrappedComponent => props => {
  return props.getUser.me.userTypology === 3 || props.getUser.me.userTypology === 1 ? (
    <WrappedComponent {...props} />
  ) : (
    <PageNotFound />
  )
}

export const onlyAdvertiser = WrappedComponent => props => {
  return props.getUser.me.userTypology === 2 || props.getUser.me.userTypology === 1 ? (
    <WrappedComponent {...props} />
  ) : (
    <PageNotFound />
  )
}

export const onlyClient = WrappedComponent => props => {
  return props.getUser.me.userTypology === 1 ? (
    <WrappedComponent {...props} />
  ) : (
    <PageNotFound />
  )
}
