import React, { useState } from 'react'
import { Query, graphql } from 'react-apollo'

import CreatePropertyForm from 'components/Forms/CreatePropertyForm'
import Close from 'components/Icons/Close'
import Loader from 'components/Loader'

import { getProperties, User, getPropertyCategories } from 'queries/queries'
import { createProperty } from 'mutations'
import { withAuth } from 'hocs/PrivateRoute'
import { onlyPublisher } from 'hocs/componentByRole'
import * as path from 'constants/routes'

const CreateProperty = props => {
  const [state] = useState({ error: '' })
  const handleCreateProperty = (values, { setErrors }) =>
    new Promise((resolve, reject) => {
      const {
        name,
        description,
        url,
        monthlyTraffic,
        language,
        country,
        articleWriting,
        category,
        categories,
        type,
        enableSelfService
      } = values

      let enableSelfServiceValue = enableSelfService.value

      if (enableSelfServiceValue === undefined) {
        enableSelfServiceValue = true
      }
      const categories_arr = []
      if (categories.length > 0) {
        for (const c in categories) {
          console.log(categories[c])
          categories_arr.push(categories[c].value)
        }
      }
      props
        .createProperty({
          variables: {
            name,
            description,
            url,
            monthlyTraffic,
            language: language.value,
            country: country.value,
            articleWriting: articleWriting.value || 1,
            category: category.value,
            categories: categories_arr,
            propertyType: type.value,
            userId: props.getUser.me.id,
            enableSelfService: enableSelfServiceValue
          },
          refetchQueries: [
            {
              query: getProperties,
              variables: { userId: props.getUser.me.id }
            },
            { query: User }
          ]
        })
        .then(response => {
          if (response.data.createProperty.success) {
            props.history.push(
              `/properties/${response.data.createProperty.property.id}/create-offer`
            )
            resolve()
          } else {
            const errors = {}
            response.data.createProperty.error.validationErrors.map(
              error => (errors[error.field] = error.messages.join(' '))
            )
            setErrors(errors)
            reject()
          }
        })
    })
  return (
    <div className='row justify-content-md-center p-5 m-0'>
      <Query query={getPropertyCategories}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />
          if (error) return `Error! ${error.message}`
          return (
            <div className='col-8'>
              <CreatePropertyForm
                propertyCategories={data.propertyCategories}
                handleCreateProperty={handleCreateProperty}
                error={state.error}
                errors={props}
              />
              <Close onClick={() => props.history.push(path.PROPERTIES)} />
            </div>
          )
        }}
      </Query>
    </div>
  )
}

export default graphql(createProperty, { name: 'createProperty' })(
  withAuth(onlyPublisher(CreateProperty))
)
