import React from 'react'

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  checked,
  className,
  inline,
  ...props
}) => {
  return (

    <div className={`custom-control custom-radio ${inline ? 'custom-control-inline' : ''}`}>
      <input
        name={name}
        id={id}
        type='radio'
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        className='custom-control-input'
        {...props}
      />
      <label htmlFor={id} className='custom-control-label'>
        {label}
      </label>
    </div>
  )
}

export default RadioButton
