import { Avatar } from 'antd'
import accountIcon from 'assets/icona-account.png'
import Notifications from 'components/NavBar/Notifications'
import * as path from 'constants/routes'
import i18n from 'i18n'
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBNavLink
} from 'mdbreact'
import React from 'react'

import ProfileName from './profileName.js'

const Profile = ({ user, handleLogout }) => (
  <MDBDropdown>
    <div className='d-flex align-items-center'>
      <Notifications user={user} />
      <MDBDropdownToggle nav>
        <div className='text-white'>
          <ProfileName user={user} />
        </div>
      </MDBDropdownToggle>
    </div>
    <MDBDropdownMenu right basic className='dropdown-default' style={{ minWidth: '300px' }}>
      <div className='p-3'>
        <div className='d-flex align-items-center'>
          <div className='pr-3'>
            <Avatar size='large' icon='user' src={accountIcon} />
          </div>
          <p className='m-0'>
            <ProfileName user={user} />
            <small className='d-block text-muted'>{user.userTypologyDisplay}</small>
          </p>
        </div>
        <div className='border-bottom pt-3' />
        <MDBNavLink
          to={path.PROFILE}
          className='pt-3 pb-0 pl-0 pr-0'
          activeClassName='active'
          style={{
            color: '#555',
            fontSize: '16px',
            fontWeight: '700'
          }}
        >
          {i18n.t('View Profile')}
        </MDBNavLink>
        <MDBNavLink
          to='#'
          className='pt-3 pl-0 pr-0 pb-0'
          activeClassName='active'
          onClick={handleLogout}
          style={{
            color: '#E08080',
            fontSize: '16px',
            fontWeight: '400'
          }}
        >
          <p className='m-0' style={{ color: '#E08080' }}>
            {i18n.t('Logout')}
          </p>
        </MDBNavLink>
      </div>
    </MDBDropdownMenu>
  </MDBDropdown>
)

export default Profile
