import React, { useState } from 'react'
import { Query, graphql, compose } from 'react-apollo'

import Loader from 'components/Loader'
import EditPropertyForm from 'components/Forms/EditPropertyForm'
import Close from 'components/Icons/Close'

import {
  getProperty,
  getProperties,
  getPropertyCategories
} from 'queries/queries'
import { updateProperty } from 'mutations'
import { withAuth } from 'hocs/PrivateRoute'
import { onlyPublisher } from 'hocs/componentByRole'
import * as path from 'constants/routes'

const EditProperty = props => {
  const [state] = useState({ error: '' })
  const handleEditProperty = (values, { setErrors }) =>
    new Promise((resolve, reject) => {
      const {
        name,
        description,
        url,
        monthlyTraffic,
        language,
        country,
        articleWriting,
        categories,
        type,
        enableSelfService
      } = values

      let enableSelfServiceValue = enableSelfService.value
      if (enableSelfServiceValue === undefined) {
        enableSelfServiceValue = enableSelfService
      }

      const categories_arr = []
      if (categories.length > 0) {
        for (const c in categories) {
          console.log('category: ', categories[c])
          let value = categories[c].id
          categories_arr.push(value)
        }
      }

      props
        .updateProperty({
          variables: {
            propertyId: props.match.params.id,
            name,
            description,
            url,
            monthlyTraffic,
            language: language.value || language,
            country: country.value || country,
            articleWriting: articleWriting.value || articleWriting,
            categories: categories_arr,
            propertyType: type.value || type,
            userId: props.getUser.me.id,
            enableSelfService: enableSelfServiceValue
          },
          refetchQueries: [
            {
              query: getProperties,
              variables: { userId: props.getUser.me.id }
            }
          ]
        })
        .then(response => {
          if (response.data.updateProperty.success) {
            props.history.push(
              path.SAVED_ACTION_PROPERY,
              response.data.updateProperty.property.id
            )
            resolve()
          } else {
            const errors = {}
            response.data.updateProperty.error.validationErrors.map(
              error => (errors[error.field] = error.messages.join(' '))
            )
            setErrors(errors)
            reject()
          }
        })
    })
  return (
    <div 
      className='row justify-content-md-center p-5 m-0' 
      data-coponent='Dashboard/Publisher/EditProperty'>
      <div className='col-8'>
        <Query query={getProperty} variables={{ id: props.match.params.id }}>
          {({ loading, error, data }) => {
            if (loading) return <Loader />
            if (error) return `Error! ${error.message}`
            return (
              <>
                <EditPropertyForm
                  handleEditProperty={handleEditProperty}
                  propertyCategories={props.getPropertyCategories}
                  error={state.error}
                  errors={props}
                  defaultValues={data.property}
                />
                <Close onClick={() => props.history.push(path.PROPERTIES)} />
              </>
            )
          }}
        </Query>
      </div>
    </div>
  )
}

export default compose(
  graphql(getPropertyCategories, {
    name: 'getPropertyCategories'
  }),
  graphql(updateProperty, {
    name: 'updateProperty'
  })
)(withAuth(onlyPublisher(EditProperty)))
