import React, { useState } from 'react'

import { Formik, Form, Field } from 'formik'
import Title from 'components/Access/Title'
import DefaultField from '../DefaultField'
import DefaultButton from '../DefaultButton'
import ReactSelectField from '../ReactSelectField'
import { Radio } from 'antd'
import Select from 'react-select'
import Notification from 'components/Notification'
import { CreateRequestSchema10, CreateRequestSchema20, CreateRequestSchema30 } from './validation'
import { languages, requestQualities, budgetValues, objectiveValues, labelFor } from 'utils/api'
import { BUTTON_COLOR } from 'constants/colors'
import i18n from 'i18n'

import 'react-select/dist/react-select.css'

const RadioGroup = Radio.Group

const AdminCreateRequestForm = props => {
  // const writing = 1
  const [writing, writingHandler] = useState(1)
  // const selectedVelocity = 10
  const [selectedVelocity, setSelectedVelocity] = useState(10)
  const [selectedObjective, setSelectedObjective] = useState(10)
  const [selectedUser, setSelectedUser] = useState(props.user_id)

  const userValues = props.advertisers.sort((a, b) => a.userName.localeCompare(b.userName)).map(({ id, userName, email }) => ({
    label: `${userName} - ${email}`,
    value: id
  }))

  const isSubmitted = props.isSubmitted
  const submitForm = (values, actions) => {
    values.articleWriting = writing
    values.objective = selectedObjective
    values.velocity = selectedVelocity
    values.user = selectedUser
    return props.handleCreateRequest(values, actions)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: '',
        objective: 10,
        topic: '',
        targetUrl: '',
        months: 1,
        velocity: 10,
        linkNumber: 1,
        rangeBudget: 0,
        language: '',
        description: '',
        domainAuthority: '',
        articleWriting: 1,
        itemQuality: 1,
        isApproval: 2,
        selectedObjective: 10,
        user: ''
      }}
      validationSchema={selectedObjective === 10 ? CreateRequestSchema10 : selectedObjective === 20 ? CreateRequestSchema20 : CreateRequestSchema30}
      onSubmit={submitForm}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched
        } = props

        return (
          <div data-component='Forms/AdminCreateRequestForm/index'>
            <Title title={i18n.t('New Admin Request')} style={{ color: '#555555' }} />
            <Form>
              <div className='d-flex justify-content-between'>
                <div className='col-6'>
                  <div className='position-relative form-group'>
                    <label className='label-color'>{i18n.t('Advertiser\'s name')}</label>
                    <Select
                      id='user'
                      options={userValues}
                      multi={false}
                      clearable={false}
                      onChange={value => setSelectedUser((value && value.value) ? value.value : null)}
                      onBlur={() => setFieldTouched('user')}
                      value={selectedUser}
                      className='field-select'
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='col-6'>
                  <DefaultField
                    name='name'
                    type='text'
                    label={i18n.t('Admin Request Title')}
                    placeholder={i18n.t('Ex. Publications Site Name')}
                  />
                </div>
                <div className='col-6'>
                  <label>{i18n.t('Objective')}</label>
                  <Select
                    id='objective'
                    options={objectiveValues}
                    multi={false}
                    onChange={value => setSelectedObjective((value && value.value) ? value.value : null)}
                    onBlur={() => setFieldTouched('objective')}
                    value={selectedObjective}
                    className='field-select'
                  />
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                {selectedObjective === 10 && (
                  <div className='col-6'>
                    <DefaultField
                      name='linkNumber'
                      type='number'
                      label={i18n.t('Link number')}
                      placeholder='es. 5'
                    />
                  </div>
                )}
              </div>

              <div className='d-flex justify-content-between'>
                <div className='col-6'>
                  <DefaultField
                    placeholder={i18n.t('Comma separated values')}
                    name='topic'
                    type='text'
                    label={i18n.t('Topic')}
                  />
                </div>
                <div className='col-6'>
                  <DefaultField
                    name='targetUrl'
                    type='url'
                    label={labelFor(selectedObjective, 'targetUrl', i18n.t('Target Url'))}
                  />
                </div>
              </div>

              <div className='d-flex justify-content-between'>
                <div className='col-6'>
                  <label>{labelFor(selectedObjective, 'rangeBudget', i18n.t('Total Budget'))}</label>
                  <ReactSelectField
                    name='rangeBudget'
                    fieldType='rangeBudget'
                    options={budgetValues}
                    value={values.rangeBudget}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.rangeBudget}
                    touched={touched.rangeBudget}
                  />
                </div>
                <div className='col-6'>
                  <label>{i18n.t('Language')}</label>
                  <ReactSelectField
                    name='Language'
                    fieldType='language'
                    options={languages}
                    value={values.language}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.language}
                    touched={touched.language}
                    placeholder={i18n.t('Select language')}
                  />
                </div>
              </div>

              <div className='position-relative form-group col-12'>
                <label>{labelFor(selectedObjective, 'description')}</label>
                <Field
                  name='description'
                  rows={8}
                  type='text'
                  className={
                    props.errors.description && props.touched.description
                      ? 'form-control is-invalid rounded-0'
                      : 'form-control rounded-0'
                  }
                  label='Enter Request Specifications'
                  placeholder={labelFor(selectedObjective, 'descriptionLabel')}
                  component='textarea'
                />
                <div className='invalid-feedback'>
                  {i18n.t('Description is required!')}
                </div>
              </div>

              {/* {selectedObjective === 10 && (
                <div className='d-flex justify-content-between'>
                  <div className='col-6'>
                    <DefaultField
                      name='domainAuthority'
                      type='number'
                      label={i18n.t('Minimum Domain Authority')}
                      placeholder={i18n.t('Insert') + ' DA'}
                    />
                  </div>
                </div>
              )}

              {selectedObjective === 30 && (
                <div className='d-flex justify-content-between'>
                  <div className='col-6'>
                    <label>{i18n.t('Activity Months')}</label>
                    <ReactSelectField
                      name='months'
                      fieldType='months'
                      options={monthsValues}
                      value={values.months}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.months}
                      touched={touched.months}
                    />
                  </div>
                </div>
              )} */}

              <div className='d-flex justify-content-between mt-4'>
                <div className='col-12'>
                  <p style={{ fontSize: '20px' }}>{i18n.t('Velocità campagna')}</p>
                  <RadioGroup
                    value={selectedVelocity}
                    onChange={e => {
                      setSelectedVelocity(e.target.value)
                      setFieldValue('velocity', e.target.value)
                    }}
                  >
                    <div className='velocityRadio'>
                      <Radio value={10} onChange={() => setSelectedVelocity(10)}>{i18n.t('standard')}</Radio>
                      <br />
                      <Radio value={20} onChange={() => setSelectedVelocity(20)}>{i18n.t('slow')}</Radio>
                      <br />
                      <Radio value={30} onChange={() => setSelectedVelocity(30)}>{i18n.t('controlled')}</Radio>
                    </div>
                  </RadioGroup>

                  {selectedVelocity === 20 && (
                    <div className='d-flex justify-content-between'>
                      <Notification title={i18n.t('The costs and timing of the campaign will increase by around 10%')} width='100%' color='#F2CA71' />
                    </div>
                  )}
                  {selectedVelocity === 30 && (
                    <div className='d-flex justify-content-between'>
                      <Notification title={i18n.t('The costs and timing of the campaign will increase by around 30%')} width='100%' color='#F48B8B' />
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex justify-content-between mt-3'>
                <div className='col-12'>
                  <p className='mb-0' style={{ fontSize: '20px' }}>{i18n.t('Article writing')}</p>
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='col-6'>
                  <RadioGroup
                    value={writing}
                    onChange={e => {
                      writingHandler(e.target.value)
                      setFieldValue('isApproval', e.target.value)
                    }}
                  >
                    <div className='d-flex mt-3'>
                      <Radio value={2} onChange={() => writingHandler(2)}>{i18n.t('Write Article')}</Radio>
                      <Radio value={1} onChange={() => writingHandler(1)}>{i18n.t('Commission Article')}</Radio>
                    </div>
                  </RadioGroup>
                </div>
                {writing === 1 && (
                  <div className='col-6'>
                    <ReactSelectField
                      name='itemQuality'
                      fieldType='itemQuality'
                      options={requestQualities}
                      value={values.itemQuality}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.itemQuality}
                      touched={touched.itemQuality}
                    />
                  </div>
                )}
              </div>

              <div className='text-center pt-5'>
                {!isSubmitted && (
                  <DefaultButton
                    type='submit'
                    style={{ backgrounddivor: BUTTON_COLOR, padding: '0 80px' }}
                  >
                    {i18n.t('Send campaign')}
                  </DefaultButton>
                )}
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default AdminCreateRequestForm
