import Yup from 'yup'
import { MIN_PASSWORD_LENGTH } from 'constants/index.js'
import i18n from 'i18n'

export const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t('Name has to be longer than 2 characters!'))
    .max(64, i18n.t('Name has to be shorter than 30 characters!')),
  // .required(i18n.t("Name is required!")),
  surname: Yup.string()
    .min(2, i18n.t('Surname has to be longer than 2 characters!'))
    .max(64, i18n.t('Surname has to be shorter than 30 characters!')),
  // .required(i18n.t("Surname is required!")),
  companyName: Yup.string(),
  // .required(i18n.t("Surname is required!")),
  email: Yup.string()
    .email(i18n.t('E-mail is not valid!'))
    .required(i18n.t('E-mail is required!')),
  password1: Yup.string()
    .min(MIN_PASSWORD_LENGTH, i18n.t('Password has to be longer than x characters', { count: MIN_PASSWORD_LENGTH }))
    .required(i18n.t('Password is required!')),
  password2: Yup.string()
    .required(i18n.t('Password confirmation is required!'))
    .min(MIN_PASSWORD_LENGTH, i18n.t('Password has to be longer than x characters', { count: MIN_PASSWORD_LENGTH })),
  privacy: Yup.bool()
    .oneOf([true], i18n.t('You must accept the Privacy Policy')),
  terms: Yup.bool()
    .oneOf([true], i18n.t('You must accept the Terms and Conditions'))
})
