import '../style.scss'

import DefaultButton from 'components/Forms/DefaultButton'
import DefaultCheckboxWithLink from 'components/Forms/DefaultCheckboxWithLink'
import DefaultField from 'components/Forms/DefaultField'
import { Form, Formik } from 'formik'
import i18n from 'i18n'
import React from 'react'

import { SignupSchema } from './validation'

// import { Checkbox } from 'antd'



const SignUpForm = props => {
  const initialValues = {
    name: '',
    surname: '',
    email: '',
    password1: '',
    password2: '',
    userTypology: props.userTypology,
    advertiserType: props.advertiserType || null,
    companyName: '',
    privacy: false,
    terms: false,
    lang: i18n.language
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={props.register}
    >
      {() => (
        <div className='wrapp-form'>
          <Form>
            {props.advertiserType === 1 || props.userTypology === 3 ? (
              <div className='row mb-2'>
                <div className='col-6'>
                  <DefaultField
                    name='name'
                    type='text'
                    label={i18n.t('Name')}
                    placeholder={i18n.t('Enter Name')}
                  />
                </div>
                <div className='col-6'>
                  <DefaultField
                    className='col-6'
                    name='surname'
                    type='text'
                    label={i18n.t('Surname')}
                    placeholder={i18n.t('Enter Surname')}
                  />
                </div>
              </div>
            ) : (
              <div className='mb-4'>
                <DefaultField
                  name='companyName'
                  type='companyName'
                  label={i18n.t('Company name')}
                  placeholder={i18n.t('Enter company name')}
                />
              </div>
            )}
            <div className='mb-4'>
              <DefaultField
                name='email'
                type='email'
                label='Email:'
                placeholder={i18n.t('Enter Email')}
              />
            </div>
            <div className='row mb-2'>
              <div className='col-6'>
                <DefaultField
                  name='password1'
                  type='password'
                  label='Password:'
                  placeholder={i18n.t('Enter Password')}
                />
              </div>
              <div className='col-6'>
                <DefaultField
                  name='password2'
                  type='password'
                  label={i18n.t('Password confirmation')}
                  placeholder={i18n.t('Repeat the password')}
                />
              </div>
            </div>
            <div className='mb-2'>
              <DefaultCheckboxWithLink
                name='privacy' label={i18n.t('I declare that I have read the {{tag_start}}privacy policy{{tag_end}}', {
                  tag_start: '<a href="https://www.bytekmarketing.it/privacy-policy/" target="_blank">',
                  tag_end: '</a>',
                  interpolation: { escapeValue: false }
                })}
              />
              <br />
              {props.userTypology === 3 && (
                <DefaultCheckboxWithLink
                  name='terms' label={i18n.t('I declare that I have read and accept the {{tag_start}}terms and conditions{{tag_end}}', {
                    tag_start: '<a href="https://www.relevanty.it/tec-advertiser.html" target="_blank">',
                    tag_end: '</a>',
                    interpolation: { escapeValue: false }
                  })}
                />
              )}
              {props.userTypology === 2 && (
                <DefaultCheckboxWithLink
                  name='terms' label={i18n.t('I declare that I have read and accept the {{tag_start}}terms and conditions{{tag_end}}', {
                    tag_start: '<a href="https://www.relevanty.it/tec-advertiser.html" target="_blank">',
                    tag_end: '</a>',
                    interpolation: { escapeValue: false }
                  })}
                />
              )}
            </div>
            <DefaultField
              name='lang'
              type='hidden'
            />
            <DefaultButton type='submit'>{i18n.t('Sign up')}</DefaultButton>
          </Form>
        </div>
      )}
    </Formik>
  )
}

export default SignUpForm
