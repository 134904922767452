import React from 'react'
import ReactDOM from 'react-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { HttpLink } from 'apollo-link-http'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'
// import { WebSocketLink } from "apollo-link-ws";
import { setContext } from 'apollo-link-context'
import rootReducer from './reducers'
// import "font-awesome/css/font-awesome.min.css";
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import App from './App'
import { TOKEN } from './constants'
import { loadData } from './utils'
import * as serviceWorker from './serviceWorker'
import './styles/index.scss'

import { I18nextProvider } from 'react-i18next'
// import { split } from 'apollo-link'
// import { getMainDefinition } from 'apollo-utilities'
import i18n from './i18n'
require('dotenv').config()

const cache = new InMemoryCache({
  dataIdFromObject: (o) => `${o.__typename}-${o.id}`
})

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql/`
})

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: `Bearer ${loadData(TOKEN)}`
  }
}))

// const link = split(({ query }) => {
//   const { kind, operation } = getMainDefinition(query);
//   return kind === "OperationDefinition" && operation === "subscription";
// }, authLink.concat(httpLink));

const client = new ApolloClient({
  cache: cache.restore(window.__APOLLO_STATE__ || {}),
  link: authLink.concat(httpLink)
})

const store = createStore(rootReducer)

store.subscribe(() => console.log(store.getState()))

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
