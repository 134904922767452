import "../styles/index.scss";

import { Button } from "antd";
import i18n from "i18n";
import React from "react";
import { moneyField } from "utils";

export const columnsProperties = [
  {
    title: () => <Button type="primary">Primary</Button>,
    // title: (filters, sortOrder) => ({<div>Title</div>}),
    dataIndex: "name",
    render: (item) => item || "-",
  },
  {
    title: "",
    dataIndex: "category",
    // render: item => i18n.t(item) || "-"
    render: (item) => item || "",
  },
  {
    title: "",
    dataIndex: "propertyType",
    render: (item) => i18n.t(item) || "-",
  },
  {
    title: "",
    dataIndex: "country",
    render: (item) => i18n.t(item) || "-",
  },
  {
    title: "",
    dataIndex: "language",
    render: (item) => item || "-",
  },
  {
    title: "",
    dataIndex: "eye",
    render: (item) => item || "-",
  },
];

export const columnsPropertiesHeader = (props) => [
  {
    title: () => "",
    dataIndex: "x",
    render: (item) => item || "-",
    width: 75,
  },
  {
    title: () => i18n.t("Property name"),
    dataIndex: "name",
    render: (item) => item || "-",
    width: 270,
  },
  {
    title: i18n.t("Category"),
    dataIndex: "category",
    render: (item) => item || "",
    width: 200,
  },
  {
    title: i18n.t("Type"),
    dataIndex: "propertyType",
    render: (item) => i18n.t(item) || "-",
    width: 130,
  },
  props.userType === 1
    ? {
        title: i18n.t("Price"),
        dataIndex: "offers",
        render: (item) => {
          if (item && item.length) {
            return moneyField.format(item[0].price);
          }
          return null;          
        },
        width: 130,
      }
    : {},
  props.userType === 1
    ? {
        title: i18n.t("Monthly Traffic"),
        dataIndex: "monthlyTraffic",
        render: (item) => i18n.t(item) || "-",
        width: 120,
      }
    : {
        title: i18n.t("Country"),
        dataIndex: "country",
        render: (item) => i18n.t(item) || "-",
        width: 120,
      },
  props.userType === 1
    ? {
        title: i18n.t("Language"),
        dataIndex: "language",
        render: (item) => item || "-",
        width: 70,
      }
    : {
        title: i18n.t("Language"),
        dataIndex: "language",
        render: (item) => item || "-",
        width: 70,
      },
  {
    title: "",
    dataIndex: "eye",
    render: (item) => item || "-",
    // width: 1
  },
];

export const languages = [
  // { label: 'Afrikaans', value: 'af' },
  // { label: 'Arabic', value: 'ar' },
  // { label: 'Asturian', value: 'ast' },
  // { label: 'Azerbaijani', value: 'az' },
  // { label: 'Bulgarian', value: 'bg' },
  // { label: 'Belarusian', value: 'be' },
  // { label: 'Bengali', value: 'bn' },
  // { label: 'Breton', value: 'br' },
  // { label: 'Bosnian', value: 'bs' },
  // { label: 'Catalan', value: 'ca' },
  // { label: 'Czech', value: 'cs' },
  // { label: 'Welsh', value: 'cy' },
  // { label: 'Danish', value: 'da' },
  { label: "German", value: "de" },
  // { label: 'Lower Sorbian', value: 'dsb' },
  // { label: 'Greek', value: 'el' },
  { label: "English", value: "en" },
  // { label: 'Australian English', value: 'en-au' },
  // { label: 'British English', value: 'en-gb' },
  // { label: 'Esperanto', value: 'eo' },
  { label: "Spanish", value: "es" },
  // { label: 'Argentinian Spanish', value: 'es-ar' },
  // { label: 'Colombian Spanish', value: 'es-co' },
  // { label: 'Mexican Spanish', value: 'es-mx' },
  // { label: 'Nicaraguan Spanish', value: 'es-ni' },
  // { label: 'Venezuelan Spanish', value: 'es-ve' },
  // { label: 'Estonian', value: 'et' },
  // { label: 'Basque', value: 'eu' },
  // { label: 'Persian', value: 'fa' },
  // { label: 'Finnish', value: 'fi' },
  { label: "French", value: "fr" },
  // { label: 'Frisian', value: 'fy' },
  // { label: 'Irish', value: 'ga' },
  // { label: 'Scottish Gaelic', value: 'gd' },
  // { label: 'Galician', value: 'gl' },
  // { label: 'Hebrew', value: 'he' },
  // { label: 'Hindi', value: 'hi' },
  // { label: 'Croatian', value: 'hr' },
  // { label: 'Upper Sorbian', value: 'hsb' },
  // { label: 'Hungarian', value: 'hu' },
  // { label: 'Armenian', value: 'hy' },
  // { label: 'Interlingua', value: 'ia' },
  // { label: 'Indonesian', value: 'id' },
  // { label: 'Ido', value: 'io' },
  // { label: 'Icelandic', value: 'is' },
  { label: "Italian", value: "it" },
  // { label: 'Japanese', value: 'ja' },
  // { label: 'Georgian', value: 'ka' },
  // { label: 'Kabyle', value: 'kab' },
  // { label: 'Kazakh', value: 'kk' },
  // { label: 'Khmer', value: 'km' },
  // { label: 'Kannada', value: 'kn' },
  // { label: 'Korean', value: 'ko' },
  // { label: 'Luxembourgish', value: 'lb' },
  // { label: 'Lithuanian', value: 'lt' },
  // { label: 'Latvian', value: 'lv' },
  // { label: 'Macedonian', value: 'mk' },
  // { label: 'Malayalam', value: 'ml' },
  // { label: 'Mongolian', value: 'mn' },
  // { label: 'Marathi', value: 'mr' },
  // { label: 'Burmese', value: 'my' },
  // { label: 'Norwegian Bokmål', value: 'nb' },
  // { label: 'Nepali', value: 'ne' },
  // { label: 'Dutch', value: 'nl' },
  // { label: 'Norwegian Nynorsk', value: 'nn' },
  // { label: 'Ossetic', value: 'os' },
  // { label: 'Punjabi', value: 'pa' },
  // { label: 'Polish', value: 'pl' },
  // { label: 'Portuguese', value: 'pt' },
  // { label: 'Brazilian Portuguese', value: 'pt-br' },
  // { label: 'Romanian', value: 'ro' },
  { label: "Russian", value: "ru" },
  // { label: 'Slovak', value: 'sk' },
  // { label: 'Slovenian', value: 'sl' },
  // { label: 'Albanian', value: 'sq' },
  // { label: 'Serbian', value: 'sr' },
  // { label: 'Serbian Latin', value: 'sr-latn' },
  // { label: 'Swedish', value: 'sv' },
  // { label: 'Swahili', value: 'sw' },
  // { label: 'Tamil', value: 'ta' },
  // { label: 'Telugu', value: 'te' },
  // { label: 'Thai', value: 'th' },
  // { label: 'Turkish', value: 'tr' },
  // { label: 'Tatar', value: 'tt' },
  // { label: 'Udmurt', value: 'udm' },
  // { label: 'Ukrainian', value: 'uk' },
  // { label: 'Urdu', value: 'ur' },
  // { label: 'Vietlabelse', value: 'vi' },
  // { label: 'Simplified Chinese', value: 'zh-hans' },
  // { label: 'Traditional Chinese', value: 'zh-hant' }
];

export const countries = [
  { label: "Afghanistan", value: "AF" },
  { label: "Åland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "AndorrA", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Cote D'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic Of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Italia", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea, Democratic People'S Republic of", value: "KP" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People'S Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory, Occupied", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "RWANDA", value: "RW" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia and Montenegro", value: "CS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Surilabel", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];

export const articleWriting = [
  { label: i18n.t("yes"), value: 1 },
  { label: "No", value: 2 },
];

export const categoryTypes = [
  { label: "Sport", value: 1 },
  { label: i18n.t("Cooking"), value: 2 },
  { label: i18n.t("News"), value: 3 },
];

export const propertyTypes = [
  { label: "Blog", value: 1 },
  { label: i18n.t("Journal"), value: 2 },
  { label: i18n.t("Instagram Account"), value: 3 },
  { label: i18n.t("Facebook Page"), value: 4 },
];

export const selectLinkTypes = [
  { label: i18n.t("Follow"), value: 1 },
  { label: i18n.t("No Follow"), value: 2 },
];

export const publicationTypes = [
  { label: i18n.t("Temporary"), value: 1 },
  { label: i18n.t("Final"), value: 2 },
];

export const requestQualities = [
  { label: i18n.t("high"), value: 1 },
  { label: i18n.t("medium"), value: 2 },
  { label: i18n.t("low"), value: 3 },
];

export const requestQualitiesOld = [
  { label: i18n.t("high_old"), value: 1 },
  { label: i18n.t("medium_old"), value: 2 },
  { label: i18n.t("low_old"), value: 3 },
];

export const budgetValues = [
  { label: i18n.t("1200,00 - 1600,00 €"), value: 1200 },
  { label: i18n.t("1600,00 - 2000,00 €"), value: 1600 },
  { label: i18n.t("2000,00 - 2500,00 €"), value: 2000 },
  { label: i18n.t("2500,00 - 3000,00 €"), value: 2500 },
  { label: i18n.t("3000,00+ €"), value: 3000 },
];

export const objectiveValues = [
  { label: i18n.t("fixed links"), value: 10 },
  { label: i18n.t("better awareness"), value: 20 },
  { label: i18n.t("better google"), value: 30 },
];

export const kindValues = [
  { label: i18n.t("Limited Number"), value: 1 },
  { label: i18n.t("Minimum Expense"), value: 2 },
  { label: i18n.t("Expire At"), value: 3 },
  { label: i18n.t("Categories"), value: 4 },
];

export const destinationValues = [
  { label: i18n.t("Managed platform"), value: 1 },
  { label: i18n.t("Self Service Platform"), value: 2 },
];

export const discountKindValues = [
  { label: i18n.t("Percent"), value: 1 },
  { label: i18n.t("Amount"), value: 2 },
];

export const articleWordsNumberValues = [
  { label: "400", value: 400 },
  { label: "600", value: 600 },
  { label: "800", value: 800 },
];

export const velocityValues = [
  { label: i18n.t("standard"), value: 10 },
  { label: i18n.t("slow"), value: 20 },
  { label: i18n.t("controlled"), value: 30 },
];

export const monthsValues = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
  { label: 13, value: 13 },
  { label: 14, value: 14 },
  { label: 15, value: 15 },
  { label: 16, value: 16 },
  { label: 17, value: 17 },
  { label: 18, value: 18 },
  { label: 19, value: 19 },
  { label: 20, value: 20 },
  { label: 21, value: 21 },
  { label: 22, value: 22 },
  { label: 23, value: 23 },
  { label: 24, value: 24 },
];

export const publicationType = [
  { label: i18n.t("Definitive"), value: 1 },
  { label: i18n.t("Temporary"), value: 2 },
];

export const jobOrderStatuses = [
  { label: i18n.t("to compose"), value: 1 },
  { label: i18n.t("sent"), value: 2 },
  { label: i18n.t("under revision"), value: 3 },
  { label: i18n.t("approved"), value: 4 },
];

export const requestsStatus = [
  { label: i18n.t("In progress"), value: 1 },
  { label: i18n.t("To approve"), value: 2 },
  { label: i18n.t("To pay"), value: 3 },
  { label: i18n.t("Active"), value: 4 },
  { label: i18n.t("Paid"), value: 5 },
];

export const selfServiceOptions = [
  { label: i18n.t("Do not show the site to clients"), value: false },
  { label: i18n.t("Make the site visible to clients"), value: true },
];

const postAndUrlApprovalStatus = {
  10: {
    label: i18n.t("Post to upload"),
    value: 10,
    color: "#898bd3",
    icon: "fas fa-exclamation-circle fa-lg ",
  },
  20: {
    label: i18n.t("Advertiser post under revision"),
    value: 20,
    color: "#f2ca71",
    icon: "fas fa-exclamation-circle fa-lg ",
  },
  30: {
    label: i18n.t("Advertiser post refused"),
    value: 30,
    color: "#f48b8b",
    icon: "fas fa-sync fa-lg ",
  },
  40: {
    label: i18n.t("Advertiser post approved"),
    value: 40,
    color: "#9fcb74",
    icon: "fa-check-circle far fa-lg ",
  },
  50: {
    label: i18n.t("Publisher post refused"),
    value: 50,
    color: "#f48b8b",
    icon: "fas fa-sync fa-lg ",
  },
  60: {
    label: i18n.t("Publisher post approved"),
    value: 60,
    color: "#9fcb74",
    icon: "fa-check-circle far fa-lg ",
  },
  70: {
    label: i18n.t("URL to upload"),
    value: 70,
    color: "#898bd3",
    icon: "fas fa-exclamation-circle fa-lg ",
  },
  80: {
    label: i18n.t("Url under revision"),
    value: 80,
    color: "#f2ca71",
    icon: "fas fa-exclamation-circle fa-lg ",
  },
  90: {
    label: i18n.t("Url refused"),
    value: 90,
    color: "#f48b8b",
    icon: "fas fa-sync fa-lg ",
  },
  100: {
    label: i18n.t("Url approved"),
    value: 100,
    color: "#9fcb74",
    icon: "fa-check-circle far fa-lg ",
  },
};

export const ChoicesPostAndUrlApprovalStatus = (value) => {
  return postAndUrlApprovalStatus[value];
};

export const labelFor = (fieldFrom, fieldTo, defaultValue) => {
  if (fieldFrom === 10) {
    if (fieldTo === "description") {
      return i18n.t("Description and Notes");
    }
    if (fieldTo === "descriptionLabel") {
      return i18n.t(
        "What are the characteristics of the links you need? Specify in detail your needs and any constraints in order to get a detailed quote immediately"
      );
    }
  }

  if (fieldFrom === 20) {
    if (fieldTo === "description") {
      return i18n.t("Brief and Note");
    }

    if (fieldTo === "descriptionLabel") {
      return i18n.t("Enter your strategic goals here");
    }
  }

  if (fieldFrom === 30) {
    if (fieldTo === "description") {
      return i18n.t("Objectives and Notes");
    }
    if (fieldTo === "descriptionLabel") {
      return i18n.t(
        "What are your goals? What is your current traffic and where do you want to go with the countryside?"
      );
    }
    if (fieldTo === "rangeBudget") {
      return i18n.t("Monthly Budget (euro)");
    }
    if (fieldTo === "targetUrl") {
      return i18n.t("Objective site");
    }
  }

  return defaultValue;
};

export const writingDescription = (item) => {
  switch (item) {
    case "yes":
      return "The article will be written by the client";
    case "no":
      return "The article will be written by ReleVanty";
    default:
      return "";
  }
};

export const selectOfferNames = [
  "Pubblicazione Articolo",
  "Pubblicazione + Scrittura Articolo",
  "Pubblicazione Articolo + Social",
  "Pubblicazione + Scrittura Articolo + Social",
  "Facebook",
  "Instagram",
  "Social",
  "Banner",
  "Pubblicazione Articolo + Social + Newsletter",
  "Pubblicazione + Scrittura Articolo + Social + Newsletter",
  "Pubblicazione Articolo + Newsletter",
  "Video",
  "Youtube",
  "Pacchetto Articoli",
  "Pop-up",
  "Inserimento link su articolo esistente",
  "Comunicato Stampa",
  "Link",
];

export const selectOfferNameTypes = selectOfferNames.map((name) => ({
  label: name,
  value: name,
}));

export const getLabelByValue = (value, data) =>
  data.find((obj) => obj.value === value).label;
