import Yup from 'yup'

const baseSchema = Yup.object().shape({
  name: Yup.string().required(),
  code: Yup.string().required(),
  destination: Yup.object().required(),
  kind: Yup.object().required(),
  enabled: Yup.boolean().required(),
  discountValue: Yup.number().required(),
  discountKind: Yup.object().required(),
})

export const limitedNumberSchema = baseSchema.shape({
  limitedNumber: Yup.number().required(),
})

export const minimumExpenseSchema = baseSchema.shape({
  minimumExpense: Yup.number().required(),
})

export const expireAtSchema = baseSchema.shape({
  expireAt: Yup.string().required(),
})

export const categoriesSchema = baseSchema.shape({
  categories: Yup.array().of(Yup.string()).required(),
})


export const kindSchema = {
  1: limitedNumberSchema,
  2: minimumExpenseSchema,
  3: expireAtSchema,
  4: categoriesSchema,
}