import React from "react";
import "./styles.css";
import classnames from "classnames";


const Checkbox = ({onChange, label, className, checked}) => {
  return (
    <div
      className={classnames('checkbox-wrapper', className)}
      onClick={onChange}
    >
      <div
        className={classnames('outer-square', {'unselected': !checked})}
      >
        <div
          className={classnames('inner-square', {'unselected-square': !checked })}
        />
      </div>
      <div className="helper-text">{label}</div>
    </div>
  );
}

export default Checkbox