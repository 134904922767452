import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import i18n from 'i18n'

const RespectiveUserInfo = (props) => {
  const { id, statusDisplay, company, address, pec, electronicInvoiceCode, vat, propertiesCount, offersCount } = props.data

  return (
    <div data-component='Dashboard/Client/ShowUsers/respectiveUserInfo'>
      <div className='content property border-0 p-2 mt-0' >
        {statusDisplay === 'Advertiser' && (
          <>
            <Row>
              {company && (
                <Col span={12}>
                  <span className='label'>{i18n.t('Business name')}</span>
                  <span className='content'>{company.name}</span>
                </Col>
              )}
              {address && (
                <Col span={12}>
                  <span className='label'>{i18n.t('Billing address')}</span>
                  <span className='content'>
                    {address.billingAddress}, {address.cap} {address.city} ({address.province}) - {address.state}
                  </span>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={12}>
                <span className='label'>{i18n.t('Certified Email')}</span>
                <span className='content'>{pec}</span>
              </Col>
              <Col span={12}>
                <span className='label'>{i18n.t('VAT Code')}</span>
                <span className='content'>{vat}</span>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <span className='label'>{i18n.t('Electronic Billing Code')}</span>
                <span className='content'>{electronicInvoiceCode}</span>
              </Col>
            </Row>
          </>
        )}
        {statusDisplay === 'Publisher' && (
          <Row>
            <Col span={12}>
              <span className='label'>{i18n.t('Properties Count')}</span>
              <span className='content'>{propertiesCount}</span>
            </Col>
            <Col span={12}>
              <span className='label'>{i18n.t('Offers Count')}</span>
              <span className='content'>{offersCount}</span>
            </Col>
          </Row>
        )}
      </div>
      <div className='text-center'>
        <Link to={`/edit-users/${id}`} className='btn btn-orange'>
          <i className='fas fa-edit mr-2' />
          {i18n.t('Edit')}
        </Link>
        <button className='btn btn-orange' onClick={() => { props.impersonate(id) }}>
          <i className='fas fa-user mr-2' />
          {i18n.t('Impersonate')}
        </button>
      </div>
    </div>
  )
}

export default RespectiveUserInfo
