import { Input } from "antd";
import React from "react";

const { TextArea } = Input;

const DefaultTextArea = ({ name, label, rows, placeholder, style }) => (
  <TextArea
    name={name}
    label={label}
    rows={rows}
    placeholder={placeholder}
    style={style}
  />
);

export const FormikTextArea = ({
  field,
  form: { touched, errors },
  label,
  ...props
}) => {
  return (
    <>
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <TextArea className="text-area" {...field} {...props} />
      {touched && errors ? (
        <div className="error">{errors[field.name]}</div>
      ) : null}
    </>
  );
};

export default DefaultTextArea;
