import Yup from 'yup'
import { MIN_PASSWORD_LENGTH } from '../../../constants'
import i18n from 'i18n'

export const ResetPasswordSchema = Yup.object().shape({
  newPassword1: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`
    )
    .required(i18n.t('Password is required!')),
  newPassword2: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`
    )
    .required(i18n.t('Password is required!'))
})
