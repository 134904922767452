import logo from 'assets/Logo-White.png'
import Copyright from 'components/Access/Copyright'
import SignUpForm from 'components/Access/Forms/SignUp'
import Title from 'components/Access/Title'
import * as path from 'constants/routes'
import i18n from 'i18n'
import { register } from 'mutations'
import React, { useState } from 'react'
import { graphql } from 'react-apollo'
import { Container } from 'reactstrap'
import swal from 'sweetalert'
import { saveData } from 'utils'

const SignUp = props => {
  const [state] = useState({ error: '' })
  const register = (values, { setErrors }) =>
    new Promise((resolve, reject) => {
      const { name, surname, email, password1, password2, userTypology, lang } = values
      props
        .register({
          variables: {
            name: name,
            surname: surname,
            email: email,
            password1: password1,
            password2: password2,
            userTypology: userTypology,
            lang: lang
          }
        })
        .then(response => {
          if (response.data.register.success) {
            const token = response.data.register.token

            saveData('token', token)
            swal('Conferma', 'Per favore, controlla la tua email per attivare l\'account!', 'warning')
            window.dataLayer.push({
              contentType: 'Registrazione',
              pageType: 'Registrazione',
              event: 'wpcf7successfulsubmit',
              eventCategory: 'account action',
              eventAction: 'registrazione',
              eventLabel: 'publisher'
            })
            props.history.push(path.SIGN_IN)
            resolve()
          } else {
            const errors = {}
            response.data.register.error.validationErrors.map(
              error => (errors[error.field] = error.messages.join(' '))
            )
            setErrors(errors)
            reject()
          }
        })
    })
  return (
    <div className='access-bg full-screen centered-content'>
      <Container>
        <Title
          descTop={i18n.t('Do you already have an account?')}
          image={logo}
          to={path.SIGN_IN}
          path={i18n.t('Login')}
          title={i18n.t('New Publisher User')}
        />
        <SignUpForm register={register} error={state.error} userTypology={3} />
        <Copyright />
      </Container>
    </div>
  )
}

export default graphql(register, { name: 'register' })(SignUp)
