import i18n from 'i18next'
import it from 'lang/it'
import en from 'lang/en'
import { matchPath } from 'react-router'

const { params } = matchPath(window.location.pathname, { path: '/(en|it)?*' })

i18n.init({
  // we init with resources
  resources: {
    en: {
      translations: en
    },

    it: {
      translations: it
    }
  },
  fallbackLng: 'en',

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    // escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
})

let lang = 'it'
if (params && params[0] !== undefined) {
  lang = params[0]
  window.localStorage.setItem('relevantyLng', lang)
} else {
  const userlang = window.localStorage.getItem('relevantyLng')
  if (userlang) {
    lang = userlang
  }
}

i18n.changeLanguage(lang)
// console.log('language switched to:', lang, params[0], userlang)

export default i18n
export { lang }
