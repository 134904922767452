import React from 'react'
import { generatePath } from 'react-router'
import logo from 'assets/Logo-White.png'
import companyIcon from 'assets/Company.png'
import freelanceIcon from 'assets/Freelance.png'
import Title from 'components/Access/Title'
import UserType from 'components/UserType'
import Copyright from 'components/Access/Copyright'
import Language from 'components/Access/Language'
import * as path from 'constants/routes'

import i18n, { lang } from 'i18n'

const AdvertiserType = props => {
  // const lang = params.lng
  return (
    <div className='main access-bg'>
      <Title
        descTop={i18n.t('Do you already have an account?')}
        descBottom={i18n.t('Select which user category you belong to')}
        image={logo}
        to={path.SIGN_IN}
        path={i18n.t('Login')}
        title={i18n.t('Select the type of user')}
      />
      <Language />
      <div className='card-wrapp company-adv'>
        <UserType
          image={companyIcon}
          type={i18n.t('Company')}
          path={generatePath(path.ADVERTISER_COMPANY_SIGN_UP, { lng: lang })}
        />
        <UserType
          image={freelanceIcon}
          type={i18n.t('Freelance')}
          path={generatePath(path.ADVERTISER_FREELANCE_SIGN_UP, { lng: lang })}
        />
      </div>
      <Copyright />
    </div>
  )
}

export default AdvertiserType
