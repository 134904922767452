import "./style.scss";

import { setCategories } from "actions/categories";
import Title from "components/Access/Title";
// import Filters from './Filters'
// import Table from './Table'
import DefaultButton from "components/Forms/DefaultButton";
// import { SetOrderBaseInfoSchema, SetOrderNoteInfoSchema } from './validation'
import DefaultField from "components/Forms/DefaultField";
// import Loader from 'components/Loader'
import RadioButton from "components/Forms/RadioButton";
import { BUTTON_COLOR } from "constants/colors";
import { Field, Form, Formik } from "formik";
import i18n from "i18n";
import { setBaseOrderInfo } from "mutations";
import { getJobOrder } from "queries/queries";
import React from "react";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { camelize } from "utils";

import { FormikTextArea } from "../../../Forms/DefaultTextArea";

// const initialState = {
//   showHeader: false,
//   search: {
//     categoryName: '',
//     sitesCount: ''
//   }
// }

// const reducer = (state, action) => {
//   switch (action.type) {
//     case 'SHOW_HEADER':
//       return {
//         ...state,
//         showHeader: true
//       }
//     case 'HIDDEN_HEADER':
//       return {
//         ...state,
//         showHeader: false,
//         selectedRowKeys: []
//       }
//     case 'SELECTED_ROW_KEYS':
//       return {
//         ...state,
//         selectedRowKeys: action.payload
//       }
//     case 'HANDLE_SET_VALUE':
//       return {
//         ...state,
//         search: {
//           ...state.search,
//           [action.eventType]: action.event
//         }
//       }
//     default:
//       return state
//   }
// }

const CategoriesSelection = (props) => {
  // const [state, dispatch] = useReducer(reducer, initialState)

  // const onSelectChange = (selectedRowKeys, selectedRows) => {
  //   const categories = selectedRows.filter((item) =>
  //     selectedRowKeys.includes(item.id)
  //   )
  //   props.setCategories(selectedRowKeys, categories)
  //   selectedRowKeys.length
  //     ? dispatch({
  //         type: 'SHOW_HEADER'
  //       })
  //     : dispatch({
  //       type: 'HIDDEN_HEADER'
  //     })
  // }

  // const rowSelection = {
  //   selectedRowKeys: props.selectedRowKeys,
  //   onChange: onSelectChange
  // }

  // const { showHeader } = state

  // const handleChangeValue = (type) => (event) => {
  //   dispatch({
  //     type: 'HANDLE_SET_VALUE',
  //     event: event.target.value,
  //     eventType: type
  //   })
  // }

  // const handleDataFilters = (data) =>
  //   data
  //     .map((item) => ({
  //       ...item,
  //       check: <i className='far fa-lg fa-check-circle text-success' />
  //     }))
  //     .filter((item) =>
  //       search.categoryName
  //         ? `${item.name} ${item.surname}`
  //           .toLowerCase()
  //           .includes(search.categoryName.toLowerCase())
  //         : item
  //     )
  //     .filter((item) =>
  //       search.sitesCount
  //         ? parseInt(`${item.sitesCount} ${item.sitesCount}`.toLowerCase()) >=
  //         parseInt(search.sitesCount.toLowerCase())
  //         : item
  //     )

  const handleBaseInfoSubmit = async (values, { setErrors }) => {
    const response = await props.setBaseOrderInfo({
      variables: {
        orderId: props.match.params.id,
        proposalName: values.proposalName,
        proposalDescription: values.proposalDescription,
      },
      refetchQueries: [
        { query: getJobOrder, variables: { id: props.match.params.id } },
      ],
    });
    if (response.data.setBaseOrderInfo.error.validationErrors.length) {
      const errors = {};
      response.data.setBaseOrderInfo.error.validationErrors.map((error) => {
        errors[camelize(error.field)] = error.messages.join(" ");
        return errors;
      });
      setErrors(errors);
    } else {
      props.location.state && props.location.state.edit
        ? props.history.push(`/orders/${props.match.params.id}`)
        : props.location.state
        ? props.history.push(props.location.state)
        : props.history.push(`/orders/${props.match.params.id}/save`);
    }
  };

  // const { search } = state

  return (
    <div className="categories-selection">
      <Title title={i18n.t("New Job Order")} style={{ color: "#555555" }} />
      <div className="row justify-content-md-center col-12">
        <Formik
          initialValues={{
            proposalName: props.getJobOrder.jobOrder.proposalName || "",
            proposalDescription:
              props.getJobOrder.jobOrder.proposalDescription || "",
          }}
          onSubmit={handleBaseInfoSubmit}
        >
          {({ values, handleChange, submitForm, errors }) => {
            if (Object.keys(errors).length) {
              window.scroll(0, 0);
            }
            return (
              <Form>
                <div className="table-container Filters">
                  <h4 className="seleziona-categoria pt-4">
                    {i18n.t("Set the order name and description")}
                  </h4>
                </div>
                <div className="row">
                  <div className="col-6">
                    <DefaultField
                      name="proposalName"
                      type="text"
                      label={i18n.t("Order name")}
                      placeholder={i18n.t("Enter Order name")}
                      disabled={
                        !!(props.location.state && props.location.state.edit)
                      }
                    />
                  </div>
                  <div className="col-6">
                    <div>
                      <label className="label-color">
                        {i18n.t("Order description")}:*
                      </label>
                      <Field
                        component={FormikTextArea}
                        name="proposalDescription"
                        style={{ paddingTop: 12 }}
                        rows={6}
                        placeholder={i18n.t("Enter Order description")}
                        disabled={
                          !!(props.location.state && props.location.state.edit)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center mt-3">
                    <DefaultButton
                      type="submit"
                      style={{
                        backgroundColor: BUTTON_COLOR,
                        width: "445.04px",
                      }}
                    >
                      {i18n.t("Proceed")}
                    </DefaultButton>
                  </div>
                </div>
                {/* <div className='table-container Filters'>
                  <h4 className='seleziona-categoria pt-4'>
                    {i18n.t('Select Publication Category')}
                  </h4>
                  <p className='categorie-selezionat'>
                    {i18n.t('Selected Categories')}:
                    <span className='pl-2'>{props.categories.length}</span>
                  </p>
                  <Filters handleChangeValue={handleChangeValue} />
                  <Query query={getPropertyCategories}>
                    {({ loading, error, data }) => {
                      if (loading) return <Loader />
                      if (error) return `Error! ${error.message}`
                      const items = handleDataFilters(data.propertyCategories)
                      return (
                        <>
                          <Table
                            rowSelection={rowSelection}
                            showHeader={showHeader}
                            items={items}
                          />
                          <div className='text-center pt-5'>
                            <DefaultButton
                              type='submit'
                              onClick={e =>
                                rowSelection.selectedRowKeys.length === 0 &&
                                e.preventDefault()}
                              style={{
                                backgroundColor: BUTTON_COLOR,
                                width: '445.04px'
                              }}
                            >
                              {i18n.t('Proceed')}
                            </DefaultButton>
                          </div>
                        </>
                      )
                    }}
                  </Query>
                </div> */}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRowKeys: state.categories.selectedRowKeys,
    categories: state.categories.categories,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCategories,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(graphql(setBaseOrderInfo, { name: "setBaseOrderInfo" })(CategoriesSelection));
