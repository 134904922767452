import React from 'react'
import { MDBFooter, Container } from 'mdbreact'

import './style.css'

const Footer = () => (
  <MDBFooter className='text-left font-small footer-bg darken-2'>
    <Container>
      <p className='footer-title mt-3'>
        Relevanty © {new Date().getFullYear()} |{' '}
        <a
          href='https://www.bytekmarketing.it/privacy-policy/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy
        </a>{' '}
        | Terms
      </p>
    </Container>
  </MDBFooter>
)

export default Footer
