import { Component } from 'react'
import { graphql } from 'react-apollo'

import swal from 'sweetalert'
import * as path from 'constants/routes'
import { checkToken } from 'mutations'

class CheckToken extends Component {
  async componentDidMount () {
    const response = await this.props.checkToken({
      variables: {
        userId: this.props.match.params.uid,
        confirmToken: this.props.match.params.confirmToken
      }
    })
    if (response.data.checkToken.success) {
      await swal(
        'Conferma',
        'Grazie per aver confermato la tua email!',
        'success'
      )
      this.props.history.push(path.DASHBOARD)
    } else {
      swal({
        title: 'Token is invalid!',
        icon: 'error'
      })
    }
  }

  render () {
    return null
  }
}

export default graphql(checkToken, { name: 'checkToken' })(CheckToken)
