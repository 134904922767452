import React from 'react'
import { Link } from 'react-router-dom'

import './style.scss'

const DefaultButton = ({ type, onClick, style, children, className }) => (
  <button className={`primary ${className}`} type={type} onClick={onClick} style={style}>
    {children}
  </button>
)

export const DefaultButtonIcon = ({ label, type, style, icon, action }) => (
  <button className={type} style={style} onClick={action}>
    {label}
    {icon}
  </button>
)

export const BackButton = props => {
  return (
    <button
      className={`BackButton ${props.className}`}
      onClick={() => props.history.goBack()}
    >
      <i
        className='fas fa-sign-out-alt'
        style={{ transform: 'scale(-1, 1)' }}
      />
      {props.text}
    </button>
  )
}

export const LinkBackButton = props => {
  return (
    <Link to={props.url}>
      <button
        className={`BackButton ${props.className}`}
      >
        <i
          className='fas fa-sign-out-alt'
          style={{ transform: 'scale(-1, 1)' }}
        />
        {props.text}
      </button>
    </Link>
  )
}

export const DefaultSmallButton = ({
  type,
  style,
  children,
  className,
  onClick,
  disabled
}) => (
  <button
    className={`smallButton ${className}`}
    type={type}
    style={style}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)

export default DefaultButton
