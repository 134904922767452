import React, { useState } from "react";

import { Formik, Form } from "formik";
import Title from "components/Access/Title";
import DefaultField from "../DefaultField";
import DefaultButton from "../DefaultButton";
import { kindSchema } from "./validation";
import { destinationValues, kindValues, discountKindValues } from "utils/api";
import { BUTTON_COLOR } from "constants/colors";
import i18n from "i18n";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "../NewComponents/Checkbox";
import Select from "../NewComponents/Select";

import "./styles.css";

export default function CreateCouponForm({
  handleCreate,
  isSubmitted,
  categories,
}) {
  const [kind, setKind] = useState(1);
  const submitForm = (values, actions) => {
    values.destination = values.destination.value;
    values.kind = values.kind.value;
    values.discountKind = values.discountKind.value;
    values.categories = values.categories
      ? [...values.categories].join(",")
      : null;
    return handleCreate(values, actions);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        destination: destinationValues[0],
        kind: kindValues[0],
        enabled: true,
        categories: [],
        discountValue: 0,
        discountKind: discountKindValues[0],
      }}
      validationSchema={kindSchema[kind]}
      onSubmit={submitForm}
    >
      {(props) => {
        const { values, touched, errors, setFieldValue } = props;
        return (
          <div>
            <Form>
              <div className="col-12">
                <Title
                  title={i18n.t("New Coupon")}
                  style={{ color: "#555555" }}
                />
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div className="col-6">
                  <DefaultField
                    name="name"
                    type="text"
                    label={i18n.t("Coupon Name")}
                    placeholder={i18n.t("e.g. Black Friday 2022")}
                    errors={errors.name}
                  />
                </div>
                <div className="col-6">
                  <DefaultField
                    name="code"
                    type="text"
                    label={i18n.t("Coupon ID")}
                    placeholder={i18n.t("e.g. BLACKFRIDAY22")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between mb-4">
                <div className="col-6">
                  <Select
                    options={destinationValues}
                    label={i18n.t("Coupon Description")}
                    containerClassName="p-0"
                    onChange={(value) => {
                      setFieldValue("destination", value);
                    }}
                    error={errors.destination}
                    touched={touched.destination}
                    value={values.destination}
                  />
                </div>
                <div className="col-6">
                  <Select
                    options={kindValues}
                    label={i18n.t("Kind of Coupon")}
                    containerClassName="p-0"
                    onChange={(value) => {
                      setKind(value && value.value ? value.value : null);
                      setFieldValue("kind", value);
                    }}
                    error={errors.kind}
                    touched={touched.kind}
                    value={values.kind}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="col-6">
                  <DefaultField
                    name="discountValue"
                    type="number"
                    label={i18n.t("Discount")}
                    placeholder={i18n.t("20% or 20€")}
                  />
                </div>
                <div className="col-6">
                  <Select
                    options={discountKindValues}
                    label={i18n.t("Kind of Discount")}
                    containerClassName="p-0"
                    onChange={(value) => {
                      setFieldValue("discountKind", value);
                    }}
                    error={errors.discountKind}
                    touched={touched.discountKind}
                    value={values.discountKind}
                  />
                </div>
              </div>
              {values.kind.value === 1 && (
                <div className="d-flex justify-content-between">
                  <div className="col-6">
                    <DefaultField
                      name="limitedNumber"
                      type="number"
                      label={i18n.t("Total Number of Coupons")}
                      placeholder={i18n.t("e.g. 200")}
                    />
                  </div>
                </div>
              )}
              {values.kind.value === 2 && (
                <div className="d-flex justify-content-between">
                  <div className="col-6">
                    <DefaultField
                      name="minimumExpense"
                      type="number"
                      label={i18n.t("Minimum Expense")}
                      placeholder={i18n.t("e.g. 500 €")}
                    />
                  </div>
                </div>
              )}
              {values.kind.value === 3 && (
                <div className="d-flex justify-content-between mb-3">
                  <div className="col-6">
                    <label className="select-label m-0">
                      {i18n.t("Date Of Coupon Expiring")}
                    </label>
                    <DatePicker
                      name="expireAt"
                      selected={values.expireAt}
                      onChange={(date) => setFieldValue("expireAt", date)}
                      showTimeSelect
                      dateFormat="Pp"
                      className="input w-100"
                      placeholderText="GG/MM/YYYY hh:mm"
                    />
                  </div>
                </div>
              )}

              {values.kind.value === 4 && (
                <div className="d-flex flex-wrap mb-3 col-12 mt-2">
                  {categories.map((name) => (
                    <div className="col-3 p-0" key={name}>
                      <Checkbox
                        checked={values.categories.includes(name)}
                        label={i18n.t(name)}
                        className="mb-2"
                        onChange={() => {
                          const categoriesSet = new Set(values.categories);
                          categoriesSet.has(name)
                            ? categoriesSet.delete(name)
                            : categoriesSet.add(name);
                          setFieldValue("categories", [...categoriesSet]);
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className=" col-12 d-flex align-items-center">
                <Checkbox
                  checked={values.enabled}
                  label={i18n.t("Enable Coupon")}
                  className="mt-2"
                  onChange={() => setFieldValue("enabled", !values.enabled)}
                />
              </div>
              <div className="text-center pt-5">
                {!isSubmitted && (
                  <DefaultButton
                    type="submit"
                    style={{ backgrounddivor: BUTTON_COLOR, padding: "0 80px" }}
                  >
                    {i18n.t("Create Coupon")}
                  </DefaultButton>
                )}
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
