import React from 'react'
import { generatePath } from 'react-router'

import { Formik, Form } from 'formik'
import DefaultField from 'components/Forms/DefaultField'
import DefaultButton from 'components/Forms/DefaultButton'
import DefaultCheckbox from 'components/Forms/DefaultCheckbox'

import { LoginSchema } from './validation'
import { Link } from 'react-router-dom'

import '../style.scss'
import i18n, { lang } from 'i18n'
import * as path from 'constants/routes'

const LoginForm = ({ login }) => (
  <Formik
    initialValues={{
      email: '',
      password: ''
    }}
    validationSchema={LoginSchema}
    onSubmit={login}
  >
    {() => (
      <div className='wrapp-form'>
        <Form>
          <DefaultField
            name='email'
            type='email'
            label='Email:'
            placeholder={i18n.t('username@email')}
          />
          <DefaultField
            name='password'
            type='password'
            label='Password:'
            placeholder={i18n.t('Enter Password')}
          />
          <div className='row'>
            <p className='lable-bold col-12'>
              <Link className='pull-left p-0' to={generatePath(path.FORGOT_PASSWORD, { lng: lang })}>
                {i18n.t('Forgot password?')}
              </Link>
              <Link className='pull-right p-0' to={generatePath(path.RESEND_ACTIVATION, { lng: lang })}>
                {i18n.t("Didn't receive activation email?")}
              </Link>
            </p>
          </div>
          <DefaultCheckbox label={i18n.t('Remember me')} />
          <DefaultButton type='submit'>{i18n.t('Login')}</DefaultButton>
        </Form>
      </div>
    )}
  </Formik>
)
export default LoginForm
