import React from 'react'
import { Query } from 'react-apollo'
import i18n from 'i18n'

import { Container } from 'mdbreact'
import ChildNavBar from 'components/NavBar/ChildNavBar'
import ShowPropertiesComponent from 'components/Dashboard/Client/ShowProperties'
import Loader from 'components/Loader'

import { getPropertyCategories } from 'queries/queries'
import { withAuth } from 'hocs/PrivateRoute'
import { onlyClient } from 'hocs/componentByRole'

const showProperties = props => {
  return (
    <>
      <ChildNavBar title={i18n.t('Properties')} />
      <Query query={getPropertyCategories}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />
          if (error) return `Error! ${error.message}`
          return (
            <div className='pt-5'>
              <Container>
                <ShowPropertiesComponent
                  {...props}
                  propertyCategories={data.propertyCategories}
                />
              </Container>
            </div>
          )
        }}
      </Query>
    </>
  )
}

export default withAuth(onlyClient(showProperties))
