import React from 'react'
import i18n from 'i18n'
import PropertiesComponent from 'components/Forms/CreateSelfServiceRequestForm/property'
import {BUTTON_COLOR} from 'constants/colors'
import DefaultButton from '../DefaultButton'
import 'components/Dashboard/Client/OrdersSaving/styles.scss'

const SetPropertyComponent = props => (
  <div data-component='client/src/components/Forms/CreateSelfServiceRequestForm/setProperty.js'>
    <button
      className='BackButton mt-5 p-0'
      onClick={() => props.handleSteps(2)}
    >
      <i
        className='fas fa-sign-out-alt'
        style={{transform: 'scale(-1, 1)'}}
      />
      {i18n.t('Come back')}
    </button>
    <div className='d-flex'>
      <h2 className='title text-left mt-4 mb-0 pull-left'>
        {i18n.t('Selected Categories')}
      </h2>
    </div>
    <div className='pt-5 save-orders-table'>
      <PropertiesComponent
        {...props}
        userType={1}
        jobOrder={props.jobOrder}
        request={props.request}
        propertyCategories={props.propertyCategories}
      />
    </div>
    <div className='text-center pt-5'>
      {props.selectedOffersKeysCount > 0 && (
        <DefaultButton
          type='submit'
          style={{backgrounddivor: BUTTON_COLOR, padding: '0 80px'}}
          onClick={props.handleCreateRequest}
        >
          {i18n.t('Select properties')}
        </DefaultButton>
      )}
    </div>
  </div>
)

export default SetPropertyComponent
