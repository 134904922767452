import React from 'react'

import { Input, Select, Checkbox } from 'antd'
import DefaultSelect from 'components/Forms/DefaultSelect'
import DefaultButton from 'components/Forms/DefaultButton'

import { languages, countries } from 'utils/api'
import i18n from 'i18n'
import './style.css'

const Option = Select.Option

const Filters = ({
  onChangeSearch,
  handleClickOnSelect,
  propertyCategories,
  handlePriceFromValue,
  handlePriceToValue,
  handleDAFromValue,
  handleDAToValue,
  onChangePublisher,
  handleSelfServiceValue,
  onFilterButtonClick,
  filters,
  fetching,
  fetchFile,
  foundItems
}) => (
  <>
    <div className='Filters row'>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Search for properties')}
          onBlur={event => onChangeSearch(event.target.value)}
          defaultValue={filters.search}
        />
      </div>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t('Category')}
          onChange={event => handleClickOnSelect('category', event)}
          defaultValue={filters.category}
        >
          <Option value='' key=''>
            <p />
          </Option>
          {propertyCategories.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => (
            <Option value={name} key={id}>
              {i18n.t(name)}
            </Option>
          ))}
        </DefaultSelect>
      </div>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t('Type')}
          onChange={event => handleClickOnSelect('type', event)}
          defaultValue={filters.type}
        >
          <Option value='' key=''>
            <p />
          </Option>
          <Option value='1' key='1'>
            Blog
          </Option>
          <Option value='2' key='2'>
            {i18n.t('Journal')}
          </Option>
          <Option value='3' key='3'>
            {i18n.t('Instagram Account')}
          </Option>
          <Option value='4' key='4'>
            {i18n.t('Facebook Page')}
          </Option>
        </DefaultSelect>
      </div>
      <div className='col-3'>
        <DefaultSelect
          placeholder='Country'
          onChange={event => handleClickOnSelect('country', event)}
          defaultValue={filters.country}
        >
          <Option value='' key=''>
            <p />
          </Option>
          {countries.map(country => (
            <Option key={country.value} value={country.value}>
              {country.label}
            </Option>
          ))}
        </DefaultSelect>
      </div>
    </div>
    <div className='Filters row'>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Price from')}
          onBlur={event => handlePriceFromValue(parseInt(event.target.value))}
          defaultValue={filters.priceFrom}
        />
      </div>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Price to')}
          onBlur={event => handlePriceToValue(parseInt(event.target.value))}
          defaultValue={filters.priceTo}
        />
      </div>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('DA from')}
          onBlur={event => handleDAFromValue(parseInt(event.target.value))}
          defaultValue={filters.daFrom}
        />
      </div>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('DA to')}
          onBlur={event => handleDAToValue(parseInt(event.target.value))}
          defaultValue={filters.daTo}
        />
      </div>
    </div>
    <div className='Filters row'>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t('Language')}
          onChange={event => handleClickOnSelect('language', event)}
          defaultValue={filters.language}
        >
          <Option value='' key=''>
            <p />
          </Option>
          {languages.map(language => (
            <Option key={language.value} value={language.value}>
              {language.label}
            </Option>
          ))}
        </DefaultSelect>
      </div>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Publisher email')}
          onBlur={event => onChangePublisher(event.target.value)}
          defaultValue={filters.publisher}
        />
      </div>
      <div className='col-6'>
        <div className='d-flex'>
          <Checkbox
            onChange={event => handleSelfServiceValue(event)}
            checked={filters.selfService === true}
            style={{ margin: '10px 0' }}
          >{i18n.t('Self Service')}
          </Checkbox>

          <DefaultButton
            type='submit'
            className='btn primary'
            onClick={onFilterButtonClick}
          >{i18n.t('Filter')}
          </DefaultButton>

          <button
            className='btn primary-outline'
            disabled={foundItems === 0 || fetching}
            onClick={fetchFile}
          >
            {fetching && (
              <>
                <i className='fas fa-circle-notch fa-spin mr-2' />
                {i18n.t('Loading')}
              </>
            )}
            {!fetching && (
              <>
                <i className='fas fa-file-excel mr-2' />
                {i18n.t('CSV download')}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  </>
)

export default Filters
