import React from 'react'
import i18n from '../../../i18n'
import './style.scss'

const Copyright = () => (
  <div className='copyrights'>
    <p>&#9400; {i18n.t('2019 ByTek Marketing Srl. All rights reserved')}</p>
  </div>
)

export default Copyright
