import AdminEditUserForm from 'components/Forms/AdminEditUserForm'
import { BackButton } from 'components/Forms/DefaultButton'
import Loader from 'components/Loader'
import ChildNavBar from 'components/NavBar/ChildNavBar'
import * as path from 'constants/routes'
import { withAuth } from 'hocs/PrivateRoute'
import { onlyClient } from 'hocs/componentByRole'
import i18n from 'i18n'
import { Container } from 'mdbreact'
import { adminEditUser } from 'mutations'
import { getUser } from 'queries/queries'
import React, { useState } from 'react'
import { Query, graphql } from 'react-apollo'

const AdminEditUser = props => {
  const [state] = useState({ error: '' })
  const handleAdminEditUser = (values, { setErrors }) =>
    new Promise((resolve, reject) => {
      const {
        isConfirmed,
        status,
        advertiserType,
      } = values
      const isConfirmedBoolean = isConfirmed === 'true'
      const statusValue = (typeof status === 'object' && status !== null && 'value' in status) ? status.value : status
      const advertiserTypeValue = (typeof advertiserType === 'object' && advertiserType !== null && 'value' in advertiserType) ? advertiserType.value : advertiserType
      
      props
        .adminEditUser({
          variables: {
            id: parseInt(props.match.params.userId),
            userData: {
              isConfirmed: isConfirmedBoolean,
              status: statusValue,
              advertiserType: statusValue === 2 ? advertiserTypeValue : null,
            }
          },
          refetchQueries: [
            {
              query: getUser,
              variables: { id: props.match.params.userId }
            }
          ]
        })
        .then(response => {
          if (response.data.adminEditUser.success) {
            props.history.push(
              path.SHOWUSERS
              // path.ADMIN_EDIT_USER.replace(':userId', parseInt(props.match.params.userId))
            )
            resolve()
          } else {
            const errors = {}
            response.data.adminEditUser.error.validationErrors.map(
              error => (errors[error.field] = error.messages.join(' '))
            )
            setErrors(errors)
            reject()
          }
        })
    })

  return (
    <>
      <ChildNavBar title={i18n.t('Users')} />
      <div className='pt-5'>
        <Container>
          <Query query={getUser} variables={{ id: props.match.params.userId }}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />
              if (error) return `Error! ${error.message}`
              return (
                <div className='row'>
                  <div className='col-12'>
                    <BackButton text={i18n.t('Come back')} history={props.history} />
                  </div>
                  <div className='col-6'>
                    <AdminEditUserForm
                      handleEditUser={handleAdminEditUser}
                      error={state.error}
                      errors={props}
                      defaultValues={data.user}
                    />
                  </div>
                </div>
              )
            }}
          </Query>
        </Container>
      </div>
    </>
  )
}

export default graphql(adminEditUser, { name: 'adminEditUser' })(
  withAuth(onlyClient(AdminEditUser))
)
