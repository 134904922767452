import React, { useState } from 'react'

import { Row, Col } from 'antd'

import i18n from 'i18n'
import OffersList from './OffersList'

const RespectivePropertyInfo = (props) => {
  const [showButton, setShowButton] = useState(false)

  const { url, publisherEmail, propertyType, country, offers, enableSelfService } = props.data

  return (
    <div className='content property border-0 p-2 mt-1'>
      <Row>
        <Col span={8}>
          <span className='label'>URL:</span>
          <span className='content'>
            <a href={url} rel='noopener noreferrer' target='_blank'>
              {url}
            </a>
          </span>
        </Col>
        <Col span={8}>
          <span className='label'>{i18n.t('Self Service')}:</span>
          <span className='content'>
            {enableSelfService ? i18n.t('Yes') : i18n.t('No')}
          </span>
        </Col>
        <Col span={8}>
          <span className='label'>{i18n.t('Publisher')}</span>
          <span className='content'>
            <a href={`mailto:${publisherEmail}`}>
              {publisherEmail}
            </a>
          </span>
        </Col>
      </Row>
      <div className='border-bottom mb-3'>
        <Row>
          <Col span={8}>
            <span className='label'>{i18n.t('Type')}</span>
            <span className='content'>{propertyType}</span>
          </Col>
          <Col span={8}>
            <span className='label'>{i18n.t('Publication')}:</span>
            <span className='content'>Definitiva</span>
          </Col>
          <Col span={8}>
            <span className='label'>{i18n.t('Country')}</span>
            <span className='content'>{country}</span>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24}>
          <span className='label'>Offerte Presenti</span>
        </Col>
      </Row>
      <OffersList
        data={offers}
        userType={1}
        setOffersKeys={props.setOffersKeys}
        property={props.data}
        showButton={showButton}
        setShowButton={setShowButton}
      />
    </div>
  )
}

export default RespectivePropertyInfo
