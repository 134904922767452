import Yup from 'yup'
import i18n from '../../../../i18n'
import { MIN_PASSWORD_LENGTH } from '../../../../constants/index'

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('E-mail is not valid!'))
    .required(i18n.t('E-mail is required!')),
  password: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `${i18n.t('Password has to be longer than')} ${MIN_PASSWORD_LENGTH} ${i18n.t('characters!')}`
    )
    .required(i18n.t('Password is required!'))
})
