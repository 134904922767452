import React from 'react'
import { Input, Select } from 'antd'
import DefaultSelect from 'components/Forms/DefaultSelect'
import i18n from 'i18n'

const Option = Select.Option

const Filters = ({
  searchPlaceholder,
  onChangeSearch,
  onChangeSelect,
  searchvalue
}) => (
  <div className='Filters row' style={{ padding: 0 }}>
    <div className='col-3'>
      <Input
        placeholder={searchPlaceholder}
        onChange={onChangeSearch}
        searchvalue={searchvalue}
      />
    </div>
    <div className='col-4'>
      <Input.Group compact className='row'>
        <Input
          type='number'
          className='text-center col-5 border-right-0'
          placeholder={i18n.t('Price from')}
          onChange={event => onChangeSelect('priceMinType', event.target.value)}
        />
        <Input
          className='text-center col-2 border-left-0'
          style={{
            pointerEvents: 'none',
            backgroundColor: '#fff'
          }}
          placeholder='~'
          disabled
        />
        <Input
          type='number'
          className='text-center col-5 border-left-0'
          placeholder={i18n.t('Price to')}
          onChange={event => onChangeSelect('priceMaxType', event.target.value)}
        />
      </Input.Group>
    </div>
    <div className='col-2'>
      <DefaultSelect
        placeholder={i18n.t('Type')}
        onChange={event => onChangeSelect('linkType', event)}
      >
        <Option value='' key=''>
          <p />
        </Option>
        <Option value='Follow'>{i18n.t('Follow')}</Option>
        <Option value='No Follow'>{i18n.t('No Follow')}</Option>
      </DefaultSelect>
    </div>
    <div className='col-3'>
      <DefaultSelect
        placeholder={i18n.t('Publication Type')}
        onChange={event => onChangeSelect('publicationType', event)}
      >
        <Option value='' key=''>
          <p />
        </Option>
        <Option value='Temporary'>{i18n.t('Temporary')}</Option>
        <Option value='Final'>{i18n.t('Final')}</Option>
      </DefaultSelect>
    </div>
  </div>
)

export default Filters
