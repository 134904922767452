import gql from "graphql-tag";

export const User = gql`
  query me {
    me {
      id
      email
      name
      surname
      userTypology
      userTypologyDisplay
      lang
      isConfirmed
      advertiserType
      propertiesCount
      requestsCount
      company {
        name
      }
      vat
      address {
        id
        billingAddress
        cap
        city
        province
        state
      }
      useElectronicInvoice
      electronicInvoiceCode
      pec
    }
  }
`;

export const getRequestsCard = gql`
  query getRequestCard($userId: Int, $statuses: [Int], $currentMonth: Boolean) {
    requestCard(
      userId: $userId
      statuses: $statuses
      currentMonth: $currentMonth
    ) {
      totalCount
      totalAmount
    }
  }
`;

export const getRequests = gql`
  query getRequests($userId: Int, $statuses: [Int], $limit: Int) {
    requests(userId: $userId, statuses: $statuses, limit: $limit) {
      id
      name
      objective
      topic
      targetUrl
      velocity
      months
      linkNumber
      rangeBudget
      language
      deadline
      description
      domainAuthority
      articleWriting
      status
      statusNum
      itemQuality
      paidAt
      isApproval
      unreadMessages
      isSelfService
      user {
        id
        name
        surname
        userName
      }
      joborder {
        id
        publisherApprovals
        advertiserApprovals
        couponCode
        discountedAmount
        totalWithMargin
        totalWithMarginWithVat
        additionalArticleMargin
        totalAmount
        amountWithVat
        vatMultiplier
        status
        statusNum
        invoice
        invoiceUrl
        onHold
        offers {
          id
          advertiserApproval
          postApproval
          urlApproval
          postApprovalNum
          urlApprovalNum
          postAndUrlApproval
          articleToBePublishedAt
          offerName
          offerUrl
          offerPrice
          offerArticleWriting
          articlePrice
          offer {
            id
            name
            description
            prohibitedArticles
            linkType
            publicationType
            property {
              id
              name
              description
              url
              monthlyTraffic
              articleWriting
              language
              country
              category {
                id
                name
              }
              propertyType
            }
            isTemporary
            expiresAt
            articleWriting
            timeForWriting
            language
          }
        }
      }
    }
  }
`;

export const getUsers = gql`
  query getUsers {
    users {
      id
      name
      surname
      email
      userTypology
      userName
      advertiserType
      company {
        id
        name
      }
    }
  }
`;

export const getUser = gql`
  query getUser($id: Int!) {
    user(id: $id) {
      id
      userName
      name
      surname
      email
      company {
        name
      }
      isConfirmed
      status
      advertiserType
    }
  }
`;

export const getPaginatedUsers = gql`
  query getPaginatedUsers(
    $page: Int
    $pageSize: Int
    $name: String
    $surname: String
    $email: String
    $isConfirmed: Boolean
    $userTypology: Int
    $advertiserType: Int
    $selfService: Boolean
  ) {
    paginatedUsers(
      page: $page
      pageSize: $pageSize
      name: $name
      surname: $surname
      email: $email
      isConfirmed: $isConfirmed
      userTypology: $userTypology
      advertiserType: $advertiserType
      selfService: $selfService
    ) {
      page
      pages
      pageSize
      total
      hasNext
      hasPrev
      objects {
        id
        userName
        email
        isConfirmed
        userTypologyDisplay
        advertiserTypeDisplay
        vat
        pec
        electronicInvoiceCode
        propertiesCount
        offersCount
        company {
          id
          name
        }
        address {
          billingAddress
          cap
          city
          province
          state
        }
      }
    }
  }
`;

export const getPaginatedCoupons = gql`
  query getPaginatedCoupons($page: Int, $pageSize: Int) {
    paginatedCoupons(page: $page, pageSize: $pageSize) {
      page
      pages
      pageSize
      total
      hasNext
      hasPrev
      objects {
        id
        name
        code
        destination
        enabled
        discountValue
        discountKind
        kind
      }
    }
  }
`;

export const getProperties = gql`
  query getProperties($userId: Int, $withOffer: Boolean) {
    properties(userId: $userId, withOffer: $withOffer) {
      id
      name
      description
      url
      monthlyTraffic
      articleWriting
      language
      country
      enableSelfService
      domainAuthorityRating
      categories {
        id
        name
      }
      propertyType
      offers {
        id
        name
        description
        price
        prohibitedArticles
        linkType
        publicationType
        isTemporary
        expiresAt
        articleWriting
        timeForWriting
        language
      }
    }
  }
`;

export const getPaginatedProperties = gql`
  query getPaginatedProperties(
    $page: Int
    $pageSize: Int
    $userId: Int
    $withOffer: Boolean
    $selfService: Boolean
    $search: String
    $priceFrom: Int
    $priceTo: Int
    $category: String
    $categories: [String]
    $type: String
    $country: String
    $language: String
    $daFrom: Int
    $daTo: Int
    $url: String
    $publisher: String
  ) {
    paginatedProperties(
      page: $page
      pageSize: $pageSize
      userId: $userId
      withOffer: $withOffer
      selfService: $selfService
      search: $search
      priceFrom: $priceFrom
      priceTo: $priceTo
      category: $category
      categories: $categories
      propertyType: $type
      country: $country
      language: $language
      daFrom: $daFrom
      daTo: $daTo
      url: $url
      publisher: $publisher
    ) {
      page
      pages
      pageSize
      total
      hasNext
      hasPrev
      objects {
        id
        name
        description
        url
        monthlyTraffic
        articleWriting
        language
        country
        enableSelfService
        domainAuthorityRating
        categories {
          id
          name
        }
        propertyType
        offers {
          id
          name
          description
          price
          prohibitedArticles
          linkType
          publicationType
          isTemporary
          expiresAt
          articleWriting
          timeForWriting
          language
        }
        cheapestOffers {
          id
          name
          description
          price
          prohibitedArticles
          linkType
          publicationType
          isTemporary
          expiresAt
          articleWriting
          timeForWriting
          language
        }
        publisherEmail
      }
    }
  }
`;

export const getSelfServiceProperties = gql`
  query getSelfServiceProperties($withOffer: Boolean) {
    selfServiceProperties(withOffer: $withOffer) {
      id
      name
      description
      url
      monthlyTraffic
      articleWriting
      language
      country
      domainAuthorityRating
      category {
        id
        name
      }
      propertyType
      offers {
        id
        name
        description
        price
        prohibitedArticles
        linkType
        publicationType
        isTemporary
        expiresAt
        articleWriting
        timeForWriting
        language
      }
    }
  }
`;

export const getProperty = gql`
  query getProperty($id: Int!) {
    property(id: $id) {
      id
      name
      description
      url
      monthlyTraffic
      articleWriting
      language
      country
      enableSelfService
      domainAuthorityRating
      categories {
        id
        name
      }
      propertyType
      offers {
        id
        name
        description
        price
        prohibitedArticles
        linkType
        publicationType
        isTemporary
        expiresAt
        articleWriting
        timeForWriting
        language
      }
    }
  }
`;

export const getOffer = gql`
  query getOffer($id: Int!) {
    offer(id: $id) {
      id
      name
      description
      price
      prohibitedArticles
      linkType
      publicationType
      isTemporary
      expiresAt
      articleWriting
      timeForWriting
      language
    }
  }
`;

export const getOffers = gql`
  query getOffers($ids: [Int]!) {
    offers(ids: $ids) {
      id
      name
      description
      price
      prohibitedArticles
      linkType
      publicationType
      isTemporary
      expiresAt
      articleWriting
      timeForWriting
      language
    }
  }
`;

export const getJobOrders = gql`
  query getJobOrders($statuses: [Int], $limit: Int) {
    jobOrders(statuses: $statuses, limit: $limit) {
      id
      status
      statusNum
      publisherApprovals
      advertiserApprovals
      total
      totalWithMargin
      totalWithMarginWithVat
      additionalArticleMargin
      totalAmount
      amountWithVat
      vatMultiplier
      invoice
      invoiceUrl
      isSelfService
      unreadMessages
      couponCode
      discountedAmount
      offers {
        id
        uploadedFile
        uploadedUrl
        advertiserApproval
        publisherApproval
        postApproval
        urlApproval
        postApprovalNum
        urlApprovalNum
        postAndUrlApproval
        articleToBePublishedAt
        marginValue
        priceWithMargin
        offerName
        offerUrl
        offerPrice
        offerArticleWriting
        articlePrice
        offer {
          id
          name
          description
          price
          linkType
          publicationType
          prohibitedArticles
          propertyName
          isTemporary
          expiresAt
          articleWriting
          timeForWriting
          language
        }
      }
      request {
        id
        name
        objective
        topic
        targetUrl
        velocity
        months
        linkNumber
        rangeBudget
        language
        deadline
        description
        domainAuthority
        articleWriting
        itemQuality
        paidAt
        isApproval
        status
        statusNum
        isSelfService
        user {
          id
          name
          surname
          userName
        }
      }
    }
  }
`;

export const getFilteredJobOrders = gql`
  query getFilteredJobOrders($advertiserId: Int, $statusNum: Int, $limit: Int) {
    filteredJobOrders(
      statusNum: $statusNum
      advertiserId: $advertiserId
      limit: $limit
    ) {
      id
      advertiserId
      status
      statusNum
      publisherApprovals
      advertiserApprovals
      total
      totalWithMargin
      totalWithMarginWithVat
      additionalArticleMargin
      totalAmount
      amountWithVat
      vatMultiplier
      invoice
      invoiceUrl
      request {
        id
        name
        objective
        topic
        targetUrl
        velocity
        months
        linkNumber
        rangeBudget
        language
        deadline
        isSelfService
      }
    }
  }
`;

export const getPaginatedJobOrders = gql`
  query getPaginatedJobOrders(
    $page: Int
    $pageSize: Int
    $statuses: [Int]
    $jobOrderId: String
    $clientName: String
    $jobOrderName: String
    $isSelfService: Boolean
    $owner: ID
    $isOnHold: Boolean
    $isDraft: Boolean
    $inPayment: Boolean
    $requestName: String
  ) {
    paginatedJobOrders(
      page: $page
      pageSize: $pageSize
      statuses: $statuses
      jobOrderId: $jobOrderId
      clientName: $clientName
      jobOrderName: $jobOrderName
      isSelfService: $isSelfService
      owner: $owner
      isOnHold: $isOnHold
      isDraft: $isDraft
      inPayment: $inPayment
      requestName: $requestName
    ) {
      page
      pages
      pageSize
      total
      hasNext
      hasPrev
      objects {
        id
        status
        statusNum
        publisherApprovals
        advertiserApprovals
        total
        totalWithMargin
        totalWithMarginWithVat
        additionalArticleMargin
        totalAmount
        amountWithVat
        vatMultiplier
        invoice
        invoiceUrl
        isSelfService
        unreadMessages
        couponCode
        discountedAmount
        owner {
          id
          userName
        }
        offers {
          id
          uploadedFile
          uploadedUrl
          advertiserApproval
          publisherApproval
          postApproval
          urlApproval
          postApprovalNum
          urlApprovalNum
          postAndUrlApproval
          articleToBePublishedAt
          marginValue
          priceWithMargin
          offerName
          offerUrl
          offerPrice
          offerArticleWriting
          articlePrice
          offer {
            id
            name
            description
            price
            linkType
            publicationType
            prohibitedArticles
            propertyName
            isTemporary
            expiresAt
            articleWriting
            timeForWriting
            language
          }
        }
        request {
          id
          name
          objective
          topic
          targetUrl
          velocity
          months
          linkNumber
          rangeBudget
          language
          deadline
          description
          domainAuthority
          articleWriting
          itemQuality
          paidAt
          isApproval
          status
          statusNum
          isSelfService
          user {
            id
            name
            surname
            userName
          }
        }
      }
    }
  }
`;

export const getJobOrder = gql`
  query getJobOrder($id: Int!) {
    jobOrder(id: $id) {
      id
      status
      statusNum
      publisherApprovals
      advertiserApprovals
      proposalName
      proposalDescription
      isEnterprise
      invoice
      invoiceUrl
      isSelfService
      advertiserId
      couponCode
      discountedAmount
      request {
        id
        name
        objective
        topic
        targetUrl
        velocity
        months
        linkNumber
        rangeBudget
        language
        deadline
        description
        domainAuthority
        articleWriting
        itemQuality
        paidAt
        isApproval
        status
        statusNum
        bankTransfer
        bankTransferUrl
        isSelfService
        budget
        categories {
          id
          name
        }
        user {
          id
          name
          surname
          userName
          email
        }
      }
      margin
      marginId
      marginType
      marginTypeId
      marginValue
      total
      totalWithMargin
      totalWithMarginWithVat
      additionalArticleMargin
      totalAmount
      amountWithVat
      vatMultiplier
      onHold
      owner {
        id
        userName
      }
      offers {
        proposal {
          id
          invoice
          invoiceUrl
          status
          statusNum
          publisher {
            id
            email
          }
        }
        id
        url
        advertiserApproval
        publisherApproval
        postApproval
        urlApproval
        postApprovalNum
        urlApprovalNum
        postAndUrlApproval
        articleToBePublishedAt
        marginValue
        priceWithMargin
        file
        fileUrl
        cover
        coverUrl
        offerName
        offerUrl
        offerPrice
        offerArticleWriting
        articlePrice
        offer {
          id
          name
          description
          price
          prohibitedArticles
          linkType
          publicationType
          property {
            id
            name
            description
            url
            monthlyTraffic
            articleWriting
            language
            country
            category {
              id
              name
            }
            user {
              email
            }
            propertyType
          }
          isTemporary
          expiresAt
          articleWriting
          timeForWriting
          language
        }
        article {
          id
          wordNumber
          quality
          subject
          publicationType
          follow
          anchorText
          targetUrl
          deadline
        }
      }
    }
  }
`;

export const getRequest = gql`
  query getRequest($requestId: Int!) {
    request(requestId: $requestId) {
      id
      name
      objective
      topic
      targetUrl
      velocity
      months
      linkNumber
      rangeBudget
      language
      deadline
      description
      domainAuthority
      articleWriting
      itemQuality
      paidAt
      isApproval
      isSelfService
      status
      statusNum
      bankTransfer
      bankTransferUrl
      isSelfService
      user {
        id
        name
        surname
        userName
      }
      joborder {
        id
        totalWithMargin
        totalWithMarginWithVat
        additionalArticleMargin
        totalAmount
        amountWithVat
        vatMultiplier
        isSelfService
        advertiserId
        couponCode
        discountedAmount
        owner {
          id
          userName
        }
        offers {
          id
          advertiserApproval
          publisherApproval
          postApproval
          urlApproval
          postApprovalNum
          urlApprovalNum
          postAndUrlApproval
          articleToBePublishedAt
          file
          fileUrl
          cover
          coverUrl
          url
          isSelfService
          offerName
          offerUrl
          offerPrice
          offerArticleWriting
          articlePrice
          offer {
            id
            name
            description
            prohibitedArticles
            linkType
            publicationType
            property {
              id
              name
              description
              url
              monthlyTraffic
              articleWriting
              language
              country
              category {
                id
                name
              }
              propertyType
            }
            isTemporary
            expiresAt
            articleWriting
            timeForWriting
            language
          }
          article {
            id
            wordNumber
            quality
            subject
            publicationType
            follow
            anchorText
            targetUrl
            deadline
          }
        }
        request {
          id
          name
          objective
          topic
          targetUrl
          velocity
          months
          linkNumber
          rangeBudget
          language
          deadline
          description
          domainAuthority
          articleWriting
          itemQuality
          paidAt
          isApproval
          status
          statusNum
          isSelfService
          user {
            id
            name
            surname
            userName
          }
          payment {
            id
            amount
            amountWithVat
            vatMultiplier
            last4
            paid
            paidAt
          }
        }
      }
    }
  }
`;

export const getSelfServiceRequest = gql`
  query getSelfServiceRequest($requestId: Int!) {
    selfServiceRequest(requestId: $requestId) {
      id
      name
      objective
      selectedObjective
      topic
      targetUrl
      velocity
      months
      linkNumber
      rangeBudget
      language
      deadline
      description
      domainAuthority
      articleWriting
      itemQuality
      paidAt
      averageNumberWords
      isApproval
      isSelfService
      status
      jobOrder {
        id
        totalWithMargin
        totalWithMarginWithVat
        additionalArticleMargin
        totalAmount
        amountWithVat
        vatMultiplier
        isSelfService
        offers {
          id
          advertiserApproval
          publisherApproval
          postApproval
          urlApproval
          postApprovalNum
          urlApprovalNum
          postAndUrlApproval
          articleToBePublishedAt
          file
          fileUrl
          cover
          coverUrl
          url
          isSelfService
          offerName
          offerUrl
          offerPrice
          offerArticleWriting
          articlePrice
          offer {
            id
            name
            description
            prohibitedArticles
            linkType
            publicationType
            property {
              id
              name
              description
              url
              monthlyTraffic
              articleWriting
              language
              country
              category {
                id
                name
              }
              propertyType
            }
            isTemporary
            expiresAt
            articleWriting
            timeForWriting
            language
          }
          article {
            id
            wordNumber
            quality
            subject
            publicationType
            follow
            anchorText
            targetUrl
            deadline
          }
        }
      }
    }
  }
`;

export const getPropertyCategories = gql`
  query getPropertyCategories($hasProperties: Boolean) {
    propertyCategories(hasProperties: $hasProperties) {
      id
      name
      sitesCount
    }
  }
`;

export const getPropertyCategoriesOptions = gql`
  query getPropertyCategoriesOptions($hasProperties: Boolean) {
    propertyCategoriesOptions(hasProperties: $hasProperties) {
      value
      label
    }
  }
`;

export const getSelfServicePropertyCategories = gql`
  query getPropertyCategories {
    propertyCategories {
      id
      name
      sitesCount
    }
  }
`;

export const getNotifications = gql`
  query getNotifications($userId: Int!) {
    notifications(userId: $userId) {
      id
      publisherNotificationType
      advertiserNotificationType
      clientNotificationType
      publisherNotificationTypeNum
      advertiserNotificationTypeNum
      clientNotificationTypeNum
      description
      offer {
        id
        property {
          id
          name
        }
      }
      jobOrder {
        id
        status
        request {
          id
          name
          description
          status
        }
      }
      proposal {
        id
        name
        description
        status
      }
      dateCreated
      status
    }
  }
`;

export const getNotificationsCount = gql`
  query getNotificationsCount($userId: Int!) {
    notificationsCount(userId: $userId) {
      count
    }
  }
`;

export const getProposals = gql`
  query getProposals($publisherId: Int!, $statuses: [Int], $limit: Int) {
    proposals(publisherId: $publisherId, statuses: $statuses, limit: $limit) {
      id
      name
      description
      expiry
      total
      file
      fileUrl
      status
      statusNum
      invoicedAt
      createdAt
      publisher {
        id
        name
      }
      advertiser {
        id
        name
        userName
      }
      order {
        id
        request {
          id
          name
        }
      }
      offers {
        id
        advertiserApproval
        publisherApproval
        postApproval
        urlApproval
        postApprovalNum
        urlApprovalNum
        postAndUrlApproval
        articleToBePublishedAt
        offerName
        offerUrl
        offerPrice
        offerArticleWriting
        articlePrice
        offer {
          id
          name
          description
          price
          prohibitedArticles
          linkType
          publicationType
          isTemporary
          expiresAt
          articleWriting
          timeForWriting
          language
        }
      }
    }
  }
`;

export const getProposalCard = gql`
  query getProposalCard($statuses: [Int], $publisherId: Int) {
    proposalCard(statuses: $statuses, publisherId: $publisherId) {
      totalAmount
      totalCount
    }
  }
`;

export const getProposalsWithStatus = gql`
  query getProposalsWithStatus($status: Int, $publisherId: Int, $limit: Int) {
    proposalsWithStatus(
      status: $status
      publisherId: $publisherId
      limit: $limit
    ) {
      id
      name
      description
      expiry
      total
      file
      fileUrl
      status
      statusNum
      paidAt
      publisher {
        id
        name
      }
      advertiser {
        id
        name
        userName
      }
      offers {
        id
        advertiserApproval
        publisherApproval
        postApproval
        urlApproval
        postApprovalNum
        urlApprovalNum
        postAndUrlApproval
        articleToBePublishedAt
        offerName
        offerUrl
        offerPrice
        offerArticleWriting
        articlePrice
        jobOrder {
          id
          request {
            id
            name
            description
            status
            deadline
          }
        }
        offer {
          id
          name
          description
          price
          prohibitedArticles
          linkType
          publicationType
          isTemporary
          expiresAt
          articleWriting
          timeForWriting
          language
        }
      }
    }
  }
`;

export const getPaginatedPublisherInvoices = gql`
  query getPaginatedPublisherInvoices(
    $page: Int
    $pageSize: Int
    $statuses: [Int]
    $proposalId: String
    $publisherName: String
    $proposalName: String
    $invoiceDateFrom: String
    $invoiceDateTo: String
  ) {
    paginatedPublisherInvoices(
      page: $page
      pageSize: $pageSize
      statuses: $statuses
      proposalId: $proposalId
      publisherName: $publisherName
      proposalName: $proposalName
      invoiceDateFrom: $invoiceDateFrom
      invoiceDateTo: $invoiceDateTo
    ) {
      page
      pages
      pageSize
      total
      hasNext
      hasPrev
      objects {
        id
        name
        publisher
        total
        status
        status
        statusNum
        file
        fileUrl
        invoicedAt
      }
    }
  }
`;

export const getPublisherInvoices = gql`
  query getPublisherInvoices($statuses: [Int], $limit: Int) {
    publisherInvoices(statuses: $statuses, limit: $limit) {
      id
      name
      publisher
      total
      status
      status
      statusNum
      file
      fileUrl
      invoicedAt
    }
  }
`;

export const getAllProposals = gql`
  query getAllProposals($statuses: [Int], $limit: Int) {
    allProposals(statuses: $statuses, limit: $limit) {
      id
      name
      description
      expiry
      total
      file
      fileUrl
      status
      statusNum
      publisher {
        id
        name
      }
      advertiser {
        id
        name
      }
      offers {
        id
        advertiserApproval
        publisherApproval
        postApproval
        urlApproval
        postApprovalNum
        urlApprovalNum
        postAndUrlApproval
        articleToBePublishedAt
        offerName
        offerUrl
        offerPrice
        offerArticleWriting
        articlePrice
        offer {
          id
          name
          description
          price
          prohibitedArticles
          linkType
          publicationType
          isTemporary
          expiresAt
          articleWriting
          timeForWriting
          language
        }
      }
    }
  }
`;

export const getProposal = gql`
  query getProposal($id: Int!) {
    proposal(id: $id) {
      id
      name
      description
      expiry
      total
      file
      fileUrl
      status
      statusNum
      articleWriting
      invoicedAt
      createdAt
      publisher {
        id
        name
        userName
      }
      advertiser {
        id
        name
      }
      order {
        request {
          name
        }
      }
      offers {
        id
        advertiserApproval
        publisherApproval
        postApproval
        urlApproval
        postApprovalNum
        urlApprovalNum
        postAndUrlApproval
        articleToBePublishedAt
        createdAt
        file
        fileUrl
        cover
        coverUrl
        url
        offerName
        offerUrl
        offerPrice
        offerArticleWriting
        articlePrice
        offer {
          id
          name
          description
          price
          prohibitedArticles
          linkType
          publicationType
          property {
            id
            name
            description
            url
            monthlyTraffic
            articleWriting
            language
            country
            category {
              id
              name
            }
            propertyType
          }
          isTemporary
          expiresAt
          articleWriting
          timeForWriting
          language
        }
        jobOrder {
          id
          request {
            id
            name
            description
            status
            deadline
            linkNumber
          }
        }
        article {
          id
          wordNumber
          quality
          subject
          publicationType
          follow
          anchorText
          targetUrl
          deadline
        }
      }
    }
  }
`;

export const getRoom = gql`
  query getRoom($room_id: Int) {
    room(id: $room_id) {
      id
      users {
        id
        name
      }
      jobOrder {
        id
        owner {
          id
          userName
        }
      }
      messages(room: $room_id) {
        id
        text
        file
        fileUrl
        seen
        sender {
          id
          name
          userTypology
        }
        time
      }
    }
  }
`;

export const getClients = gql`
  query getClients {
    clients {
      userName
      name
      id
    }
  }
`;

export const getAdvertisers = gql`
  query getAdvertisers {
    advertisers {
      userName
      id
      email
    }
  }
`;

export const getUserRooms = gql`
  query getUserRooms(
    $page: Int
    $pageSize: Int
    $userId: Int!
    $jobOrderId: String
    $requestId: String
  ) {
    userRooms(
      page: $page
      pageSize: $pageSize
      userId: $userId
      jobOrderId: $jobOrderId
      requestId: $requestId
    ) {
      page
      pages
      pageSize
      total
      hasNext
      hasPrev
      objects {
        id
        jobOrder {
          id
          request {
            id
            name
            user {
              id
              userName
            }
          }
        }
        unseenMessages
      }
    }
  }
`;

export const getUserUnreadMessages = gql`
  query getUserUnreadMessages($userId: Int!) {
    userUnreadMessages(userId: $userId) {
      count
    }
  }
`;
