import Yup from 'yup'
import i18n from 'i18n'

export const CreatePropertySchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('Name is required!')),
  description: Yup.string().required(i18n.t('Description is required!')),
  url: Yup.string()
    .url(i18n.t('Url is not valid!'))
    .required(i18n.t('Url is required!')),
  language: Yup.string().required(i18n.t('Language is required!')),
  monthlyTraffic: Yup.number()
    .integer(i18n.t('Monthly traffic is required!'))
    .required(i18n.t('Monthly traffic is required!')),
  country: Yup.string().required(i18n.t('Country is required!')),
  // category: Yup.string().required(i18n.t('Category is required!')),
  type: Yup.string().required(i18n.t('Type is required!')),
  articleWriting: Yup.string().required(i18n.t('Article writing is required!'))
})
