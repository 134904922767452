import React from 'react'

import { Field } from 'formik'
import { ReactstrapInput } from 'reactstrap-formik'

import './style.css'

const DefaultField = ({
  label,
  name,
  type,
  placeholder,
  component,
  errors,
  touched,
  disabled = false,
  style
}) => {
  return label ? (
    <Field
      name={name}
      type={type}
      disabled={disabled}
      component={!component ? ReactstrapInput : component}
      className='input'
      label={label}
      placeholder={placeholder}
      style={style}
    />
  ) : (
    <>
      <Field
        name={name}
        type={type}
        disabled={disabled}
        className={`input form-control ${errors &&
          touched &&
          'is-invalid'}`}
        placeholder={placeholder}
        style={style}
      />
      {!!errors && touched && (
        <div className='invalid-feedback d-block'>{errors}</div>
      )}
    </>
  )
}

export default React.memo(DefaultField)
