import React from 'react'

import { Select } from 'antd'

const DefaultSelect = ({ placeholder, defaultValue, onChange, children, mode='default' }) => (
  <Select
    placeholder={placeholder}
    onChange={onChange}
    defaultValue={defaultValue}
    allowClear
    mode={mode}
  >
    {children}
  </Select>
)

export default DefaultSelect
