import React, {useState} from 'react'
import {Field, Form, Formik} from 'formik'
import Title from '../../../components/Access/Title'
import {Col, DatePicker, Row} from 'antd'
import DefaultField from '../DefaultField'
import DefaultButton from '../DefaultButton'
import ReactSelectField from '../ReactSelectField'
import RadioButton from '../RadioButton'
import moment from 'moment'
import 'react-select/dist/react-select.css'
import {BUTTON_COLOR} from 'constants/colors'
import {EditOfferSchema} from './validation'
import {publicationTypes, selectLinkTypes, selectOfferNames, selectOfferNameTypes} from 'utils/api'
import i18n from 'i18n'

const offersWithArticleWriting = selectOfferNames.filter(name => name.includes('Scrittura Articolo'))


const EditOfferForm = props => {
  const [offer] = useState(props.defaultValues)
  const linkType =
    selectLinkTypes.find(item => item.label === i18n.t(offer.linkType)) || ''

  const nameType =
      selectOfferNameTypes.find(item => item.label === i18n.t(offer.name)) || ''

  const publicationType =
    publicationTypes.find(
      item => item.label === i18n.t(offer.publicationType)
    ) || ''
  const dateFormat = {
    human: 'DD/MM/YYYY',
    db: 'YYYY-MM-DD'
  }


  return (
    <Formik
      initialValues={{
        name: nameType.value,
        description: offer.description,
        price: offer.price,
        linkType: linkType.value,
        publicationType: publicationType.value,
        prohibitedArticles: offer.prohibitedArticles,
        isTemporary: offer.isTemporary.toString(),
        expiresAt: offer.expiresAt,
        articleWriting: offer.articleWriting.toString(),
        timeForWriting: offer.timeForWriting,
        language: offer.language,
      }}
      validationSchema={EditOfferSchema}
      onSubmit={props.handleEditOffer}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched
        } = props
        if (offersWithArticleWriting.includes(values.name && values.name.value) && values.articleWriting === 'false') {
          setFieldValue('articleWriting', 'true')
        } else if (!offersWithArticleWriting.includes(values.name && values.name.value) && values.articleWriting === 'true'){
          setFieldValue('articleWriting', 'false')
        }
        return (
          <>
            <Title title={i18n.t('Edit offer')} style={{ color: '#555555' }} />
            <Form>
                <label>{i18n.t('Name')}</label>
                <ReactSelectField
                    name={i18n.t('Name')}
                    fieldType='name'
                    options={selectOfferNameTypes}
                    value={values.name}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.name}
                    placeholder={i18n.t('Select the name of the offer')}
                    touched={touched.name}
                />
              <div className='position-relative form-group'>
                <label>{i18n.t('Description')}</label>
                <Field
                  name='description'
                  rows={8}
                  type='text'
                  className={
                    props.errors.description && props.touched.description
                      ? 'form-control is-invalid rounded-0'
                      : 'form-control rounded-0'
                  }
                  label='Description'
                  placeholder={i18n.t('Enter the name of the offer')}
                  component='textarea'
                />
                <div className='invalid-feedback'>
                  {i18n.t('Description is required!')}
                </div>
              </div>
              <Row gutter={32}>
                <Col span={6}>
                  <DefaultField
                    name='price'
                    type='number'
                    label={i18n.t('Price')}
                    placeholder='es. 500'
                  />
                </Col>
                <Col span={9}>
                  <label>{i18n.t('Select Link Type')}</label>
                  <ReactSelectField
                    name='Link Type'
                    fieldType='linkType'
                    options={selectLinkTypes}
                    value={values.linkType}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.linkType}
                    touched={touched.linkType}
                  />
                </Col>
                <Col span={9}>
                  <label>{i18n.t('Publication Type')}</label>
                  <ReactSelectField
                    name={i18n.t('Publication Type')}
                    fieldType='publicationType'
                    options={publicationTypes}
                    value={values.publicationType}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.publicationType}
                    touched={touched.publicationType}
                  />
                </Col>
              </Row>
              <Row gutter={32} className='mb-3'>
                <Col span={6}>
                  <label>{i18n.t('Temporary Offer')}</label>
                  <div style={{ margin: '14px 0' }}>
                    <Field
                      component={RadioButton}
                      name='isTemporary'
                      id='to_true'
                      value
                      label={i18n.t('Yes')}
                      inline
                      checked={values.isTemporary === 'true'}
                    />
                    <Field
                      component={RadioButton}
                      name='isTemporary'
                      id='to_false'
                      value={false}
                      label={i18n.t('No')}
                      inline
                      checked={values.isTemporary === 'false'}
                    />
                  </div>
                </Col>
                {values.isTemporary === 'true' && (
                  <Col span={9}>
                    <label>{i18n.t('Expiration date')}</label>
                    <DatePicker
                      name='expiresAt'
                      className='date-picker'
                      placeholder='GG/MM/AAAA'
                      disabledDate={(current) => current && current < moment().endOf('day')}
                      value={values.expiresAt ? moment(values.expiresAt, dateFormat.db) : null}
                      format={dateFormat.human}
                      touched={touched.expiresAt}
                      onChange={(_, date) => setFieldValue('expiresAt', date ? moment(date, dateFormat.human).format(dateFormat.db) : null)}
                      defaultValue={values.expiresAt ? moment(values.expiresAt, dateFormat.db) : null}
                    />
                    {!!errors.expiresAt && (
                      <div className='invalid-feedback d-block'>{errors.expiresAt}</div>
                    )}
                  </Col>
                )}
              </Row>
              <Row gutter={32} className='mb-3'>
                <Col span={6}>
                  <label>{i18n.t('Does the offer involve writing the article?')}</label>
                  <div style={{margin: '14px 0'}}>
                    <Field
                      component={RadioButton}
                      name='articleWriting'
                      id='aw_true'
                      value
                      label={i18n.t('Yes')}
                      inline
                      checked={values.articleWriting === 'true'}
                    />
                    <Field
                      component={RadioButton}
                      name='articleWriting'
                      id='aw_false'
                      value={false}
                      label={i18n.t('No')}
                      inline
                      checked={values.articleWriting === 'false'}
                    />
                  </div>
                </Col>
                {values.articleWriting === 'true' && (
                  <Col span={4}>
                    <label>{i18n.t('Time For Writing')}</label>
                    <ReactSelectField
                      name={i18n.t('Time For Writing')}
                      fieldType='timeForWriting'
                      options={[1, 2, 3, 4, 5, 6, 7].map(language => ({label: language, value: language}))}
                      value={values.timeForWriting}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.timeForWriting}
                      touched={touched.timeForWriting}
                    />
                  </Col>
                )}
                {values.articleWriting === 'true' && (
                  <Col span={4}>
                    <label>{i18n.t('Article Language')}</label>
                    <ReactSelectField
                      name={i18n.t('Article Language')}
                      fieldType='language'
                      options={[
                        {label: i18n.t('Italian'), value: 'it'},
                        {label: i18n.t('English'), value: 'en'}
                      ]}
                      value={values.language}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.language}
                      touched={touched.language}
                    />
                  </Col>
                )}
              </Row>
              <div className='position-relative form-group'>
                <label>{i18n.t('Prohibited Articles')}</label>
                <Field
                  name='prohibitedArticles'
                  rows={3}
                  type='text'
                  className={
                    props.errors.prohibitedArticles &&
                    props.touched.prohibitedArticles
                      ? 'form-control is-invalid rounded-0'
                      : 'form-control rounded-0'
                  }
                  label='Description'
                  placeholder={i18n.t('Separate tags with ","')}
                  component='textarea'
                />
                <div className='invalid-feedback'>
                  {i18n.t('Description is required!')}
                </div>
              </div>
              <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                <DefaultButton
                  type='submit'
                  style={{ backgroundColor: BUTTON_COLOR, padding: '0 80px' }}
                >
                  {i18n.t('Update Offer')}
                </DefaultButton>
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default EditOfferForm
