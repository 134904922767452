import React from 'react'

import { Field } from 'formik'
import { Checkbox } from 'antd'
import { Input, FormFeedback } from 'reactstrap'

const DefaultCheckboxWithLink = props => (
  <Field name={props.name} type='checkbox' component={CustomInputForm} label={props.label} />
)

export const CheckboxComponent = props => (
  <Checkbox className='checkbox' onChange={props.onChange}>
    <span dangerouslySetInnerHTML={{ __html: props.label }} />
  </Checkbox>
)
export const CustomInputForm = ({ field, form: { touched, errors }, ...props }) => (
  <label className='submitCheckbox' style={{ marginLeft: '8px' }}>
    <Input
      type='checkbox'
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
    <span dangerouslySetInnerHTML={{ __html: props.label }} />
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </label>
)
export default DefaultCheckboxWithLink
