import React from 'react'

import { Input, Select } from 'antd'
import DefaultSelect from 'components/Forms/DefaultSelect'
import DefaultButton from 'components/Forms/DefaultButton'

import { languages, countries } from 'utils/api'
import i18n from 'i18n'
import './style.css'
import { BUTTON_COLOR } from 'constants/colors'

const Option = Select.Option

const Filters = ({
  onChangeSearch,
  handleClickOnSelect,
  propertyCategories,
  handlePriceFromValue,
  handlePriceToValue,
  handleDAFromValue,
  handleDAToValue,
  onFilterButtonClick,
  onChangeUrl,
  filters,
  multipleCategories = false
}) => (
  <div data-component='components/Dashboard/Client/Properties/Filters'>
    <div className='Filters row'>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Search for properties')}
          onBlur={event => onChangeSearch(event.target.value)}
          defaultValue={filters.search}
        />
      </div>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t(multipleCategories ? 'Categories' : 'Category')}
          onChange={event => handleClickOnSelect('category', event)}
          defaultValue={filters.category}
          mode={multipleCategories ? 'multiple': 'default'}
        >
          <Option value='' key=''>
            <p />
          </Option>
          {propertyCategories.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => (
            <Option value={name} key={id}>
              {i18n.t(name)}
            </Option>
          ))}
        </DefaultSelect>
      </div>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t('Type')}
          onChange={event => handleClickOnSelect('type', event)}
          defaultValue={filters.type}
        >
          <Option value='' key=''>
            <p />
          </Option>
          <Option value='1' key='1'>
            Blog
          </Option>
          <Option value='2' key='2'>
            {i18n.t('Journal')}
          </Option>
          <Option value='3' key='3'>
            {i18n.t('Instagram Account')}
          </Option>
          <Option value='4' key='4'>
            {i18n.t('Facebook Page')}
          </Option>
        </DefaultSelect>
      </div>
      <div className='col-3'>
        <DefaultSelect
          placeholder='Country'
          onChange={event => handleClickOnSelect('country', event)}
          defaultValue={filters.country}
        >
          <Option value='' key=''>
            <p />
          </Option>
          {countries.map(country => (
            <Option key={country.value} value={country.value}>
              {country.label}
            </Option>
          ))}
        </DefaultSelect>
      </div>
    </div>
    <div className='Filters row'>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Price from')}
          onBlur={event => handlePriceFromValue(parseInt(event.target.value))}
          defaultValue={filters.priceFrom}
        />
      </div>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('Price to')}
          onBlur={event => handlePriceToValue(parseInt(event.target.value))}
          defaultValue={filters.priceTo}
        />
      </div>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('DA from')}
          onBlur={event => handleDAFromValue(parseInt(event.target.value))}
          defaultValue={filters.daFrom}
        />
      </div>
      <div className='col-3'>
        <Input
          placeholder={i18n.t('DA to')}
          onBlur={event => handleDAToValue(parseInt(event.target.value))}
          defaultValue={filters.daTo}
        />
      </div>
    </div>
    <div className='Filters row'>
      <div className='col-3'>
        <DefaultSelect
          placeholder={i18n.t('Language')}
          onChange={event => handleClickOnSelect('language', event)}
          defaultValue={filters.language}
        >
          <Option value='' key=''>
            <p />
          </Option>
          {languages.map(language => (
            <Option key={language.value} value={language.value}>
              {language.label}
            </Option>
          ))}
        </DefaultSelect>
      </div>
      <div className='col-3'>
        <Input
            placeholder={i18n.t('Search for URL')}
            onBlur={event => onChangeUrl(event.target.value)}
            defaultValue={filters.url}
          />
      </div>
      <div className='col-3'>
        <DefaultButton
          type='submit'
          style={{ background: BUTTON_COLOR, padding: '0 80px' }}
          onClick={onFilterButtonClick}
        >
          {i18n.t('Filter')}
        </DefaultButton>
      </div>
    </div>
  </div>
)

export default Filters
