import React, { useState } from 'react'
import { Query, graphql } from 'react-apollo'
import { Table } from 'antd'
import { paginationRender } from 'utils'
import {getPaginatedCoupons} from 'queries/queries'
import { destinationValues, kindValues, getLabelByValue } from 'utils/api'

import { toggleCouponEnabled } from 'mutations'
import { Link } from 'react-router-dom'
import * as path from 'constants/routes'
import Switch from "react-switch";

import Loader from 'components/Loader'
import i18n from 'i18n'


const columns = (handleToggle) => [
  {
    title: i18n.t('Name'),
    dataIndex: 'name',
    render: item => item || '-',
    width: 130,
    className: 'text-center',
  },
  {
    title: i18n.t('Code'),
    dataIndex: 'code',
    render: item => item || '-',
    className: 'text-center',
    width: 130
  },
  {
    title: i18n.t('Destination'),
    dataIndex: 'destination',
    render: item => getLabelByValue(item, destinationValues) || '-',
    className: 'text-center',
    width: 130
  },
  {
    title: i18n.t('Kind'),
    dataIndex: 'kind',
    render: item => getLabelByValue(item, kindValues) || '-',
    className: 'text-center',
    width: 130
  },
  {
    title: i18n.t('Discount'),
    dataIndex: 'discountValue',
    render: (item, {discountKind}) => `-${item}${discountKind === 1 ? "%" : "€"}` || '-',
    className: 'text-center',
    width: 130
  },
  {
    title: i18n.t('Enabled'),
    dataIndex: 'enabled',
    className: 'text-center',
    render: (item, {id}) => <Switch checked={item} onChange={() => handleToggle(id)}/>,
    width: 50
  }
]

const ShowCouponsComponent = ({toggleCouponEnabled}) => {
  const [page, setPage] = useState(1)

  const handleToggle = async (id) => {
    try {
      toggleCouponEnabled({
        variables: {couponId: +id},
        refetchQueries: [
          {
            query: getPaginatedCoupons,
            variables: {
              page: page,
              pageSize: 10,
            }
          }
        ]
      })
    } catch {}
  }

  return (
    <Query
      query={getPaginatedCoupons}
      fetchPolicy='network-only'
      variables={{
        page: page,
        pageSize: 10,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />
        if (error) return `Error! ${error.message}`
        const { paginatedCoupons } = data
        const total = paginatedCoupons ? paginatedCoupons.total : 0
        let items = paginatedCoupons ? paginatedCoupons.objects : []

        return (
          <>
            <div>
              <Link to={path.CREATE_COUPON}><button className='btn primary'>{i18n.t('Create Coupon')}</button></Link>
            </div>
            <div className='table-container'>
              <Table
                rowSelection={null}
                rowKey='id'
                pagination={{
                  itemRender: paginationRender,
                  total: total,
                  current: page,
                  onChange: setPage
                }}
                columns={columns(handleToggle)}
                dataSource={items}
              />
            </div>
          </>
        )
      }}
    </Query>
  )
}

export default graphql(toggleCouponEnabled, { name: 'toggleCouponEnabled' })(ShowCouponsComponent)
