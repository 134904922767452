import React, { useState } from 'react'

import AdminCreateRequestForm from 'components/Forms/AdminCreateRequestForm'
import Close from 'components/Icons/Close'

import { withAuth } from 'hocs/PrivateRoute'
import { onlyClient } from 'hocs/componentByRole'
import * as path from 'constants/routes'
import { Query, graphql, compose } from 'react-apollo'
import { adminCreateRequest } from 'mutations'
import { getRequests, getAdvertisers } from 'queries/queries'
import Loader from 'components/Loader'
import i18n from 'i18n'
import swal from 'sweetalert'

const AdminCreateRequest = props => {
  const [state] = useState({ error: '' })
  const [isSubmitted, setSubmitted] = useState(false)
  const handleCreateRequest = async (values, { setErrors }) => {
    const willCreate = await swal({
      title: i18n.t('Are you sure?'),
      text: i18n.t('Are you sure you want to create the request?'),
      icon: 'warning',
      buttons: true,
      dangerMode: true
    })

    console.log(values)
    if (willCreate) {
      setSubmitted(true)
      return new Promise((resolve, reject) => {
        const {
          name,
          objective,
          topic,
          targetUrl,
          months,
          velocity,
          linkNumber,
          rangeBudget,
          language,
          description,
          // domainAuthority,
          articleWriting,
          itemQuality,
          isApproval,
          user
        } = values
        props
          .adminCreateRequest({
            variables: {
              name,
              objective: objective || 10,
              topic,
              targetUrl,
              months: months.value || 1,
              velocity: velocity || 10,
              linkNumber,
              rangeBudget: rangeBudget.value || 0,
              language: language.value,
              description,
              articleWriting: articleWriting,
              itemQuality: itemQuality.value || 1,
              isApproval: isApproval,
              user: user
            },
            refetchQueries: [
              { query: getRequests, variables: { userId: props.getUser.me.id } }
            ]
          })
          .then(response => {
            if (response.data.adminCreateRequest.success) {
              window.dataLayer.push({
                event: 'gaEvent',
                eventCategory: 'app relevanty',
                eventAction: 'richiesta effettuata',
                eventLabel: props.getUser.me.email
              })
              props.history.push(`/orders/${response.data.adminCreateRequest.request.joborder.id}/save`)
              resolve()
            } else {
              const errors = {}
              response.data.createRequest.error.validationErrors.map(
                error => (errors[error.field] = error.messages.join(' '))
              )
              setErrors(errors)
              setSubmitted(false)
              // reject(new Error())
            }
          }).finally(() => {
            console.log('FINALLY')
            setSubmitted(false)
          })
      })
    }
  }
  return (
    <Query
      query={getAdvertisers}
      fetchPolicy='network-only'
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />
        if (error) return `Error! ${error.message}`
        return (
          <div className='row justify-content-md-center p-5 m-0' data-component='Dashboard/Client/CreateRequest/index'>
            <div className='col-8'>
              <AdminCreateRequestForm
                handleCreateRequest={handleCreateRequest}
                error={state.error}
                errors={props}
                isSubmitted={isSubmitted}
                advertisers={data.advertisers}
              />
              <Close onClick={() => props.history.push(path.DASHBOARD)} />
            </div>
          </div>
        )
      }}
    </Query>

  )
}

export default compose(
  graphql(adminCreateRequest, { 
    name: 'adminCreateRequest' 
  })
)(withAuth(onlyClient(AdminCreateRequest)))