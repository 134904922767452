import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";

const UserType = ({ path, image, type, children, href }) => {

  const content = (
    <div className="item-body" style={{ textAlign: "center" }}>
      <img src={image} alt="" height="180" width="180" />
      <h4 className="title m-0">{type}</h4>
      {children}
    </div>
  );

  return href ? (
    <a href={href} className="item wrapp">
      {content}
    </a>
  ) : (
    <Link to={path} className="item wrapp">
      {content}
    </Link>
  );
};

export default UserType;
